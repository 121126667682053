import Accordion from "react-bootstrap/Accordion";
import "./PalmFibreFaq.css";

function PalmFibreFaq() {
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>
          What are the value added by products of MFOE plant?
        </Accordion.Header>
        <Accordion.Body>
          The De-oiled Fibre has wide range of applications and can be used in
          making, mattresses, pulp, paper & board besides, being used back as
          fuel to the boiler. The issue regarding reduced calorific value is
          also compensated by high temperature and low moisture deoiled fiber
          from the Solvent Extraction Plant.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>What to do with the oil produced?</Accordion.Header>
        <Accordion.Body>
          Our effort is to ensure that the product is positioned on a
          Nutritional platform besides Cosmetics, Carotene recovery,
          Phytosterol, Tocopherol and Tocotrienol which are over 2000 PPM in the
          oil. Moreover, this oil can be used for direct consumption after
          refining since the DOBI is over 2.0. The fatty acid composition of
          fibre oil is same as Palm Oil, however, only difference is in
          Nutritional value which is higher than Palm Oil extracted through
          press and 2% higher FFA than CPO.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2">
        <Accordion.Header>
          What is the Safety? What should be the distance between other
          buildings from MFOE plant?
        </Accordion.Header>
        <Accordion.Body>
          MECPRO’s solvent plants have adopted more than five patented
          technologies in the process incorporating hexane detector at all
          outlets with PLC system. Hence, they are 100% safe as adequate safety
          features are incorporated. Plants have the option of operating on both
          flammable, as well as, non-flammable solvents. Distance between
          buildings should be 15.25 mtrs and Fire Zone should be 40 Mtrs.{" "}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3">
        <Accordion.Header>What area does MFOE plant require?</Accordion.Header>
        <Accordion.Body>
          Although, it depends on the plant capacity, however, the plant can be
          set up in 18Mtrs x 30 Mtrs with a height of 15 Mtrs., leaving the
          radius of 15.25 Mtrs, all around the main plant. The main plant can be
          set up in 2800M2 up to 120 Ton FFB/Hr based Solvent Extraction Plant.{" "}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="4">
        <Accordion.Header>
          What is the operational life of plant & equipment?
        </Accordion.Header>
        <Accordion.Body>
          The plant & equipment being supplied by MECPRO has service lifetime of
          15-20 years, subject to periodic preventive maintenance, as per
          MECPRO’s guideline{" "}
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="5">
        <Accordion.Header>
          What is the quality of oil produced?
        </Accordion.Header>
        <Accordion.Body>
          MECPRO’S cutting edge technology based on low temperature azeotrope
          distillation maintains the quality of oil much better. The oil is very
          rich in and can be used for Carotenoids = 2100 ppm Vitamin E = 2200
          ppm Phytosterols = 3000 ppm Squalene = 1000 ppm extraction of
          Carotenoids. Moreover, with the R&D, the DOBI has been achieved up to
          2.2 in the recently commissioned plant.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="6">
        <Accordion.Header>How do we proceed further?</Accordion.Header>
        <Accordion.Body>
          MECPRO can arrange a detailed technical presentation to your core
          technical team, to further clarify any doubts. Moreover, MECPRO can
          arrange a plant visit to show you the latest plant, based on our
          state-of-the-art cutting edge innovative technologies <br />
          Contact: projects@mecpro.com
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default PalmFibreFaq;
