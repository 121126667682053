import React from 'react'
import Accordion from 'react-bootstrap/Accordion';


const PomeFaq = () => {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>What is the defination of emulsive oil recovery plant ?</Accordion.Header>
                <Accordion.Body>
                    In the palm oil mill, each metric ton of palm fruit bunch processed discharge 600 Kg of Effluent with 0.8-1.8% of oil. Out of that the floated oil will be skimmed and emulsified oil of minimum 1% going to the effluent treatment pond. In this 20-25% recovered as a sludge oil with higher FFA and balance will be merged and loosed in the mud. The cost of recovered oil will be only 40-50% of the CPO price. The new technology to recover oil from POME will ensure total oil recovery with good quality and less than 7% FFA level.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>What is the project implementation time required?
                </Accordion.Header>
                <Accordion.Body>
                    Usually, the project implementation schedule varies from 8 months to 12 months depending upon the location, soil condition and weather condition.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>What is the pay back period of Project?
                </Accordion.Header>
                <Accordion.Body>
                    The payback period of such project is less than 1 to 3 year as per the prevailing rate.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>	What are all the input raw material considered for Such Plants?
                </Accordion.Header>
                <Accordion.Body>
                    Such plant can process effluent water from sterilizer, clarifier under flow or decanter out flow, purifier, taken to a closed circuit to avoid any deterioration of oil and oil separated from the effluent water.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>What is the effect on OER?
                </Accordion.Header>
                <Accordion.Body>
                    Based on Mecpro technology the OER enhanced by 0.5 to 0.75% in the palm oil mill using such downstream process.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>What is the utility consumption?
                </Accordion.Header>
                <Accordion.Body>
                    Utility consumption for 1 m3 of effluent process, Hexane Loss 1 kg Power 4 kW, Steam pressure at 3 bar 60 kg. Utility consumptions and maintenance are negligible in this project.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>Is the process technology proven & patented?</Accordion.Header>
                <Accordion.Body>
                    The technology patented and commercialized, and resulting 0.5% OER increase from POME and the project is running successfully for the past 3 years.




                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>What is the total area required for this project?
                </Accordion.Header>
                <Accordion.Body>
                    The total area required for the project including safety area is required less than 2800 m2.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
                <Accordion.Header>What is the COD reduction after removal of oil from effluent water?

                </Accordion.Header>
                <Accordion.Body>
                    The recovered oil is not having any COD, however in the vacuum process is slight reduction of COD which is less than 10% and the same is compensated in the bio-gas generation reactor.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
                <Accordion.Header>Is the water is suitable for bio-gas generation after oil extraction?</Accordion.Header>
                <Accordion.Body>
                    Yes, it can directly send to bio-gas reactor after cooling and the purity of gas will be better than present since the water is processed under vacuum. The maximum carbon dioxide will be removed from the water and the anaerobic reaction will be faster than present and the same time the COD conversion to bio-gas increased by 10 – 15% and the same will compensate the reduction of COD in the oil recovery process, so recovering of oil from effluent is not having any effect for the production of bio-gas.



                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
                <Accordion.Header>How one can make a zero effluent water discharge without bio-gas generation plant?
                </Accordion.Header>
                <Accordion.Body>
                    If management are not willing to investing for bio-gas generation, the de-oiled water can easily filter and the water can be taken for evaporation process and the distilled water can re-use for palm oil mill process and the solid can be converted to organic fertilizer.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
                <Accordion.Header>The water can be evaporated after bio-gas generation?</Accordion.Header>
                <Accordion.Body>
                    Yes, after the bio-gas generation the water can send to evaporation plant, however there will be a slight increase of steam consumption.



                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
                <Accordion.Header>What is the utility consumption for water evaporation process?
                </Accordion.Header>
                <Accordion.Body>
                    There are two method of evaporation process. The first one is with low pressure steam at 3 bar or power based using MVR.

                    The steam base project required 220 to 250 kg steam for m3 of water evaporation and the power required is 4 KW/m3. The power base project requires 10 kg steam and 15 KW power per m3 of water evaporation.





                </Accordion.Body>
            </Accordion.Item>


        </Accordion>
    )
}

export default PomeFaq