import React from "react";
import logo from "./images/logo.png";
import "./Footer.css";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <div className="footerContainer">
        <div className="logoFooter">
          <div className="logo">
            <img src={logo} alt="Mecpro" />
          </div>
        </div>
        <div className="footerLinks">
          <div className="footerLinksleft">
            <ul>
              <li>
                {" "}
                <Link to="Refinery">Refinery </Link>
              </li>
              <li>
                <Link to="Fractionation">Fractionation</Link>
              </li>
              <li>
                <Link to="LayerEsterification">Layer Esterification</Link>
              </li>
              <li>
                <Link to="SolventExtraction">Solvent Extraction</Link>
              </li>
              <li>
                <Link to="CottonSeed">Cotton Seeds Delinting</Link>
              </li>
              <li>
                <Link to="OilMill">Oil Mill</Link>
              </li>
              <li>
                <Link to="SpentBleechingEarth">Spent bleaching earth</Link>
              </li>
              <li>
                <Link to="FatSplitting">Fat Splitting</Link>
              </li>
              <li>
                <Link to="Hydrogenation">Hydrogenation</Link>
              </li>
              <li>
                <Link to="PalmOilMill">Palm Oil Mill</Link>
              </li>
            </ul>
          </div>
          <div className="footerLinksCenter">
            <ul>
              <li>
                {" "}
                <Link to="Soap">Soap</Link>
              </li>
              <li>
                {" "}
                <Link to="FlourMill">Flour Mill</Link>
              </li>
              <li>
                {" "}
                <Link to="WaxPurification">Wax Purification</Link>
              </li>
              <li>
                {" "}
                <Link to="BakeryShortening">
                  Bakery Shortening and Margarine
                </Link>
              </li>
              <li>
                {" "}
                <Link to="SolarEnergy">Solar Energy</Link>
              </li>
              <li>
                {" "}
                <Link to="BioDiesel">Bio-Diesel</Link>
              </li>
              <li>
                <Link to="Palmfibreoilectraction">Palm Fibre</Link>
              </li>
              <li>
                <Link to="PalmKerneloilextraction">Palm Kernal</Link>
              </li>
              <li>
                <Link to="Pome">Oil Recovery from POME</Link>
              </li>
            </ul>
          </div>
          <div className="footerLinksRight">
            <h2>Head Office</h2>
            <p>
              Mecpro Heavy Engineering Ltd.
              <br />
              (CIN: U74210DL1986PLC024429)
              <br />
              610, Som Dutt Chambers-II,
              <br /> Bhikaji Cama Place
              <br />
              New Delhi-110 066, India
              <br />
              Tel. : + 91-11-26100912
              <br />
              Fax : + 91-11-26185182
              <br />
              {/* MS Team : projects@mecpro.com
              <br /> */}
              {/* E-mail : projects@mecpro.com, rajan.skhariya@mecpro.com */}
            </p>
          </div>
          <div className="overseasAddress">
            <h2> Overseas Office</h2>
            <p>
              Mecpro Heavy Engineering SDN. BHD. (926103-V) Suite 19A-12-05,
              Level 12, UOA Centre Business Suite Wisma, UOA-II, Jalan Pinang
              50450,
              <br /> Kuala Lumpur Wilayah Persekutuan,
              <br /> Malaysia <br />
              Tele: + 60165302714 <br />
              Mobile: + 91 9910022256
              <br />
             {/*  E-mail : projects@mecpro.com, */}
            </p>
          </div>
        </div>
      </div>
      <div className="footer">
        <p>
          {" "}
          <img src={logo} alt="" />
          &#169; 1986 to 2024 Mecpro Heavy Engineering Limited. All Rights
          Reserved.{" "}
        </p>
      </div>
    </>
  );
};

export default Footer;
