import React from "react";
import { Helmet } from "react-helmet";

const Lowtempmiscellaseperation = () => {
  return (
    <>
      <Helmet>
        <title>Solvent Process-Low Temperature Miscella Separation</title>
        <meta
          name="description"
          content="we have
        developed a new process named azeotropic distillation. It refers to a
        process in which a component known as the solvent or entrainer is
        added at the top of the column to form a homogeneous complex known as an
        azeotrope, which is removed as distillate itself. The other component
        free from the solvent is collected at the bottom of the column."
        />
        <meta
          name="keywords"
          content="In this
        case, the process is quite different from the normal stripping
        distillation because the solvent that appears as distillate can be
        separated at a lower temperature and refluxed at the top of the column
        for augmentation of the process,  
          solvent plant,
          oil solvent extraction plant,
          continuous solvent extraction plant,
          Miscella Filteration,
          Miscella Separation,
          Hexane separation,
          Azeotropic distillation,
          Self wash Miscella Filtration,
          Solventisation,
          Degumming,
          Edible Oil Solvent Extraction Plant,
          Desolventisation,
          Losses of Solvent,
          Solvent Extraction,
          What is Solvent Extraction,
          Higher bleachability,
          non-hydratable phosphatides,
          Advance Solvent Extraction process, 
         "
        />
      </Helmet>
      <h2 className="Refinery text-left">
        Low temperature Miscella Separation (Patented){" "}
      </h2>
      <h5 className="mecpro1">
        After conducting a detailed study of the conventional process of hexane
        separation and the excessive losses of hexane, deterioration of the oil,
        and development of non-hydratable phosphatides therein, we have
        developed a new process named azeotropic distillation. It refers to a
        process in which a component (known as the solvent or entrainer) is
        added at the top of the column to form a homogeneous complex known as an
        azeotrope, which is removed as distillate itself. The other component
        free from the solvent is collected at the bottom of the column. In this
        case, the process is quite different from the normal stripping
        distillation because the solvent that appears as distillate can be
        separated at a lower temperature and refluxed at the top of the column
        for augmentation of the process. In isothermal distillation, no physical
        or chemical changes take place to any of the components present. The
        complex formed in the vapor filter is fed to the reflux column where the
        hexane separation takes place isothermally at 65oC, the vapourising
        temperature of hexane. By means of the reflux column, miscella
        separation at 65oC for non-frothing oil and 75oC to 85oC in frothing oil
        like soybean and mustard is achieved. Plants not using the reflux column
        separate the miscella at an elevated temperature of 110 to 120oC with
        sparged steam, due to which oxidation of oil and degradation occurs.
        Also, steam sparged at this elevated temperature in the stripper
        increases non-hydratable phosphatides which increases the refining loss.
        At a lower temperature with a reflex column water degumming is possible.
        Mecpro offers <br />
        <br />• Reduced steam consumption
        <br />• Reduced chemical consumption in the refining process
        <br />• Better oil color
        <br />• Higher bleachability
        <br />• Less increase in FFA as compared to any other method
        <br />• Less non-hydratable phosphatides development than any other
        method
        <br />• Less peroxide value than any other method
        <br />• Less anisidine value than any other method
        <br />• No oxidization or degradation of oil <br />
      </h5>
    </>
  );
};

export default Lowtempmiscellaseperation;
