/* import React, { useEffect } from 'react' */
import { Swiper, SwiperSlide } from 'swiper/react'
import ReactPlayer from 'react-player'
/* import gsap from 'gsap'; */
/* import { ScrollTrigger, ScrollToPlugin } from 'gsap/all'; */
import './HeadSlider.css'
/* import { useRef } from 'react'; */
import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import { Pagination, EffectCube, EffectCoverflow, Navigation, Autoplay } from 'swiper/modules';
import vid from "./Assets/header_1.webm";
import vidIos from "./Assets/headerSlider.mp4";


const HeadSlider = () => {

  return (
    <>

      <div className="wrapper panel" >

        <div>

          {/*  <video controls>
            <source src={vid} />
            <source src={vidIos} />
          </video> */}
          <ReactPlayer url={[vid, vidIos]} width="100%" height="100%" muted={true} playing={true} loop={true} />

        </div>


        {/* <div id="three" className="imageContainer slide3" ref={Slide3}>
          <img src="./Images/headerslider1.png" alt="slide1" />
        </div>
        <div id="four" className="imageContainer slide3" ref={Slide4}>
          <img src="./Images/hs4.jpg" alt="slide1" />
        </div>
        <div id="five" className="imageContainer slide3" ref={Slide5}>
          <img src="./Images/hs5.jpg" alt="slide1" />
        </div>
        <div id="six" className="imageContainer slide3" ref={Slide6}>
          <img src="./Images/hs12.png" alt="slide1" />
        </div>
        <div id="seven" className="imageContainer slide3" ref={Slide7}>
          <img src="./Images/hs7.jpg" alt="slide1" />
        </div>
        <div id="eight" className="imageContainer slide3" ref={Slide8}>
          <img src="./Images/hs8.gif" alt="slide1" />
        </div>
        <div id="eight" className="imageContainer slide3" ref={Slide9}>
          <img src="./Images/hs9.jpg" alt="slide1" />
        </div> */}


        <div className="caption" >





          <Swiper
            effect={'cube'}
            grabCursor={true}
            cubeEffect={{
              shadow: true,
              slideShadows: true,
              shadowOffset: 0.5,
              shadowScale: 0.94,





            }}


            autoplay={{
              delay: 6500,
              disableOnInteraction: true,
            }}

            loop={true}
            navigation={false}
            modules={[EffectCoverflow, Pagination, Autoplay, Navigation, EffectCube]}
            className="mySwiper"
          >
            <SwiperSlide className='txt'>
              <h1 id='text1' >Vision</h1>
              <ul>
                <li className='caption-text'>Mecpro’s vision is to continually lower the performance benchmark for utility consumption, increase yields, and improve the quality of the product and byproducts. </li>
                <li className='caption-text'>Mecpro seeks to achieve the highest standards of safety in the operation of its plants to ensure workers’ health, welfare and comfort, and to develop sustainable, environment friendly technology to best serve society at large.</li>
                <li className='caption-text'>               Mecpro works tirelessly to utilise downstream effluent and invent methods for converting it into useful material or recirculating it into the process cycle, thereby creating wealth from waste</li>

              </ul>
            </SwiperSlide>
            <SwiperSlide className='txt'>

              <h1 id='text1' >Mission</h1>

              <ul>
                <li className='caption-text'>Our mission is to play our part in developing and improving the ecosystem of the edible oil, fats and surfactants industry.  To this end, we ceaselessly look for ways to provide value to</li>
                <li className='caption-text' >Customers – through better and more economical products and sincere service</li>
                <li className='caption-text'>Vendors – through a spirit of engagement and partnership in our dealings</li>
                <li className='caption-text'>Employees – through providing a great place to work with ample opportunities for growth</li>
                <li className='caption-text'>Society in general – through sustainable, low emission technologies that save energy and reduce global warming</li>
              </ul>
            </SwiperSlide>
            <SwiperSlide className='txt'>
              <h1 id='text1' >Quality</h1>
              <ul>
                <li className='caption-text'>Mecpro aims for quality in all its endeavours. We provide high quality products and services by attending to all the three drivers of quality</li>
                <li className='caption-text'>We incorporate the best technology that gives superior quality output in our products and byproducts.  We focus on innovation & research to find better and better techniques at all stages</li>
                <li className='caption-text'>We provide a conducive working environment to our highly skilled employees and encourage them to seek out opportunities for learning and growth.</li>
                <li className='caption-text'>We implement and continually improve our processes and operating procedures to ensure consistency and reliability in outcomes.</li>

              </ul>


            </SwiperSlide>
            <SwiperSlide className='txt'>
              <h1 id='text1' > Reputation </h1>
              <ul>

                <li className='caption-text'>To emerge as a globally recognized, professionally managed multinational organisation, delevering unparalled technology solutions and projects implementation services across diversed industry. </li>
                <li className='caption-text'> Committed to excellence innovation and operational powess, we strive to achieve total customer satisfaction through  empowered teams and an unwavering dedication to quality, setting new benchmarks for world-class standards and system in the process industry</li>
              </ul>
            </SwiperSlide>
          </Swiper>
        </div>

      </div>

    </>
  )
}

export default HeadSlider