import React from 'react'
import Accordion from 'react-bootstrap/Accordion';

const SpentBleechingEarthFaq = () => {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>What is Spent Bleaching Earth?
                </Accordion.Header>
                <Accordion.Body>
                    Spent Bleaching Earth is a solid waste material generated as a part of the refining process in the edible oil industry, worldwide.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>How much oil does Spent Bleach Earth absorb?

                </Accordion.Header>
                <Accordion.Body>
                    In refining, bleaching earth is used to remove color, metal, phosphatides, oxidized products and residual gums from the oil; however, in turn, it absorbs approximately 20-40 % by weight of the Spent Bleaching Earth.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>How much oil can be recovered during the Solvent Extraction process?
                </Accordion.Header>
                <Accordion.Body>
                    The Oil in Spent Bleaching Earth can be recovered using our patented technology leaving less than 1% in the Spent Bleaching Earth which meets the environmental regulations for the disposal of spent bleaching earth for any application.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>	process?</Accordion.Header>
                <Accordion.Body>
                    The oil recovered immediately after the bleaching process by extraction with a solvent can be used as feedstock to a refinery. Otherwise, it can be reused as a raw material for industrial applications to save costs in the processing industry. As the residual oil has a very high FFA and Peroxide Value, it can only be used for non-food applications, as feed stock for conversion to Biodiesel, and as a lubricant base for bio-lubricants as a feedstock to the oleo-chemical industry. <br />
                    We have been able to successfully run a plant on a continuous process as against the batch process prevalent in the industry. <br />
                    In our process, the oil can be recovered from Spent Bleaching Earth using both a flammable as well as non-flammable solvent, which has been done for the first time in the world. the preparatory less new process will reduce the power consumption by 40%, enhance oil quality, better safety in process, less manpower and utility consumption.
                    <br />
                    The output de-oiled Spent Bleaching Earth is safe for disposal in land fill. It can also be used as a fuel in the boilers by adjusting the extraction process. The anhydrous clay can be used in Cement manufacturing. It can also be used in manufacturing Fertilizers containing silica/silicates or can be used as a soil amendment, as it poses no harm to the soil and acts as a soil conditioner.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>Is it safe to dispose of Spent Bleaching Earth without treating?
                </Accordion.Header>
                <Accordion.Body>
                    Due to the high oil content in the Spent Bleaching Earth, its disposal can cause environmental hazards, since it is prone to catching fire, besides polluting the ground water. With the regulatory restrictions in place in most countries, the need to lessen the environmental impact is of growing importance and concern to the industry.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>What is the minimum and maximum capacity of the plant?
                </Accordion.Header>
                <Accordion.Body>
                    Mecpro has the engineering capability to design any capacity required as per the market demand both as a batch process or a continuous process. This can range from 5 MT/day to 1000MT/day

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>What is the project implementation time?
                </Accordion.Header>
                <Accordion.Body>
                    Usually, the project implementation schedule varies from 8 months to 12 months depending upon the location and soil condition.



                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>How can one improve the quality of the product?

                </Accordion.Header>
                <Accordion.Body>
                    Inter-esterification, hydrogenation, fractionation.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
                <Accordion.Header>How can one reduce the utility consumption?
                </Accordion.Header>
                <Accordion.Body>
                    Increased efficiency in penetration will help to extract oil below 1% concentration in the de-oiled cake. The miscella filtration with filter cloth and azeotropic distillation at low temperature will eliminate any degradation of the product and help to reduce corrosion as well as the maintenance cost.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
                <Accordion.Header>Is the process technology proven or patented?
                </Accordion.Header>
                <Accordion.Body>
                    We have already established our batch process as well as continuous process. Our recently commissioned 150 TPD continuous spent bleaching earth plant with nonflammable solvent is a new concept. For the first time in the global market such a plant is running successfully and producing a good quality of oil.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
                <Accordion.Header> What are the safety considerations? What should be the distance between other buildings and an SBE plant?

                </Accordion.Header>
                <Accordion.Body>
                    Mecpro’s spent bleaching earth extraction plant uses a non-flammable solvent and there is no specific distance required to be kept from other process units. It can be kept near the refinery to reduce the transportation cost. The process plant with 5 patented technologies ensures safe operation with reduced utility consumption besides improved quality of both product and by-product.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
                <Accordion.Header>What is the payback period of the capex?
                </Accordion.Header>
                <Accordion.Body>
                    Generally, such a plant can recover the capital investment in 1 to 3 years. It all depends upon the cost of spent bleaching earth and the applications of the deoiled spent earth. However, the payback period is not likely to be greater than 3 years in any case.



                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
                <Accordion.Header>How do we proceed further?
                </Accordion.Header>
                <Accordion.Body>
                    MECPRO can arrange a detailed technical presentation to your core technical team, to clarify any doubts, based on our state-of-the-art, cutting edge, innovative technologies. <br />Contact: projects@mecpro.com




                </Accordion.Body>
            </Accordion.Item>


        </Accordion>
    )
}

export default SpentBleechingEarthFaq