import "./WaxPurification.css";
import "./Refinery.css";
import { Parallax } from "react-parallax";
import { Helmet } from "react-helmet";

const WaxPurification = () => {
  return (
    <>
      <Helmet>
        <title> Wax Purification</title>
        <meta
          name="description"
          content="Wax is usually after separated from the oil by some de-waxing process. Mecpro has developed a continuous de-waxing process
          which produces wax with less oil entrainment."
        />
        <meta
          name="keyword"
          content="Wax, wax purification, edible wax, rice bran wax, "
        />
      </Helmet>
      <div>
        <h1 className="Refinery">WAX PURIFICATION</h1>
      </div>
      <div className="refinaryContainer">
        <Parallax
          blur={0}
          bgImage="./Images/1-1801777405@2x.png"
          strength={600}
        >
          <div className="HimgContainer"></div>

          {/* <h1 className="pageHeading">Solvent Extraction</h1> */}
        </Parallax>

        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/Wax.webp"
            alt="wax purification-1"
          />
          <article>
            Bakery shortening or Emulsifier-type shortening is hydrogenated
            shortening to which an emulsifying agent has been added. This gives
            the shortening exceptional ability to blend with other ingredients.
            It is especially formulated to contribute a crumbly, crusty and
            flaky texture and firm enough to support the weight of the fillings
            as used in making of cream sandwich cookies, decorative creaming
            pastries and other bakery products. MECPRO provides the Shortening
            Plant capable of producing up to 1000 kg/hour after cooling from an
            inlet temperature of 450c to 23/250c which would be suitable for
            semi-liquid filling into cans. The capacity of such plants largely
            depends upon product formulations and inlet temperature of the
            product to be processed.
          </article>
        </section>
        <br />
        <br />
        <h5 className="mecpro1">
          Wax is usually after separated from the oil by some de-waxing process.
          Mecpro has developed a continuous de-waxing process which produces wax
          with less oil entrainment. The removed wax is further de-oiled and
          purified for various applications. The major component of wax in
          edible oil is wax esters, which consist of long chain alkyl esters and
          steryl esters. They are classified as a polar lipid species, whose
          polarity is lower than that of di-glycerides. Rice bran oil wax is
          notable for its high anti-oxidant content.
        </h5>
        <section className="refVid">
          <br />
          <img
            className="fracbigImg"
            src="./Images/waxlab.webp"
            alt="spent bleaching-2"
          />
        </section>
        <br />

        <section className="refinery1">
          <article>
            Wax is a type of hydrocarbon that contains long chain alkanes, often
            containing ester, carboxylic acid or alcohol groups. The structure
            and molec- ular weight of the hydrocarbon chain and the rel- ative
            concentration of the functional groups det- ermine the hardness of
            the wax. Natural waxes are mixtures of several kinds of waxes
            whereas petroleum derived waxes tend to be more homo- genous. Waxes
            can be classified according to their source.The source of wax can be
            natural, plants, animals, petroleum, and high temperature cracked
            polyethylene wax.
          </article>
          <img
            className="refImg"
            src="./Images/waxpln.png"
            alt="spent bleaching-3"
          />
        </section>

        <section>
          <h1 className="pageHeading2">RICE BRAN OIL WAX</h1>

          <h5 className="mecpro1">
            Currently the rice bran oil wax after separation from the oil is
            traded as such in our country without any purification. Thus there
            is no value addition in the process.Today wax is considered as a
            by-product or waste material from the oil production process. The
            rice bran oil wax purification will recover the oil from the
            wax.After removal of impurities, the high quality wax can be used
            for sev- eral applications, which are needed in modern life.
            Saponified rice bran esters generate fatty acid, long chain alcohols
            and Phytost- erol. Phytosterol and long chain fatty alcohols are
            known to reduce serum cholesterol and inhibit hepatic cholesterol
            synthesis.{" "}
          </h5>
        </section>

        <h1 className="pageHeading2">RICE BRAN WAX PHYSICAL PROPERTIES</h1>

        <section className="refVid">
          <img
            className="fracbigImg"
            src="./Images/1-5@2x.webp"
            alt="spent bleaching-4"
          />
        </section>
        <br />
        <br />
        <section className="refVid">
          <img
            className="fracbigImg"
            src="./Images/process-1@2x.png"
            alt="spent bleaching-5"
          />
        </section>
        <br />
        <br />

        <section className="refVid">
          <img
            className="fracbigImg"
            src="./Images/process-2-1@2x.png"
            alt="spent bleaching-6"
          />
        </section>
        <br />
        <br />

        <h5 className="mecpro1">
          The rice bran wax has more value than naturally occurring waxes like
          bees’ wax, Lanolin, Lanocerin, Shellac, Ozokerite, carnauba,
          Candellila jojoba, Ouricourl, Montan or paraffin. Edible grade wax is
          used to coat fruits, paper, thermal transfer recording material;
          surfaces of various materials, toner composition, PTT coated silicon
          ruberols, candles, water proofing, polish, binder for manufac- turing
          and hundreds of other applications. Besides the wax can further be
          converted into octocosanol or polycosanol as a pharm- aceutical grade
          product.This project is executed by Mecpro along with the Indian
          Institute of Chemical Technology, Hyderabad for ensuring superior
          quality.Rice Bran wax has been chosen as one of the waxes from plant
          sources for applications such as a natural additive in foods,
          cosmetics and other industries like polishes, carbon paper and
          lubricants. Rice Bran wax consists almost exclus- ively of saturated
          monoesters with a carbon chain.The process of Rice Bran wax production
          involves low temperatures. Crystallizat- ion of wax from the oil is
          followed by filtration.In the Mecpro technique wax is treated with
          Solvent to prepare a wax suitable for ind- ustrial use. Sludge is
          purified to get a hard wax (by using Solvent treatment).
        </h5>
        <br />
        <br />
      </div>
    </>
  );
};

export default WaxPurification;
