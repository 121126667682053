import React, { useEffect, useState } from "react";
import "./BlogPost.css";
import axios from "axios";
import { Helmet } from "react-helmet";

function BlogPost() {
  const [blog, setBlog] = useState([]);
  const id = localStorage.getItem("id");

  //Get single Blog with ID

  useEffect(() => {
    axios
      .get(
        `https://us-central1-mecpro-bcf7d.cloudfunctions.net/api/api/v1/blog/get-blog/${id}`
      )
      .then((response) => {
        setBlog(response.data.blog);
      });
  }, [id]);

  return (
    <>
      <Helmet>
        <title> {`${blog.title}`}</title>
        <meta name="description" content={blog.description} />
        <meta
          name="keyword"
          content="Bio Diesel, crude oil, used oil, sludge oil"
        />
      </Helmet>

      <div className="postContainer">
        <h1>{blog.title}</h1>
        <img src={blog.image} alt="" />
        <div className="main">
          <div className="blogContent">{blog.description}</div>
          {/*   <aside>
                        <h1>Categories</h1>
                    </aside> */}
        </div>
      </div>
    </>
  );
}

export default BlogPost;
