import React from 'react'
import './EPCMEpcPmc.css'
import { Helmet } from 'react-helmet'

const EPCMEpcPmc = () => {
  return (
    <>
      <Helmet>
        <title>EPCM, EPC, PMC</title>
        <meta name="description" content=" The right choice of a project strategy is key to the success of your investment. There are three main variations of possible contract strategies: EPC, EPCM, PMC. How to choose the one that suits your needs?" />
        <meta name="keywords" content="EPCM, EPC, PMC " />
      </Helmet>
      <div className="epcmContainer">
        <h1 className='pageHeading2' >EPCM, EPC, PMC</h1>
        <article>The right choice of a project strategy is key to the success of your investment. There are three main variations of possible contract strategies: EPC, EPCM, PMC. How to choose the one that suits your needs?</article>
        <div className="fracbigImg">
          <img src="./Images/Group 2162.png" alt="EPCM, EPC, PMC" className='' />
        </div>
        <article className='maintainanceContent'>
          <h1>EPCM: </h1>
          <h5>Engineering, Procurement and Construction management The most flexible approach to project execution in terms of timing and budget. First, you can receive the proposal from a service provider 2-4 months faster than in case of an EPC request. Moreover, the costs will probably be less, as there is no need for the supplier to add money to the budget for risk mitigation. Last, but not least, before the contract(s) for construction works is signed, the client has very limited financial risks in case of project cancelation. As the project is done step by step, it lets the client implement design changes at any stage and/or refine the strategy during project execution. This approach also allows attracting professional companies in different fields – design and construction. However, in this case the responsibility is shared between the designer and the contractor. Coordination of those parties requires a qualified team of the client to control contractors and deliver technical decisions rightly and in time. Do you have those people available in-house? Keep in mind that in case of insufficient specification of requirements from your side, there is a possibility of deviation from expected parameters in terms of quality and timing. Nevertheless, you will get the benefits of: – effective budget and quality management, – high quality of design and construction, – effective change management, – opportunity to be involved in project execution at any stage.</h5>
        </article>
        <article>
          <h1>EPC: </h1>
          <h5>Engineering, Procurement and Construction If you wish to have only one point of contact and limit your involvement in the project, probably EPC is an option for you. It means consolidated liability in case of warranty cases, effective communication and a minimum number of interfaces</h5>
        </article>
        <article>
          <h1>PMC+C: </h1>
          <h5>Project Management Consultancy + Contractor(s) Consider PMC approach if you want to split the responsibility for engineering and project management function between two contractors. It may be required when engineering required very specific process capabilities and you cannot find enough bidders with good combination of engineering and project management skills on the market. In this case PMC partner can act as client’s representative during the design phase (performing design coordination and evaluation) as well as the construction phase (being responsible for construction management on site). This model can be also applicable for projects with multi-contracting approach, when PMC partner performs nominal function of the general contractor coordinating activities of subcontractors per discipline (civil, mechanical, electrical etc.) You get the freedom to implement any changes at any stage of your project, which will be managed by the PMC service provider. Nevertheless the impact of any change on the time and budget may be significant</h5>
        </article>

      </div>
    </>
  )
}

export default EPCMEpcPmc