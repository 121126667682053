import React from "react";
import { Helmet } from "react-helmet";

const DistillationSection = () => {
  return (
    <>
      <Helmet>
        <title>Solvent Extraction Distillation Section </title>
        <meta
          name="description"
          content="The Self-wash cleaning filtration system with non-woven filter
          cloth ensuring no sediments pass through the distillation section
          resulting in better quality of oil, less corrosion and reduce
          maintenance cost."
        />
        <meta
          name="keywords"
          content=" Solvent Extraction, solvent extraction process,  extract oil from any oil-bearing seed, like soybean oil extraction, sunflower oil extraction, rapeseed oil extraction, mustard oil extraction, corn gem, Mahua neem seed, shea oil extraction, mango kernel oil extraction, sal seed, rice bran oil extraction, spent bleaching earth,solvent extraction equipment,
          solvent plant,
          Protein Concentration,
          Protein Isolation,
          solvent extraction machinery,
          oil solvent extraction plant,
          continuous solvent extraction plant,
          solvent extraction plant cost,
          solvent extraction plant process,
          edible oil solvent extraction plant,
          solvent plant cost,
          Azeotropic distillation,
          solvent extraction plant, Mecpro, is having a vast experience in extraction technology to extract oil from any oil seed
            which is containing oil, extraction technology, extract oil "
        />
      </Helmet>
      <div>
        <h2 className="Refinery">DISTILLATION SECTION </h2>
        <h5 className="mecpro1">
          The new technological development with our patented technologies
          ensures good quality of oil, and good quality of lecithin, eliminates
          corrosion and deposit of sediments which deteriorate the oil and
          equipment. The miscella will be filtered in a filter cloth using
          self-wash filtration system before entering into distillation process.
          It will prevent the sediments entering into the distillation which
          will corrode the equipment and increase the yearly maintenance and
          deteriorate the oil. This process enhance the quality of oil and
          bleachability. The patented technology for separation of hexane from
          miscella using azeotropic distillation reduces the temperature below
          65 to 80°C, which is below the oxidization temperature as well as,
          prevents deterioration of oil. In such process, quality of gums can
          convert to lecithin as a good quality and the oil produced will be
          better market. The patented recirculation of wastewater that comes out
          from the vacuum pump will reduce the steam consumption by 100 kg/hr.
          There as several other development to recover the heat from processes
          which can be discussed and incorporated into the project to reduce
          further energy consumption. <br />
          <br />
          <h1 className="pageHeading2">
            ADVANCED TECHNOLOGY FOR DISTILLATION SECTION
          </h1>
          <br />• The Self-wash cleaning filtration system with non-woven filter
          cloth ensuring no sediments pass through the distillation section
          resulting in better quality of oil, less corrosion and reduce
          maintenance cost.
          <br />• Economization with condensate water reduces energy
          consumption, improved quality and enhance efficiency.
          <br />• Entire distillation run with condensate steam ensure the
          quality of oil and increase the efficiency of desolventisation.
          <br />• D.T. vacuum load adjusting system to maintain the N2 gas and
          Air load pressure generated in the process.
          <br />• Azeotropic distillation will ensure the separation of miscella
          below 80oC will protect gum and oil deterioration.
          <br />• Recirculation of waste water from vacuum pump ensure less
          steam consumption, less water consumption and environmentally friendly
          process.
          <br />• Two step condenser cooling system for increasing the vapour
          travel time for proper condensation to eliminate hexane loss.
          <br />• Vacuum breaker system ensures all the pumps in process work
          trouble free even with less Net Positive Suction Head (NPSH).
        </h5>
      </div>
    </>
  );
};

export default DistillationSection;
