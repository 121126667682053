import React from "react";
import "./SolventExtraction.css";
import "./Refinery.css";
import { Parallax } from "react-parallax";
import SolventExtractionFaq from "./SolventExtractionFaq";
import { Helmet } from "react-helmet";
import solv from "../components/Assets/SOLVENTEXTRACTIONFULLHD.webm";
import solvios from "../components/Assets/SOLVENTEXTRACTIONFULLHD.mp4";
import extra from "../components/Assets/PREPARATORY & SOLVENT EXTRACTION FULL HD_2.mp4";
import extraios from "../components/Assets/PREPARATORY & SOLVENT EXTRACTION FULL HD.webm";
import VentAirPurificationSystem from "./VentAirPurificationSystem";
import DistillationSection from "./DistillationSection";
import ExtractionSection from "./ExtractionSection";
import DesolventizationSection from "./DesolventizationSection";
import { Link } from "react-router-dom";

const SolventExtraction = () => {
  return (
    <>
      <Helmet>
        <title>Solvent Extraction Plant </title>
        <meta
          name="description"
          content="Solvent Extraction is a process to extract oil from any oil-bearing seed like soybean, sunflower, rapeseed mustard, corn gem, Mahua neem seed, shea, mango kernel, sal seed, etc. the powder material like rice bran, spent bleaching earth. Mecpro is having a vast experience in extraction technology to extract oil from any oil seed
            which is containing oil. The recent innovation and research Mecpro could able to do significant changes to process
            the material without a preparatory section by reducing 40%power consumption and reduction of 20% FFA, 20% better color and bleachability, and reduced manpower
            and inventory."
        />
        <meta
          name="keywords"
          content=" Solvent Extraction, solvent extraction process,  extract oil from any oil-bearing seed, like soybean oil extraction, sunflower oil extraction, rapeseed oil extraction, mustard oil extraction, corn gem, Mahua neem seed, shea oil extraction, mango kernel oil extraction, sal seed, rice bran oil extraction, spent bleaching earth,solvent extraction equipment,
          solvent plant,
          Mango Kernel Oil Extraction,
          Sunflower Oil Extraction
          Rice Bran,
          What is Solvent Extraction,
          Advance Solvent Extraction process,
          Solvent Extraction Machinery,
          Oil Solvent Extraction Plant,
          Continuous Solvent Extraction Plant,
          Solvent Extraction Plant Cost,
          Solvent Extraction Plant Process,
          Edible Oil Solvent Extraction Plant,
          Solvent Plant Cost,
          Solvent extraction plant, Mecpro, is having a vast experience in extraction technology to extract oil from any oil seed
            which is containing oil, extraction technology, extract oil "
        />
      </Helmet>
      <div>
        <h1 className="Refinery">SOLVENT EXTRACTION PLANT</h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="RefinieryHeaderImage"
            src="./Images/SOLVENT1.JPG"
            alt="SOLVENT EXTRACTION-1"
          />
        </div>
        <br />
        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/3-4@2x.png"
            alt="SOLVENT EXTRACTION-2"
          />
          <article>
            Solvent Extraction is a process to extract oil from any oil-bearing
            seed like soybean, sunflower, rapeseed mustard, corn gem, Mahua neem
            seed, shea, mango kernel, sal seed, etc. the powder material like
            rice bran, spent bleaching earth. Mecpro is having a vast experience
            in extraction technology to extract oil from any oil seed which is
            containing oil. The recent innovation and research Mecpro could able
            to do significant changes to process the material without a
            preparatory section by reducing 40%power consumption and reduction
            of 20% FFA, 20% better color and bleachability, and reduced manpower
            and inventory.{" "}
          </article>
        </section>
        <h5 className="mecpro1">
          Solvent extraction is a continuous process that uses edible grade
          hexane as a solvent and non-edible grade with a non- flammable
          solvent.
        </h5>
        <section className="ref2">
          <h4>
            Mecpro offers :- <br />
            <br />
            • Reduced steam consumption <br />
            <br />
            • Reduced steam consumption low temperature miscella, Vent air
            purific-ation system in process <br />
            <br />
            • Better oil colour <br />
            <br />
            • Higher bleachability <br />
            <br />
            • Less increase in FFA as compared to any other method <br />
            <br />• Less non-hydratable phosphatides development than any other
            method <br />
            <br />
            • Less peroxide value than any other method <br />
            <br />
            • Less anisidine value than any other method <br />
            <br />
            • Lower retention time for evaporated material <br />
            <br />
            • No oxidisation or degradation of oil <br />
          </h4>
          <div className="reffImg2">
            <img
              src="./Images/untitled1-33@2x.webp"
              alt="SOLVENT EXTRACTION-3"
            />
            <img
              src="./Images/gearengineeringconcept-2@2x.webp"
              alt="SOLVENT EXTRACTION-4"
            />
          </div>
        </section>
        <h5 className="mecpro1">
          Mecpro has invested for years in research and development for
          improving the technology used in Solvent Extraction Plants. The total
          work done in this area is very vast and covers all areas from raw
          material Preparation to bagging of deoiled meal. All equipment are of
          superior construction to reduce utility consumption and downtime. The
          large number of innovations make Mecpro’s Solvent Extraction Plant
          your best choice. These reduce your operating cost, increase the
          efficiency of your plant and are environment friendly features.
        </h5>{" "}
        <br />
        <section className="refVid">
          <img
            className="solmidimg"
            src="./Images/2-181006@2x.webp"
            alt="solvent-img"
          />
        </section>
        <br />
        <br />
        <section className="Ref3">
          <h1 className="pageHeading2">Process Description</h1>
          <h5 className="mecpro1">
            After conducting a detailed study of the conventional process of
            hexane separation and the excessive losses of hexane, deterioration
            of oil and development of non-hydratable phosphatides therein, we
            have developed a new process named azeotropic distillation. It
            refers to a process in which a component (known as the solvent or
            entrainer) is added at the top of the column to form a homogeneous
            complex known as an azeotrope, which is removed as distillate
            itself. The other component free from the solvent is collected at
            the bottom of the column. In this case, the process is quite
            different from the normal stripping distillation because the solvent
            that appears as distillate can be separated at a lower temperature
            and refluxed at the top of the column for augmentation of the
            process.
          </h5>
          <section className="refVid">
            <video className="vid new" controls autoPlay muted>
              <source src={solv} type="video/webm" />
              <source src={solvios} type="video/mp4" />
            </video>
          </section>
          <br />

          <h5 className="mecpro1">
            In isothermal distillation, no physical or chemical changes take
            place to any of the components present. The complex formed in the
            vapour filter is fed to the reflux column where the hexane
            separation takes place isothermally at 65°C, the vapourising
            temperature of hexane..
          </h5>
        </section>
        <section className="refinery1">
          <article>
            By means of the reflux column, miscella separation at 65°C for
            non-frothing oil and 75°C to 85°C in frothing oil like soybean and
            mustard is achieved. Plants not using the reflux column separate the
            miscella at an elevated temperature of 120°C with sparged steam, due
            to which oxidation of oil and degradation occur. Also steam sparged
            at this elevated temperature in the stripper increases non-
            hydratable phosphatides which increases the refining loss. At a
            lower temperature with a reflex column water degumming is possible
            provided harvesting and storage of the seed was proper..{" "}
          </article>
          <img
            className="refImg"
            src="./Images/1-1801777401@2x.webp"
            alt="SOLVENT EXTRACTION-6"
          />
        </section>
        <h5 className="mecpro1">
          Mecpro has developed several technologies in solvent extraction, out
          of that five are patented. Mecpro also developed first time in the
          global market for liquid-liquid extraction to recover emulsified oil
          from water and mud. The solvent extraction is divided into various
          sections, preparation of raw material, extraction, Desolventization,
          distillation, absorption, and vent air purification. Each section is
          having different and advanced technologies from any conventional
          system prevailing in the global market. Ends results in better quality
          with less utility consumption for sustainability in the competitive
          market. The design and layout of the solvent extraction plant ensure
          the easy operation, maintenance, and lubrication of the plant by the
          operators. It also helps for the smooth running of the plant.
          Automation in the process ensures consistency in the process and
          quality of both product and by-product with reduced utility
          consumption.
        </h5>
        <br />
      </div>
      <h1 className="Refinery">EXTRACTION SECTION</h1>
      <h5 className="mecpro1">
        The extraction method is by creating penetration by designing the
        extractor and creating a wave bed to prevent flooding of hexane from one
        compartment to another compartment. The flood control system will ensure
        the hopper hexane spread on the raw material to ensure high efficiency
        in the extraction method. The temperature required for solvent in the
        extractor is partially near 60% absorbed from Desolventized vapors. The
        spray pump selection depends upon the raw material, ensuring the
        breakdown of circulation pumps in the process. The double discharge
        cutter will ensure carrying conveyors for smooth running. The other
        advanced technologies incorporated in the extraction will make
        uninterrupted operation in the solvent extraction process.{" "}
      </h5>
      <ExtractionSection /> <br /> <br />
      <DesolventizationSection /> <br /> <br />
      <DistillationSection /> <br /> <br />
      <VentAirPurificationSystem /> <br /> <br />
      <h5 className="mecpro1">
        <Link to="https://www.mecpro.com/blog-post/6631cffdaa214d31d6273c12">
          {" "}
          _What is Solvent Extraction----{" "}
        </Link>
        <br />
        <Link to="https://www.mecpro.com/blog-post/6687dfffaf507e4334b4a3d5">
          {" "}
          _Advance Solvent Extraction process----{" "}
        </Link>
      </h5>
      <h1 className="faqHeading">Frequently Asked Questions!</h1>
      <SolventExtractionFaq />
    </>
  );
};

export default SolventExtraction;
