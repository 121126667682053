



const Groundnutoilextraction = () => {
  return (
    <>





      <h1 className="pageHeading">GroundNut Oil Extraction</h1>







    </>
  );
};
export default Groundnutoilextraction;