import React from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import "./BlogCard.css";
import { useNavigate } from "react-router-dom";

const BlogCard = ({ title, image, description, id }) => {
  const navigate = useNavigate();
  const shortDescription = description.slice(0, 300);
  const blogDetails = () => {
    localStorage.setItem("id", id);
    navigate(`/blog-post/${id}`);
  };
  return (
    <>
      <Card>
        <Card.Img variant="top" src={image} />
        <Card.Body>
          <Card.Title>{title}</Card.Title>
          <Card.Text style={{ color: "#000" }}>
            {shortDescription + "..."}
          </Card.Text>
          <Button variant="primary" onClick={blogDetails}>
            Read More
          </Button>
        </Card.Body>
      </Card>
    </>
  );
};

export default BlogCard;
