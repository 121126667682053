import React from 'react'
import Accordion from 'react-bootstrap/Accordion';


const biodieselFaq = () => {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>What is biodiesel.?
                </Accordion.Header>
                <Accordion.Body>
                    Biodiesel consists of alkyl mono esters of fatty acid derived from vegetable oils. In the process
                    of trans-esterification methanol will be added in the presence of catalyst and methanol convert
                    to methyl ester and glycerin as by-product.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>What are all the solvents used.?

                </Accordion.Header>
                <Accordion.Body>
                    Methanol, alcohol, ethanol, propanol etc.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Which are all the catalyst used?
                </Accordion.Header>
                <Accordion.Body>
                    Sodium hydroxide and potassium hydroxide are commonly used.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>	Which are all the catalyst used.?
                </Accordion.Header>
                <Accordion.Body>
                    a) Trans-esterification process performed in hydro-cavity reactor which will perform
                    trans-esterification in single stage with less than 40 min as against three stage trans-esterification process
                    b) Less methanol required in the trans-esterification process
                    c) Efficient methanol separation
                    d) High FFA oil can be a feedstock. Hence lower cost raw material available. In-term
                    enhance the profitability
                    e) Less energy required in the distillation using in-built economizer in the column
                    f) No steam produced from the cooling water, hence less energy and cooling water
                    required.                </Accordion.Body>
            </Accordion.Item>



        </Accordion>
    )
}

export default biodieselFaq