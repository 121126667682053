import React, { useEffect, useState } from 'react'
import './Media.css'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from 'axios'
import BlogCard from './BlogCard';
const Media = () => {
    const [blogs, setBlogs] = useState([])

    //Get All Blogs
    /*  const getAllBlogs = async () => {
 
         try {
             const { data } = await axios.get('http://localhost:8080/api/v1/blog/all-blogs');
             setBlogs(data?.blogs)
             if (data.success) {
                 console.log(data.response);
 
 
             }
         } catch (error) {
 
             alert("error")
 
         }
 
     } */

    useEffect(() => {

        axios.get(' https://us-central1-mecpro-bcf7d.cloudfunctions.net/api/api/v1/blog/all-blogs')
            .then((response) => {

                setBlogs(response.data.blogs)

            })


    }, [])

    return (
        <>
            <div className='mediaContainer'>
                {blogs.map((blogs) => {
                    return (

                        <BlogCard
                            image={blogs.image}
                            title={blogs.title}
                            description={blogs.description}
                            id={blogs._id}
                        />

                    )
                })}
            </div>
        </>
    )
}

export default Media