import "./AboutUs.css";
import "./Refinery.css";
import { Parallax } from "react-parallax";
import vid from "../components/Assets/corporate Film.webm";
import { Helmet } from "react-helmet";

const Refinery = () => {
  return (
    <>
      <Helmet>
        <title> About Us</title>
        <meta
          name="discription"
          content="Mecpro Heavy Engineering Ltd in India and Malaysia, a trusted and
            dependable ISO certified technology provider having over 36 years
            of experience in oils & fats, oleo-chemicals, surfactants, distilleries,
            biodiesel in India and across the globe. We also operate in other
            sector that include food processing, agriculture, cement, steel and
            Petro-chemical projects on a BOT basis with holding 17 Patents. Mecpro has successfully commissioned over 280 projects worldwide
            on a turnkey basis, with enhanced productivity and efficiency. Mecpro
            has the capability and expertise to meet your challenges and expecta-
            tions with regard to environment friendly and energy efficient projects. Mecpro has achieved several accolades including 8 national awards,
            2 corporates awards and number of excellence awards for the development of technology and environment excellence. Mecpro also written
            and published 3 books for the department of scientific and industrial
            research for technology evaluation for adoption and absorption scheme
            under their paster scheme. Mecpro also participated in the research of
            “Added value from high protein & high oil industrial co-streams” under
            European Union Apropos."
        />
        <meta
          name="keyword"
          content="Bio Diesel, hydrogenation, fractionation, Layer Esterification, Refinery, mecpro heavy engineering limited, palm oil mill, oil mill, palm kernel oil, oil extraxtion process, solvent extraction,"
        />
      </Helmet>

      <div className="refinaryContainer">
        <div>
          <h1 className="Refinery">ABOUT US</h1>
        </div>
        <Parallax blur={0} bgImage=".\Images\1.webp" strength={500}>
          <div className="HimgContainer"></div>

          {/* <h1 className="pageHeading">About Us</h1> */}
        </Parallax>
        <br />
        <br />

        <section className="refinery1">
          <img
            className="refImge"
            src="./Images/untitled2-11@2x.webp"
            alt="Refinery-img1"
          />
          <h5 className="ref1h5">
            Mecpro Heavy Engineering Ltd in India and Malaysia, a trusted and
            dependable ISO certified technology provider having over 38 years of
            experience in oils & fats, oleo-chemicals, surfactants,
            distilleries, biodiesel in India and across the globe. We also
            operate in other sector that include food processing, agriculture,
            cement, steel and Petro-chemical projects on a BOT basis with
            holding 17 Patents. <br /> Mecpro has successfully commissioned over
            280 projects worldwide on a turnkey basis, with enhanced
            productivity and efficiency. Mecpro has the capability and expertise
            to meet your challenges and expecta- tions with regard to
            environment friendly and energy efficient projects. <br /> Mecpro
            has achieved several accolades including 8 national awards, 2
            corporates awards and number of excellence awards for the
            development of technology and environment excellence. Mecpro also
            written and published 3 books for the department of scientific and
            industrial research for technology evaluation for adoption and
            absorption scheme under their paster scheme. Mecpro also
            participated in the research of “Added value from high protein &
            high oil industrial co-streams” under European Union Apropos7.{" "}
          </h5>
        </section>

        <br />
        <br />
        <h5 className="mecpro1">
          Mecpro has the core competence to optimize operations, and also the
          expertise to offer matchless guaranteed performance parameters, since
          we have always believed in taking the lead to set global standards in
          oil milling, extraction, refining and other related process
          technologies.
        </h5>
        <br />

        <h5 className="mecpro1">
          At Mecpro, innovation is ingrained in our working and shall go as far
          as our pioneering spirit takes us. Innovation in palm oil processing
          is a vast subject, and here we are focusing on the following areas in
          the palm oil industry. These are based on proven and cutting-edge
          state-of-the-art technologies to improve the oil recovery rate, paring
          the processing cost, increasing the by-products application, reducing
          the energy consumption and improving the quality of both product and
          by-product, may be by the time you read these words, we would have
          come out with some new techniques in the area.
        </h5>
        <br />
        <section className="refVid">
          <video src={vid} autoPlay loop controls muted></video>
        </section>
        <br />
        <br />
        <br />
        <br />

        <section className="refinery1">
          <h5 className="ref1h5">
            Mecpro founded by Rajan Skhariya with the vision of serving society
            through research and innovation in his chosen field of Oil & Fats,
            Oleo chemicals and Surfactants . As a result of his foresight,
            Mecpro is not a mere supplier of plant and machinery, but is an
            institution for creativity and design excellence. His credo is hard
            work, commitment to quality and taking care of the customer, come
            what may. He is a mechanical engineer with a thorough grasp of the
            chemistry of oils and fats. He therefore focuses a lot not on just
            putting up plants for his numerous clients but on research and
            development. With 17 patents in the field to his credit, the recent
            research in POME oil extraction and fibre oil extraction patented
            technology will support inhance in oil production of 6 million
            metric ton which is 3% of the global oil production. The current
            technological development for powder material namely Rice bran &
            Spent Bleeching Earth Extraction without using any preporatory
            section reduce 40% power cosumption in the solvent extraction plant.{" "}
          </h5>

          <img
            className="refImg"
            src="./Images/Rajan sir256.jpg"
            alt="Refinery-img1"
          />
        </section>
        <br />
        <br />
        <br />
        <br />

        <h1 className="pageHeading2">Our Values</h1>
        <div className="values">
          <div className="value">
            <h2>
              Rajan Skhariya <br />
              Managing Director
            </h2>
            <h5 className="mecpro1">
              These values are simple and easy to remember. these values would
              serve no purpose if they are not practised. Hence carry these
              values not just with you, but most importantly within you, all the
              time.
            </h5>
          </div>
          <div className="value2">
            <div className="val1">
              <h5>
                Respect for the individuals we interact with and the environment
                that we operate in (internally and externally)
              </h5>
              <br />
              <br />
              <br />
              <br />
              <h5>
                Seek and seize opertunities with speed and agility challenging
                the set houdaries
              </h5>
            </div>

            <div className="val2">
              <img src="Images/Group 2169.png" alt="" />
            </div>

            <div className="val3">
              <h5>
                Stretch the horizons of growth for ourselfs, our buisiness and
                our people through our unwavering embition to achieve
                outstanding personal and buisness result.
              </h5>{" "}
              <br />
              <br />
              <br />
              <br />
              <h5>
                Uphold high levels of personal and professional values in all
                our business interations and decisions
              </h5>
              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Refinery;
