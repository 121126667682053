import React from 'react';
/* import { Swiper, SwiperSlide } from 'swiper/react'; */
/* import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation'; */
/* import { Autoplay, EffectCoverflow, Pagination, Navigation } from 'swiper/modules'; */
import './Awardhom.css'
import awdimg4 from './Assets/14. INDIAN ACHIEVERS AWARD.jpg'
import awdimg5 from './Assets/3.9.jpg'
import awdimg6 from './Assets/3.3.jpg'
import awdimg7 from './Assets/3.2.jpg'
import awdimg8 from './Assets/3.1.jpg'
import awdimg9 from './Assets/1.jpg'
import awdimg10 from './Assets/APEAAWARD10.JPG'
import awdimg11 from './Assets/2.jpg'
import awdimg12 from './Assets/12. EEPC AWARD.jpg'
import awdimg13 from './Assets/ASSOCHAM AWARD13.JPG'
import awdimg14 from './Assets/14. INDIAN ACHIEVERS AWARD.jpg'
import awdimg15 from './Assets/OTAI INDIA AWARD-2004.jpg'
/* import awdimg16 from './Assets/4.1.jpg' */
/* import awdimg17 from './Assets/4.2.jpg' */
import awdimg18 from './Assets/4.3.jpg'
import awdimg19 from './Assets/4.4.jpg'
import awdimg20 from './Assets/4.5.jpg'
import awdimg21 from './Assets/4.6.jpg'
import awdimg22 from './Assets/4.7.jpg'
import awdimg23 from './Assets/4.8.jpg'
import awdimg24 from './Assets/4.9.jpg'
import awdimg25 from './Assets/4.10.jpg'
import awdimg26 from './Assets/4.11.jpg'
import awdimg27 from './Assets/3. MSME NATIONAL AWARD-11.jpg'

const Awardhom = () => {
  return (
    <>

      <h1 className='ClientHeading'>Achievements</h1>
      <div className="Awards1">
        <marquee behavior="alternate" direction="right" >
          {/* <img src={awdimg3} alt="Awards" /> */}
          <img src={awdimg4} alt="Awards" />
          <img src={awdimg5} alt="Awards" />
          <img src={awdimg6} alt="Awards" />
          <img src={awdimg7} alt="Awards" />
          {/* <img src={awdimg8} alt="Awards" /> */}
          <img src={awdimg9} alt="Awards" />
          <img src={awdimg10} alt="Awards" />
          <img src={awdimg20} alt="Awards" />
          <img src={awdimg12} alt="Awards" />
          <img src={awdimg13} alt="Awards" />
          <img src={awdimg14} alt="Awards" />
          <img src={awdimg15} alt="Awards" />

          <img src={awdimg24} alt="Awards" />
          <img src={awdimg18} alt="Awards" />
          <img src={awdimg19} alt="Awards" />
          <img src={awdimg23} alt="Awards" />
          <img src={awdimg21} alt="Awards" />
          <img src={awdimg22} alt="Awards" />
          <img src={awdimg27} alt="Awards" />

          <img src={awdimg25} alt="Awards" />
          <img src={awdimg26} alt="Awards" />
          <img src={awdimg11} alt="Awards" />


        </marquee>




      </div><br /><br />
    </>
  )
}

export default Awardhom