



const Soyabeanoilextraction = () => {
  return (
    <>





      <h1 className="pageHeading">Soya Bean Oil Extraction</h1>







    </>
  );
};
export default Soyabeanoilextraction;