
import ImageGallery from "react-image-gallery";
import './Patentsf.css'
import patent0 from '../components/images/patent/1. INDIA 217089 AN IMPROVED WASTE WATER RECYCLING APPARATUS ADAPTABLE TO A SOLVENT EXTRACTION PLANT FOR.............. (1).png';
import patent1 from '../components/images/patent/2. INDIA 230865 APROCESS AND AN APARATUS FOR SEPERATION OF EDIBLE OIL AND EDIBLE SOLVENT FROM MISCELLA-1.png';
import patent3 from '../components/images/patent/006.png';
import patent4 from '../components/images/patent/CERTIFACATE OF GRANT 1709 ETP 24.3.2020-1.png';
import patent5 from '../components/images/patent/Patent Certificate.jpg';
import patent6 from '../components/images/patent/9.png';
import patent7 from '../components/images/patent/CERTIFICATE OF GRANT-MY 169306-A, FREE FATTY ACID (LAYER ESTERI.jpg';
import patent8 from '../components/images/patent/3. INDIA 230881 A SOLVENT RECOVERY APARATUS.webp';
import patent9 from '../components/images/patent/17. THAILAND -1501006431 FILING APPL - OIL RECOVERY FROM EFFLUENT WATER-PATENT.webp'
import Award from '../components/Award'











const Patentsf = () => {


    const images = [
        {
            original: [patent0],
            thumbnail: [patent0],
        },
        {
            original: [patent1],
            thumbnail: [patent1],
        },
        {
            original: [patent3],
            thumbnail: [patent3],
        },
        {
            original: [patent4],
            thumbnail: [patent4],
        },
        {
            original: [patent5],
            thumbnail: [patent5],
        },
        {
            original: [patent6],
            thumbnail: [patent6],
        },
        {
            original: [patent7],
            thumbnail: [patent7],
        },
        {
            original: [patent8],
            thumbnail: [patent8],
        },
        {
            original: [patent9],
            thumbnail: [patent9],
        },

    ];

    return (

        <>




            <ImageGallery thumbnailPosition='bottom' items={images} />
            <Award />
        </>
    );
}
export default Patentsf


