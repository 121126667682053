import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import "./Header.css";
import image from "./images/logo.png";
import { Link, useNavigate } from "react-router-dom";

const Header = () => {
  let Logo = useRef();
  let SiteHeading = useRef();
  let SiteHeading2 = useRef();

  useEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap.timeline();

      tl.to(Logo.current, {
        duration: 2,
        ease: "easeIn",
        clipPath: "polygon(0 0, 100% 1%, 100% 100%, 0 98%)",
      });

      tl.from(SiteHeading.current, {
        opacity: 0,
        duration: 1,
        ease: "easeIn",
      });
      tl.from(SiteHeading2.current, {
        opacity: 0,
        duration: 1,
        ease: "easeIn",
      });
    });
    return () => ctx.revert();
  }, []);

  return (
    <>
      <header>
        <div className="containerHead">
          <div className="headerTop">
            <Link to="/">
              <img className="Logo" src={image} alt="logo" ref={Logo} />{" "}
            </Link>

            <div className="siteHeading">
              <Link to="/">
                <h1 className="siteHeading1" ref={SiteHeading}>
                  MECPRO{" "}
                </h1>

                <h2 className="siteHeading2" ref={SiteHeading2}>
                  HEAVY ENGINEERING LIMITED
                </h2>
              </Link>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
export default Header;
