import { Helmet } from "react-helmet";
import "./PalmOilMill.css";

const PalmOilMill = () => {
  return (
    <>
      <Helmet>
        <title>PALM Oil Mill</title>
        <meta
          name="description"
          content="The new Palm Oil Mill design will decrease
            the effluent and ponding system by 80-90%
            and enhance the OER by 1%. By Introducing
            new patent technologies for Palm Fibre Oil
            extraction and emulsified oil recovery from
            POME. Further to that energy efficient water
            evaporation system will make pond less palm
            oil mill system."
        />
        <meta
          name="keywords"
          content="Mesocarp fibre, PALM FIBRE, Palm oil extraction, palm oil mill, Palm oil system "
        />
      </Helmet>
      <div>
        <h1 className="Refinery">PALM OIL MILL</h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="RefinieryHeaderImage"
            src="./Images/palmoilmill.png"
            alt="cotton -Header-Img"
          />
        </div>

        {/* <h1 className="pageHeading">Palm Oil Mill</h1> */}

        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/1 1801777411.png"
            alt="PALM OIL MILL"
          />
          <h5 className="ref1h5">
            The new Palm Oil Mill design will decrease the effluent and ponding
            system by 80-90% and enhance the OER by 1%. By Introducing new
            patent technologies for Palm Fibre Oil extraction and emulsified oil
            recovery from POME. Further to that energy efficient water
            evaporation system will make pond less palm oil mill system.
          </h5>
        </section>
        <h1 className="pageHeading2">
          ADVANCED TECHNOLOGIES IN PALM OIL MILL{" "}
        </h1>
        <h5 className="mecpro1">
          Elimination of Decanter and Centrifuge will reduce the energy
          consumption and manpower significantly. It will be an environ- mental
          friendly project as compared to present technology adopted.{" "}
        </h5>
        <section className="refVid">
          <img
            className="solmidimg"
            src="./Images/2-181015@2x.png"
            alt="PALM OIL MILL-1"
          />
        </section>
        <h5 className="mecpro1">
          MECPRO has been providing innovative solutions for over 35 years and
          has earned a global reputation by leading the way as a reliable
          technology provider & turnkey plant and machinery supplier, that
          provides highly efficient solution which address to unique challenges
          and delivers results that our customers need to achieve success.
        </h5>

        <section className="refVid">
          <img
            className="solmidimg"
            src="./Images/3-11@2x.png"
            alt="PALM OIL MILL-2"
          />
        </section>
        <br />
      </div>
    </>
  );
};

export default PalmOilMill;
