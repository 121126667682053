import React, { useEffect, useRef } from 'react'
import { gsap, Expo } from 'gsap'
import { Parallax } from "react-parallax"
import { ScrollTrigger } from 'gsap/ScrollTrigger'

import './Service.css'
import serviceImg3 from './images/service3.webp'
import serviceImg2 from './images/Services3.webp'







function Service() {

  gsap.registerPlugin(ScrollTrigger)
  let service1 = useRef();
  let service2 = useRef();

  useEffect(() => {

    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".scontainer",
          start: "50% 80%",
          end: "80% bottom",
          scrub: 3,


        }


      })

      tl.from(service1.current, {
        yPercent: 100,
        duration: 2,
        opacity: 0,
        ease: Expo.easeIn,


      }, 'a')
      tl.from(service2.current, {

        yPercent: -100,
        duration: 3,
        opacity: 0,
        ease: Expo.easeIn,



      }, 'b')


















    })

    return () => ctx.revert();
  }, [])
  return (
    <>
      <div className="scontainer">
        <h1 className='serviceHeading'>SERVICES</h1>
        <Parallax blur={0} bgImage="https://images.unsplash.com/photo-1450101499163-c8848c66ca85?q=80&w=2670&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" strength={600}>

          <div className="serviceContainer">

            <img ClassName='serviceIMG1' id='service2' src={serviceImg3} alt="Service" ref={service1} />
            <img id='service1' ClassName='serviceIMG' src={serviceImg2} alt="Service2" ref={service2} />

          </div>
        </Parallax>
      </div>



    </>
  )
}

export default Service