import React, { useRef } from "react";
import "./VentAirPurificationSystem.css";

import { Helmet } from "react-helmet";

const VentAirPurificationSystem = () => {
  return (
    <>
      <Helmet>
        <title>Solvent Extraction Plant Process</title>
        <meta
          name="description"
          content=" Vent Air Purification System will support
        to creation a vacuum in the process. The losses of solvent observed that
        during continuous operation out of the total hexane losses, 35% are
        through vent air, 40% through desolventizing toaster, 5% through oil,
        and 20% through leakages."
        />
        <meta
          name="keywords"
          content=" Vent Air Purification System,  will
          support to creation a vacuum in the solvent extraction process,  
          solvent plant,
          desolventizing toaster,
          oil solvent extraction plant,
          continuous solvent extraction plant,
          solvent extraction plant cost,
          solventisation,
          edible oil solvent extraction plant,
          desolventisation,
          losses of solvent,
          hexane loss,
           Thermo-Dyno Cooling System,
           WATER COOLING SECTION
           "
        />
      </Helmet>
      <div>
        {" "}
        <h2 className="Refinery text-left">VENT AIR PURIFICATION SYSTEM </h2>
        <h5 className="mecpro1">
          The patented technology of the Vent Air Purification System will
          support to creation a vacuum in the process. The losses of solvent
          observed that during continuous operation out of the total hexane
          losses, 35% are through vent air, 40% through desolventizing toaster,
          5% through oil, and 20% through leakages. The vent air purification
          system will support these losses up to 95% and the solvent extraction
          hexane loss will be reduced up to below 0.9 kg/MT. <br />
          <br />
          In advanced technology, the vent air is taken back into the vent air
          purification system instead of discharging to the atmosphere, where
          the air is dissolved in the water by high-pressure mixing. Hexane is
          arrested by water, which is then taken into the water separator, and
          hexane is recovered for re-use. The final traces of hexane and air
          that escape from the purification system are taken to the extractor
          where the hexane is absorbed by the raw material and the air is
          discharged. <br />
          <br />
          <h1 className="pageHeading2">
            ADVANCED TECHNOLOGY VENT AIR PURIFICATION SECTION
          </h1>{" "}
          <br />• Advanced Technology for Recuperation System Supported with
          Vent Air Purification ensure controlling of vacuum in the process,
          eliminate excess loss, fugitive loss, purging loss, functional loss
          and will take care of operator’s mistakes. It also helps to recover
          the hexane during the solventisation of project for maintenance and
          environmentally friendly process.
        </h5>
        <h1 className="pageHeading2">
          ADVANCED TECHNOLOGY IN THE THERMO-DYNO COOLING SYSTEM{" "}
        </h1>
        <h5 className="mecpro1">
          • Thermo-dyno cooling system will help to separate the final traces of
          hexane from the oil by gradual flow cooling under vacuum.{" "}
        </h5>
        <h1 className="pageHeading2">
          PROCESS DESCRIPTION FOR WATER COOLING SECTION
        </h1>
        <h5 className="mecpro1">
          • The design of water-cooling system by using section and discharge
          under vacuum reduce the head of the pump ensuring less power
          consumption and trouble free operation.{" "}
        </h5>{" "}
        <br />
        <br />
      </div>
    </>
  );
};

export default VentAirPurificationSystem;
