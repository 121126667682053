import React from "react";
import { Helmet } from "react-helmet";

const Tomato = () => {
  return (
    <>
      <Helmet>
        <title>Tomato process</title>
        <meta
          name="description"
          content="Solar cell is one of the cost-effective methods to produce power in Chad. Climatic condition is suitable for implementation of solar cell in an economical way. In regard to land there are several acres of land is available for the installation of solar cell besides, floating cells implementation in the lake. Within 15000 m2 area one can produce 1 MW power from the solar cell in Chad, Chad is having raw material suitable to manufacture solar cells a suitable project can be envisaged for constructing to reduce the import cost of solar cells for future developments.
        The world is moving forward to stop carbon emission with such installation Chad also can be a part in that development. Solar energy, wind mill energy, hydrogen energy, thermal power plants, waste heat power plants, heavy fuel generators and hydro-power energy; these are the source for power generation."
        />
        <meta
          name="keywords"
          content=" tomato, tomato process, tomato farming, tomato cultivation"
        />
      </Helmet>

      <div>
        <h1 className="Refinery">TOMATO PROCESS</h1>
      </div>

      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="RefinieryHeaderImage"
            src="./Images/tomato.jpg"
            alt="tomato"
          />
        </div>
      </div>
      <br />

      <h5 className="mecpro1">
        The sachet Tomato Paste Manufacturing Project will process between 2,500
        to 6,000 metric tons of Tomato from the hydroponics farm into sachet
        Tomato Paste Products. The tomato processing plant where fresh tomatoes
        will be processed to manufacture tomato puree/ketchup, tomato
        concentrate and tomato paste. Plant comprises following sections:.
      </h5>
      <br />
      <h5>RECEPTION SECTION:</h5>
      <h5>
        Fresh tomatoes would be received at reception section and passed on to
        the fruit washing grading section. Tomatoes from reception will be
        washed in washing section to remove outer dirt and foreign matter from
        the fruit skin. Washed tomato would be feed to the inspection cum
        sorting conveyor where unwanted, damaged tomatoes would be separated out
        manually. Major Equipments: In feed conveyor, Tomato Washer, Sorting
        Table.
      </h5>
    </>
  );
};

export default Tomato;
