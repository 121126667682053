import React from "react";
import "./PalmFibreLanding.css";
import { Helmet } from "react-helmet";

const PalmFibreLanding = () => {
  return (
    <>
      <Helmet>
        <title> Solvent Extraction Plant-Palm Fibre Oil Extraction</title>
        <meta
          name="discription"
          content="Palm fibre is a by-product
            after extraction of Oil from Palm
            Fruits. Due to limitation of mech-
            anical pressing 5-6% oil is left over
            in Mesocarp Fiber."
        />
        <meta
          name="keyword"
          content="Palm Fibre Oil Extraction Plant, palm fibre Plant, palm fibre oil, Mesocarp Fibre Solvent Extraction Plant,  "
        />
      </Helmet>
      {/*    <h1>POME</h1> */}
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="RefinieryHeaderImage"
            src="./Images/header-2@2x.png"
            alt="Refinary-Header-Img"
          />
        </div>
      </div>

      <h3 className="pageHeading2">
        PALM OIL MILL EFFLUENT (POME) NEEDS TO BE PROPERLY TREATED OR RECYCLED
        OR REDUCED
      </h3>

      <section className="Pome1">
        <div className="PomePotential">
          <h3>AREAS WITH GREAT POTENTIAL</h3>
          <img src="./Images/POME-potential.png" alt="Pome-Potential" />
          <ul>
            <li>
              INNOVATION AND RESEARCH IN PALM PROCESSING SECTOR IS A VAST
              SUBJECT
            </li>
            <li>
              VIRTUALLY EVERY BY-PRODUCT CAN BE IMPROVED FOR PROPER UTILIZATION
            </li>
            <li>TECHNOLOGICAL DEVELOPMENT HELP IN PARING COST OF PRODUCTION</li>
            <li>
              EXPERIENCES & IMPROVEMENTS IN PALM OIL FRACTIONATION BEING SHARED
            </li>
          </ul>
        </div>
        <div className="PomeProblem">
          <h3>PROBLEM STATEMENTS</h3>
          <img src="./Images/POME-Problem-statements.png" alt="Pome-Problem" />
          <ul>
            <li>PRESENT TECHNOLOGY FOR POME TREATMENT</li>
            <li>WSP: 95% OF TREATMENT PLANTS</li>
            <li>PROBLEMS AND LIMITATIONS OF WSP:</li>
            <li>LONG HYDRAULIC RETENTION TIME (HRT)</li>
            <li>LARGE SURFACE AREA</li>
            <li>ALGAL GROWTH</li>
            <li>ODOR FORMATION</li>
            <li>UNABLE TO ACCEPT SHOCK LOADINGS</li>
          </ul>
        </div>
        <div className="PomeWaste">
          <h3>WASTE PRODUCED BY PALM OIL MILL PRODUCTION</h3>
          <img src="./Images/POME-Waste.png" alt="Pome-Waste" />
          <ul>
            <li>Solid:-</li>
            <li> EMPTY FRUIT BUNCH : 30-35%</li>
            <li>SHELL : 6-7%</li>
            <li> FIBRE : 12-13%</li>
            <li> PALM KERNAL CAKE : 6-7%</li>
            <li>Liquid:-</li>
            <li> PALM OIL MILL EFFLUENT :1:0.75 RATIO(POME)</li>
          </ul>
        </div>
      </section>

      <section className="Pome2">
        <img src="./Images/POME-Overview.png" alt="POME-Overview" />

        <ul>
          <h3>Overview</h3>
          <li>
            PALM OIL MILL EFFLUENT (POME) DISCHARGED FROM THE CPO PRODUCTION
            PROCESS CURRENTLY UNDERGOES ANAEROBIC AND AEROBIC LAGOON TREATMENT
            TO COMPLY WITH THE EFFLUENT STANDARDS.{" "}
          </li>
          <li>
            THIS TREATMENT RELEASES A LARGE QUANTITY OF METHANE GAS INTO THE
            ATMOSPHERE, WHICH HAS 21 TIMES OF EFFECTS COMPARED WITH CO2 AS GHGS.
          </li>
          <li>ANOTHER ISSUE IS THE CONTAMINATION (COD, BOD ETC.) </li>
        </ul>
      </section>
      <section className="pome3">
        <div className="PomeComposition">
          <h3>PALM OIL MILL EFFLUENT (POME)</h3>
          <img src="./Images/POME-source.png" alt="Pome-Potential" />
          <ul>
            <h4>COMPOSITION OF POME</h4>
            <li>96% OF WATER</li>
            <li>4% TOTAL SOLID </li>
            <li>DESOLVED MUD</li>
            <li>0.7 % OIL RESIDUE</li>
            <h4>POME 80-900C</h4>
          </ul>
        </div>
        <div className="PomeSource">
          <h3>SOURCE OF EFFLUENT</h3>
          <img src="./Images/POME-composition.png" alt="Pome-Potential" />
          <ul>
            <li>STERILIZER : 45%</li>
            <li>PRESS, CLARIFIER, DECANTER, CENTRIFUGE : 39% </li>
            <li>HYDROCYCLONE : 16%</li>
          </ul>
        </div>
      </section>
    </>
  );
};

export default PalmFibreLanding;
