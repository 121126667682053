import "./Pome.css";
import PomeFaq from "./PomeFaq";
import pome from "../components/Assets/Effluent Water Full  HD.webm";
import pomeios from "../components/Assets/Effluent Water Full  HD.mp4";
import { Helmet } from "react-helmet";

const Pome = () => {
  return (
    <>
      <Helmet>
        <title>EMULSIFIED OIL RECOVERY FROM PALM OIL MILL EFFLUENT</title>
        <meta
          name="description"
          content="The world produces 200 million MT of vegetable oil, out
            of that 90 million MT is Palm Oil which is contributing 45%
            of the world oil production."
        />
        <meta
          name="keywords"
          content="Mesocarp fibre, POME, pome, PALM FIBRE, palm fibre, Palm oil extraction, palm oil mill effluent, Palm oil system "
        />
      </Helmet>
      <div>
        <h1 className="Refinery">
          EMULSIFIED OIL RECOVERY FROM PALM OIL MILL EFFLUENT
        </h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="RefinieryHeaderImage"
            src="./Images/header-2@2x.png"
            alt="pome"
          />
        </div>

        {/* <h1 className="pageHeading2">EMULSIFIED OIL RECOVERY FROM PALM OIL MILL EFFLUENT
        </h1> */}

        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/1-1801777409@2x.png"
            alt="pome-1"
          />
          <article>
            The world produces 200 million MT of vegetable oil, out of that 90
            million MT is Palm Oil which is contributing 45% of the world oil
            production. The effluent is generated from the process is 270
            million MT of effluent which carryover 0.9 to 1.5% oil, which is
            counted as 2.7 million MT, out of this 20% recovered presently as a
            waste sludge oil and 80% is absorbed in the mud and settled down in
            the bottom of ponds.
          </article>
        </section>
        <h1 className="pageHeading2">
          Recovery of oil from underflow with solvent extraction
        </h1>

        <section className="refinery1">
          <article>
            Mecpro invented and patented the technology to recover the oil from
            the effluent water without any deterioration of oil, which will
            increase the OER 0.5% in palm oil mill, the FFA in the oil will be
            only 2% higher than CPO produced. The POME processed in a closed
            circuit to avoid any deterioration of oil in the process. The
            commercialized plant is running successfully in the past three
            years.
          </article>
          <img
            className="reffImg2"
            src="./Images/2-181013@2x.png"
            alt="pome-1"
          />
        </section>
        <br />
        <section className="refinery1">
          <div>
            <h1>
              "Reduction of effluent and elimination of decanter and centrifuge
              process."
            </h1>
          </div>
          <img className="reffImg2" src="./Images/3-10@2x.png" alt="pome-2" />
        </section>
        <h5 className="pome-text-right">
          'Our modern technology will prevent oil spillage to the pond and will
          recover good quality oil from POME'
        </h5>
        <br />
        <h5 className="mecpro1">
          The modified oil separation in the palm oil mill after pressing will
          eliminate minimum 95% of the oil will separate in a continuous flow,
          without going to the clarifier and balance 1 to 5% will be separated
          from the effluent in closed circuit by using solvent. With the
          development of separation technology and POME oil extraction enhance
          the DOBI value and increase the OER by 0.6 to 0.8% and eliminate the
          clarifier and decanter from the oil room process in the palm oil mill.
        </h5>
        <br />
        <section className="refVid">
          <video className="vid new" controls autoPlay muted>
            <source src={pome} type="video/webm" />
            <source src={pomeios} type="video/mp4" />
          </video>
        </section>
        <br />
        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/untitled1-41@2x.png"
            alt="pome-3"
          />

          <article>
            The best operated palm oil mill discharge 1:0.6% effluent. Effluent
            generated from sterilization section is 140 liter, clarifier and
            decanter section 425 liter, purifier section 27 liter, and EFB 8
            liter MT FFB processed. The new method of online separation will
            reduce the effluent minimum by 100 liter from the mill process. So,
            the next generation plant
            <br /> After removal of oil the mass can be transferred for bio-gas
            generation, incase bio-gas generation is not option, the same can be
            filtered and evaporated to re-use the water to eliminate any water
            effluent in the palm oil mill. Even after the bio-gas generation the
            mass can be filtered and evaporated for re-use. The filtered sludge
            can be converted to fertilizer.
          </article>
        </section>
        <br />
        <br />
        <section className="refinery1">
          <img className="pomeimg" src="./Images/4-6@2x.png" alt="pome-4" />

          <img className="pomeimg" src="./Images/5-4@2x.png" alt="pome-5" />
        </section>
        <div className="pomeLast">
          <img
            className=" pomeLastImg "
            src="./Images/6-12@2x.png"
            alt="Pome-6"
          />
        </div>

        <h5 className="mecpro1">
          Mecpro developed zero waste zero effluent palm oil mill converting
          by-product as a value added product and utilizing all the waste
          material. The latest technological development in the palm oil mill
          process recovery of oil from effluent, filtered and evaporated the
          water for re-use and filtered sludge for fertilizer. Mesocarp fiber
          and EFB taken to the solvent extraction and extract the oil and the
          de-oiled meal send to the boiler as fuel. Decanter sludge added with
          the POME and extract the oil. Palm kernel shell converted to smokeless
          coke. Boiler ash added with sludge and converted to brick or
          fertilizer.
        </h5>
        <br />
      </div>
      <h1 className="faqHeading">Frequently Asked Questions!</h1>
      <div className="faqContainer">
        <PomeFaq />
        <div className="faqImages">
          {/* <img src="./Images/IMG-20150729-WA0022.jpg" alt="" /> */}
          <img src="./Images/CIMG0075.JPG" alt="pome-6" />
          <img src="./Images/IMG-20150729-WA0023.jpg" alt="pome-7" />
          <img src="./Images/CIMG0078.JPG" alt="pome-8" />
        </div>
      </div>
    </>
  );
};

export default Pome;
