import { useLayoutEffect } from 'react'
import { useLocation } from "react-router-dom"

const ScrollTop = () => {

    const { pathname } = useLocation();

    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: "auto" });
    }, [pathname])
    return null;
};


export default ScrollTop


/* import { useEffect } from 'react'
import { useLocation } from "react-router-dom"

export default function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}
 */