import React, { useState } from "react";
import "./CreateBlog.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const CreateBlog = () => {
  const [inputs, setInputs] = useState({
    title: "",
    description: "",
    image: "",
    keywords: "",
  });
  // input handleChange
  const handleChange = (e) => {
    setInputs((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const Navigate = useNavigate();
  //Form SUbmit
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const { data } = await axios.post(
        "https://us-central1-mecpro-bcf7d.cloudfunctions.net/api/api/v1/blog/create-blog",
        {
          title: inputs.title,
          description: inputs.description,
          image: inputs.image,
          keywords: inputs.keywords,
        }
      );
      if (data.success) {
        alert("Blog Posted Successfully");
        console.log(data);
        Navigate("/media");
      }
    } catch (error) {
      alert(error);
      console.log(error);
    }
  };
  return (
    <>
      <h1 className="createPost">Create A New Post</h1>
      <form className="postBlog" onSubmit={handleSubmit}>
        <label>Title </label>
        <input
          type="text"
          name="title"
          onChange={handleChange}
          value={inputs.title}
          className="blogtitle"
          required
        />
        <label>Description </label>
        <textarea
          className="blogDescription"
          name="description"
          cols="50"
          rows="10"
          onChange={handleChange}
          value={inputs.description}
          required
        ></textarea>
        <label>Image URL </label>
        <input
          type="text"
          className="imageUrl"
          name="image"
          onChange={handleChange}
          value={inputs.image}
          required
        />
        <label>Keywords </label>
        <input
          type="text"
          className="imageUrl"
          name="keywords"
          onChange={handleChange}
          value={inputs.keywords}
          required
        />

        <input
          type="button"
          onClick={handleSubmit}
          name="submit"
          className="submitButton"
          value="Submit"
        />
      </form>
    </>
  );
};

export default CreateBlog;
