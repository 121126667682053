import React/* , { useEffect, useRef, useState }  */ from 'react'
import './Testimonial.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import /* SwiperCore,  */ { Pagination, Navigation, } from 'swiper/modules';
import vid1 from './Assets/Award-4.webm'
import vid1ios from './Assets/Award-4.mp4'
import vid2 from './Assets/Award-5.webm'
import vid2ios from './Assets/Award-5.mp4'
import vid3ios from './Assets/Award-33.webm'
import vid3 from './Assets/Award-33.mp4'
import vid4 from './Assets/EEPC-15.webm'
import vid4ios from './Assets/EEPC-15.mp4'
import vid5 from './Assets/MSME-1.webm'
import vid5ios from './Assets/MSME-1.mp4'
/* import vid6 from './Assets/TERRY HABITAT.webm' */
import vid7 from './Assets/EEPC.mp4'
import vid7ios from './Assets/EEPC.webm'
import vid8 from './Assets/corp.webm'
import vid8ios from './Assets/corp.mp4'
/* import vid9 from './Assets/9.webm'
import vid10 from './Assets/10.webm'
import vid11 from './Assets/11.webm'
import vid12 from './Assets/4.webm'
import vid13 from './Assets/04.webm'
import vid14 from './Assets/02.webm'
import vid15 from './Assets/Sequence 05.webm'
import pvid from './Assets/Final view 3.mp4'; */



const Award = () => {



    return (

        <>
            <div className="videoContainer">
                <div className="videoHeading">
                    <h1>AWARDS </h1><br />
                </div>
                <Swiper

                    grabCursor={true}



                    loop={true}
                    spaceBetween={350}

                    pagination={true}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper"
                    breakpoints={{
                        0: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        480: {
                            slidesPerView: 2,
                            spaceBetween: 10
                        },
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 40
                        },
                        1024: {
                            slidesPerView: 2,
                            spaceBetween: 40
                        },
                        1280: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                        1380: {
                            slidesPerView: 3,
                            spaceBetween: 30
                        },
                    }}
                >
                    <SwiperSlide>
                        <video className="vid" controls>
                            <source src={vid1} type='video/webm' />
                            <source src={vid1ios} type='video/mp4' />
                        </video>
                        {/* <h2 className='AwardName' alt=''>Award</h2> */}
                    </SwiperSlide>
                    <SwiperSlide>
                        <video className="vid" controls>
                            <source src={vid4} type='video/webm' />
                            <source src={vid4ios} type='video/mp4' />

                        </video>
                        {/*  <h2 className='AwardName' alt=''></h2> */}
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <video className="vid" src={vid6} controls></video>
                        <h2 className='AwardName' alt=''></h2>
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <video className="vid" controls>
                            <source src={vid2} type='video/webm' />
                            <source src={vid2ios} type='video/mp4' />
                        </video>
                        {/* <h2 className='AwardName'></h2> */}
                    </SwiperSlide>
                    <SwiperSlide>
                        <video className="vid" controls>
                            <source src={vid5} type='video/webm' />
                            <source src={vid5ios} type='video/mp4' />
                        </video>
                        {/* <h2 className='AwardName'></h2> */}
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <video className="vid" src={vid6} controls></video>
                        <h2 className='AwardName'></h2>
                    </SwiperSlide> */}
                    <SwiperSlide>
                        <video className="vid" controls>
                            <source src={vid7} type='video/webm' />
                            <source src={vid7ios} type='video/mp4' />

                        </video>
                        {/* <h2 className='AwardName'></h2> */}
                    </SwiperSlide>
                    <SwiperSlide>
                        <video className="vid" controls>
                            <source src={vid8} type='video/webm' />
                            <source src={vid8ios} type='video/mp4' />

                        </video>
                        {/* <h2 className='AwardName'></h2> */}
                    </SwiperSlide>
                    <SwiperSlide>
                        <video className="vid" controls>
                            <source src={vid3} type='video/webm' />
                            <source src={vid3ios} type='video/mp4' />
                        </video>
                        {/* <h2 className='AwardName'></h2> */}
                    </SwiperSlide>
                    {/* <SwiperSlide>
                        <video className="vid" src={vid3} controls></video>
                        <h2 className='AwardName'></h2>
                    </SwiperSlide> */}



                </Swiper>


            </div>



        </>
    )
    /*   let vid = document.querySelectorAll(".vid");
      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          console.log(entry.isIntersecting);
       
    
    
      });
      observer.observe(vid) */

}

export default Award