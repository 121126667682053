import { Outlet } from 'react-router-dom'
import BootNav from './components/BootNav'




const Layout = () => {
  return (
    <>

      <main>
        <Outlet />
      </main>
    </>
  )
}

export default Layout