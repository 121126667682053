import React from "react";
import "./SolventExtraction.css";
import { Parallax } from "react-parallax";
import { Helmet } from "react-helmet";
import oilm from "../components/Assets/PREPARATORY & SOLVENT EXTRACTION FULL HD_2.mp4";

const OilMill = () => {
  return (
    <>
      <Helmet>
        <title>Oil Mill</title>
        <meta
          name="description"
          content="MECPRO is one of the foremost manufacturers
            & exporters of an extensive range of Oil Mill
            Machinery / Plant for the crushing and extra-
            ction of edible oil. "
        />
        <meta
          name="keywords"
          content=" Oil Mill, Oil Mill
            Machinery Plant, Oil extraction plant of edible oil"
        />
      </Helmet>

      <div>
        <h1 className="Refinery">OIL MILL</h1>
      </div>
      <div className="refinaryContainer">
        <Parallax blur={0} bgImage="./Images/1 1801777402.png" strength={600}>
          <div className="HimgContainer"></div>

          {/* <h1 className="pageHeading">Oil Mill</h1> */}
        </Parallax>
        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/untitled1-34@2x.png"
            alt="Oil Mill-1"
          />
          <article>
            MECPRO is one of the foremost manufacturers & exporters of an
            extensive range of Oil Mill Machinery / Plant for the crushing and
            extra- ction of edible oil. The entire range of Oil Mill Machinery/
            Plant is manufactured as per International Quality Stan- dards.
            Premium quality raw materials and high end technology ensures that
            the machinery gives optimum performance.
          </article>
        </section>

        <h1 className="pageHeading2">What is an oil mill </h1>

        <h5 className="mecpro1">
          Oil is usually extracted from plants and vegetables. In an oil mill,
          the plant/ vegetable seeds are grinded using machines. The oil is then
          extracted through a mechanical pressing or solvent extraction process.
          Many types of oils are used in India such as mustard oil, olive oil,
          sunflower oil, sesame oil, and so on. The oil machinery (cooking
          machine, oil filters, oil press, etc) varies depe- nding on the type
          of oil you want to start.
        </h5>

        <section className="ref2">
          <h5>
            Mecpro offers :- <br />
            • Reduced steam consumption <br />
            • Reduced steam consumption low temperature miscella, Vent air
            purific-ation system in process <br />
            • Better oil colour <br />
            • Higher bleachability <br />
            • Less increase in FFA as compared to any other method <br />
            • Less non-hydratable phosphatides development than any other method{" "}
            <br />
            • Less peroxide value than any other method <br />
            • Less anisidine value than any other method <br />
            • Lower retention time for evaporated material <br />
            • No oxidisation or degradation of oil <br />
          </h5>
        </section>

        <h5 className="mecpro1">
          Mecpro has invested for years in research and development for
          improving the technology used in Solvent Extraction Plants. The total
          work done in this area is very vast and covers all areas from raw
          material Preparation to bagging of deoiled meal. All equipment are of
          superior construction to reduce utility consumption and downtime. The
          large number of innovations make Mecpro’s Solvent Extraction Plant
          your best choice. These reduce your operating cost, increase the
          efficiency of your plant and are environment friendly features.
        </h5>
        <section className="refVid">
          <img
            className="solmidimg"
            src="./Images/2-181006@2x.png"
            alt="Oil Mill-2"
          />
        </section>

        <section className="refVid">
          <video src={oilm} autoPlay loop muted></video>
        </section>

        <section className="Ref3">
          <h2 className="pageHeading2">Special Features: </h2>
          <h5 className="mecpro1">
            1 Two / Three chamber design - pre pressing & full pressing in
            single stage crushing for better oil recovery & profitable
            operation. 2 Rigid main frame to withstand heavy operating load for
            crushing job. 3 It can crush all kinds of oilseeds & special design
            system for Cottonseed & Sunflower seed crushing. 4 The edible oil
            mill machinery consist of various equipment like Seed Preparatory
            Equipment, Seed Cooking Equipment, Oil Press also called as Oil
            Expellers, Filter Pumps, Plate Filter Press and other equipment. 5
            Various capacities are available for Oil Milling Machinery / Plant,
            which are suitable for small scale industries as well as large ones.
          </h5>

          <section className="refVid">
            <img
              className="fracbigImg"
              src="../Images/2-181007@2x.png"
              alt="Oil Mill-3"
            />
          </section>

          <h2 className="pageHeading2">DEPENDABLE QUALITY</h2>

          <h5 className="mecpro1">
            Our process technologies are most widely used due to their
            simplicity, versatility & cost effectiveness.
          </h5>
        </section>
      </div>
    </>
  );
};

export default OilMill;
