import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import Clients from "../components/Clients";
import { Helmet } from "react-helmet";

const Contact = () => {
  const [fname, setName] = useState("");
  const [lname, setlName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [category, setCategory] = useState("");
  const [title, setTitle] = useState("");
  const [subject, setSubject] = useState("");
  const [country, setCountry] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_4i5gc0n",
        "template_2no843j",
        form.current,
        "LrR5EdZH6tfQxN7cq"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Email sent successfully");
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <>
      {/*   <Helmet>
        <title>Contact Us </title>
        <meta name="description" content=" Mecpro Heavy Engineering Ltd in India and Malaysia, a trusted and
            dependable ISO certified technology provider having over 36 years
            of experience in oils & fats, oleo-chemicals, surfactants, distilleries,
            biodiesel in India and across the globe. We also operate in other
            sector that include food processing, agriculture, cement, steel and
            Petro-chemical projects on a BOT basis with holding 17 Patents. Mecpro has successfully commissioned over 280 projects worldwide
            on a turnkey basis, with enhanced productivity and efficiency. Mecpro
            has the capability and expertise to meet your challenges and expecta-
            tions with regard to environment friendly and energy efficient projects. Mecpro has achieved several accolades including 8 national awards,
            2 corporates awards and number of excellence awards for the development of technology and environment excellence. Mecpro also written
            and published 3 books for the department of scientific and industrial
            research for technology evaluation for adoption and absorption scheme
            under their paster scheme. Mecpro also participated in the research of
            “Added value from high protein & high oil industrial co-streams” under
            European Union A propose." />
        <meta name="keywords" content="Bio Diesel, hydrogenation, fractionation, Layer Esterification, Refinery, mecpro heavy engineering limited, palm oil mill, oil mill, palm kernel oil, oil extraxtion process, solvent extraction, Mecpro, Mecpro Heavy Engineering Limited " />
      </Helmet> */}

      <h2>Contact Us</h2>

      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3504.0623068706514!2d77.18480961102414!3d28.56789167559873!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1d841aaaaaab%3A0x7c46b4e603518e9!2sMecpro%20Heavy%20Engineering%20Ltd!5e0!3m2!1sen!2sin!4v1702742148999!5m2!1sen!2sin"
        width="80%"
        height="450"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
        title="map"
      ></iframe>
      <h2>For Enquiry</h2>
      <div className="forenq">
        <h5>
          Please contact us if you would like to place an order, have a
          question, or need assistance. Our Customer Service is available from
          Monday to Saturday, 10.00a.m. – 6.0p.m. (Indian Standard Time).
        </h5>
      </div>
      <div className="contact-wrapper">
        <div className="contactimg">
          <img src="./Images/collagecont.png" alt="contact" />
        </div>
        <form
          className="contact-form"
          method="POST"
          onSubmit={sendEmail}
          ref={form}
        >
          <div className="name">
            <select
              className="contact-gender"
              id="title"
              name="title"
              onChange={(e) => setTitle(e.target.value)}
            >
              <option value="Mr.">Mr.</option>
              <option value="Ms">Ms.</option>
            </select>

            <input
              className="contact-input"
              type="text"
              id="fname"
              name="firstname"
              placeholder="Your name.."
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <input
            className="company"
            type="text"
            id="company"
            name="company"
            placeholder="company"
            onChange={(e) => setCompany(e.target.value)}
          />

          <div className="contactDetails">
            <input
              className="contact-input-phone"
              type="text"
              id="phone"
              name="phone"
              placeholder="contact number"
              onChange={(e) => setPhone(e.target.value)}
            />

            <input
              className="contact-input-email"
              type="email"
              id="email"
              name="email"
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="select">
            <select
              className="Countries"
              id="country"
              name="country"
              onChange={(e) => setCountry(e.target.value)}
            >
              <option>select country</option>
              <option value="AF">Afghanistan</option>
              <option value="AX">Aland Islands</option>
              <option value="AL">Albania</option>
              <option value="DZ">Algeria</option>
              <option value="AS">American Samoa</option>
              <option value="AD">Andorra</option>
              <option value="AO">Angola</option>
              <option value="AI">Anguilla</option>
              <option value="AQ">Antarctica</option>
              <option value="AG">Antigua and Barbuda</option>
              <option value="AR">Argentina</option>
              <option value="AM">Armenia</option>
              <option value="AW">Aruba</option>
              <option value="AU">Australia</option>
              <option value="AT">Austria</option>
              <option value="AZ">Azerbaijan</option>
              <option value="BS">Bahamas</option>
              <option value="BH">Bahrain</option>
              <option value="BD">Bangladesh</option>
              <option value="BB">Barbados</option>
              <option value="BY">Belarus</option>
              <option value="BE">Belgium</option>
              <option value="BZ">Belize</option>
              <option value="BJ">Benin</option>
              <option value="BM">Bermuda</option>
              <option value="BT">Bhutan</option>
              <option value="BO">Bolivia</option>
              <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
              <option value="BA">Bosnia and Herzegovina</option>
              <option value="BW">Botswana</option>
              <option value="BV">Bouvet Island</option>
              <option value="BR">Brazil</option>
              <option value="IO">British Indian Ocean Territory</option>
              <option value="BN">Brunei Darussalam</option>
              <option value="BG">Bulgaria</option>
              <option value="BF">Burkina Faso</option>
              <option value="BI">Burundi</option>
              <option value="KH">Cambodia</option>
              <option value="CM">Cameroon</option>
              <option value="CA">Canada</option>
              <option value="CV">Cape Verde</option>
              <option value="KY">Cayman Islands</option>
              <option value="CF">Central African Republic</option>
              <option value="TD">Chad</option>
              <option value="CL">Chile</option>
              <option value="CN">China</option>
              <option value="CX">Christmas Island</option>
              <option value="CC">Cocos (Keeling) Islands</option>
              <option value="CO">Colombia</option>
              <option value="KM">Comoros</option>
              <option value="CG">Congo</option>
              <option value="CD">
                Congo, Democratic Republic of the Congo
              </option>
              <option value="CK">Cook Islands</option>
              <option value="CR">Costa Rica</option>
              <option value="CI">Cote D'Ivoire</option>
              <option value="HR">Croatia</option>
              <option value="CU">Cuba</option>
              <option value="CW">Curacao</option>
              <option value="CY">Cyprus</option>
              <option value="CZ">Czech Republic</option>
              <option value="DK">Denmark</option>
              <option value="DJ">Djibouti</option>
              <option value="DM">Dominica</option>
              <option value="DO">Dominican Republic</option>
              <option value="EC">Ecuador</option>
              <option value="EG">Egypt</option>
              <option value="SV">El Salvador</option>
              <option value="GQ">Equatorial Guinea</option>
              <option value="ER">Eritrea</option>
              <option value="EE">Estonia</option>
              <option value="ET">Ethiopia</option>
              <option value="FK">Falkland Islands (Malvinas)</option>
              <option value="FO">Faroe Islands</option>
              <option value="FJ">Fiji</option>
              <option value="FI">Finland</option>
              <option value="FR">France</option>
              <option value="GF">French Guiana</option>
              <option value="PF">French Polynesia</option>
              <option value="TF">French Southern Territories</option>
              <option value="GA">Gabon</option>
              <option value="GM">Gambia</option>
              <option value="GE">Georgia</option>
              <option value="DE">Germany</option>
              <option value="GH">Ghana</option>
              <option value="GI">Gibraltar</option>
              <option value="GR">Greece</option>
              <option value="GL">Greenland</option>
              <option value="GD">Grenada</option>
              <option value="GP">Guadeloupe</option>
              <option value="GU">Guam</option>
              <option value="GT">Guatemala</option>
              <option value="GG">Guernsey</option>
              <option value="GN">Guinea</option>
              <option value="GW">Guinea-Bissau</option>
              <option value="GY">Guyana</option>
              <option value="HT">Haiti</option>
              <option value="HM">Heard Island and Mcdonald Islands</option>
              <option value="VA">Holy See (Vatican City State)</option>
              <option value="HN">Honduras</option>
              <option value="HK">Hong Kong</option>
              <option value="HU">Hungary</option>
              <option value="IS">Iceland</option>
              <option value="IN">India</option>
              <option value="ID">Indonesia</option>
              <option value="IR">Iran, Islamic Republic of</option>
              <option value="IQ">Iraq</option>
              <option value="IE">Ireland</option>
              <option value="IM">Isle of Man</option>
              <option value="IL">Israel</option>
              <option value="IT">Italy</option>
              <option value="JM">Jamaica</option>
              <option value="JP">Japan</option>
              <option value="JE">Jersey</option>
              <option value="JO">Jordan</option>
              <option value="KZ">Kazakhstan</option>
              <option value="KE">Kenya</option>
              <option value="KI">Kiribati</option>
              <option value="KP">Korea, Democratic People's Republic of</option>
              <option value="KR">Korea, Republic of</option>
              <option value="XK">Kosovo</option>
              <option value="KW">Kuwait</option>
              <option value="KG">Kyrgyzstan</option>
              <option value="LA">Lao People's Democratic Republic</option>
              <option value="LV">Latvia</option>
              <option value="LB">Lebanon</option>
              <option value="LS">Lesotho</option>
              <option value="LR">Liberia</option>
              <option value="LY">Libyan Arab Jamahiriya</option>
              <option value="LI">Liechtenstein</option>
              <option value="LT">Lithuania</option>
              <option value="LU">Luxembourg</option>
              <option value="MO">Macao</option>
              <option value="MK">
                Macedonia, the Former Yugoslav Republic of
              </option>
              <option value="MG">Madagascar</option>
              <option value="MW">Malawi</option>
              <option value="MY">Malaysia</option>
              <option value="MV">Maldives</option>
              <option value="ML">Mali</option>
              <option value="MT">Malta</option>
              <option value="MH">Marshall Islands</option>
              <option value="MQ">Martinique</option>
              <option value="MR">Mauritania</option>
              <option value="MU">Mauritius</option>
              <option value="YT">Mayotte</option>
              <option value="MX">Mexico</option>
              <option value="FM">Micronesia, Federated States of</option>
              <option value="MD">Moldova, Republic of</option>
              <option value="MC">Monaco</option>
              <option value="MN">Mongolia</option>
              <option value="ME">Montenegro</option>
              <option value="MS">Montserrat</option>
              <option value="MA">Morocco</option>
              <option value="MZ">Mozambique</option>
              <option value="MM">Myanmar</option>
              <option value="NA">Namibia</option>
              <option value="NR">Nauru</option>
              <option value="NP">Nepal</option>
              <option value="NL">Netherlands</option>
              <option value="AN">Netherlands Antilles</option>
              <option value="NC">New Caledonia</option>
              <option value="NZ">New Zealand</option>
              <option value="NI">Nicaragua</option>
              <option value="NE">Niger</option>
              <option value="NG">Nigeria</option>
              <option value="NU">Niue</option>
              <option value="NF">Norfolk Island</option>
              <option value="MP">Northern Mariana Islands</option>
              <option value="NO">Norway</option>
              <option value="OM">Oman</option>
              <option value="PK">Pakistan</option>
              <option value="PW">Palau</option>
              <option value="PS">Palestinian Territory, Occupied</option>
              <option value="PA">Panama</option>
              <option value="PG">Papua New Guinea</option>
              <option value="PY">Paraguay</option>
              <option value="PE">Peru</option>
              <option value="PH">Philippines</option>
              <option value="PN">Pitcairn</option>
              <option value="PL">Poland</option>
              <option value="PT">Portugal</option>
              <option value="PR">Puerto Rico</option>
              <option value="QA">Qatar</option>
              <option value="RE">Reunion</option>
              <option value="RO">Romania</option>
              <option value="RU">Russian Federation</option>
              <option value="RW">Rwanda</option>
              <option value="BL">Saint Barthelemy</option>
              <option value="SH">Saint Helena</option>
              <option value="KN">Saint Kitts and Nevis</option>
              <option value="LC">Saint Lucia</option>
              <option value="MF">Saint Martin</option>
              <option value="PM">Saint Pierre and Miquelon</option>
              <option value="VC">Saint Vincent and the Grenadines</option>
              <option value="WS">Samoa</option>
              <option value="SM">San Marino</option>
              <option value="ST">Sao Tome and Principe</option>
              <option value="SA">Saudi Arabia</option>
              <option value="SN">Senegal</option>
              <option value="RS">Serbia</option>
              <option value="CS">Serbia and Montenegro</option>
              <option value="SC">Seychelles</option>
              <option value="SL">Sierra Leone</option>
              <option value="SG">Singapore</option>
              <option value="SX">Sint Maarten</option>
              <option value="SK">Slovakia</option>
              <option value="SI">Slovenia</option>
              <option value="SB">Solomon Islands</option>
              <option value="SO">Somalia</option>
              <option value="ZA">South Africa</option>
              <option value="GS">
                South Georgia and the South Sandwich Islands
              </option>
              <option value="SS">South Sudan</option>
              <option value="ES">Spain</option>
              <option value="LK">Sri Lanka</option>
              <option value="SD">Sudan</option>
              <option value="SR">Suriname</option>
              <option value="SJ">Svalbard and Jan Mayen</option>
              <option value="SZ">Swaziland</option>
              <option value="SE">Sweden</option>
              <option value="CH">Switzerland</option>
              <option value="SY">Syrian Arab Republic</option>
              <option value="TW">Taiwan, Province of China</option>
              <option value="TJ">Tajikistan</option>
              <option value="TZ">Tanzania, United Republic of</option>
              <option value="TH">Thailand</option>
              <option value="TL">Timor-Leste</option>
              <option value="TG">Togo</option>
              <option value="TK">Tokelau</option>
              <option value="TO">Tonga</option>
              <option value="TT">Trinidad and Tobago</option>
              <option value="TN">Tunisia</option>
              <option value="TR">Turkey</option>
              <option value="TM">Turkmenistan</option>
              <option value="TC">Turks and Caicos Islands</option>
              <option value="TV">Tuvalu</option>
              <option value="UG">Uganda</option>
              <option value="UA">Ukraine</option>
              <option value="AE">United Arab Emirates</option>
              <option value="GB">United Kingdom</option>
              <option value="US">United States</option>
              <option value="UM">United States Minor Outlying Islands</option>
              <option value="UY">Uruguay</option>
              <option value="UZ">Uzbekistan</option>
              <option value="VU">Vanuatu</option>
              <option value="VE">Venezuela</option>
              <option value="VN">Viet Nam</option>
              <option value="VG">Virgin Islands, British</option>
              <option value="VI">Virgin Islands, U.s.</option>
              <option value="WF">Wallis and Futuna</option>
              <option value="EH">Western Sahara</option>
              <option value="YE">Yemen</option>
              <option value="ZM">Zambia</option>
              <option value="ZW">Zimbabwe</option>
            </select>
            <select
              className="Category"
              id="category"
              name="category"
              onChange={(e) => setCategory(e.target.value)}
            >
              <option value="Select Category">Select Category</option>
              <option value="Solvent Extraction">Solvent Extraction</option>
              <option value="Palm Fibre Oil Extraction">
                Palm Fibre Oil Extraction
              </option>
              <option value="Palm Kernel Oil Extraction">
                Palm Kernel Oil Extraction
              </option>
              <option value="Soyabean Oil Extraction">
                Soyabean Oil Extraction
              </option>
              <option value="Ricebrane Oil Extraction">
                Ricebrane Oil Extraction
              </option>
              <option value="Mustard Oil Extraction">
                Mustard Oil Extraction
              </option>
              <option value="Sunflower Oil Extraction">
                Sunflower Oil Extraction
              </option>
              <option value="Ground Nut Oil Extraction">
                Ground Nut Oil Extraction
              </option>
              <option value="Mango Kernel Oil Extraction">
                Mango Kernel Oil Extraction
              </option>
              <option value="cia Seed Oil Extraction">
                cia Seed Oil Extraction
              </option>
              <option value="Refinery">Refinery</option>
              <option value="Fractionation">Fractionation</option>
              <option value="Layer Esterification">Layer Esterification</option>
              <option value="Cotton Seed Delinting">
                Cotton Seed Delinting
              </option>
              <option value="Oil Mill">Oil Mill</option>
              <option value="Spent Bleeching Earth">
                Spent Bleeching Earth
              </option>
              <option value="Wax Purification">Wax Purification</option>
              <option value="Wax Extraction">Wax Extraction</option>
              <option value="Fat Splitting">Fat Splitting</option>
              <option value="Hydrogenation">Hydrogenation</option>
              <option value="Flour Mill">Flour Mill</option>
              <option value="Bakery Shortening & Maragarine">
                Bakery Shortening & Maragarine
              </option>
              <option value="Solar Energy">Solar Energy</option>
              <option value="Bio Diesel">Bio Diesel</option>
              <option value="Detergent Powder">Detergent Powder</option>
              <option value="Toilet Soap">Toilet Soap</option>
              <option value="Potato French Fries">Potato French Fries</option>
              <option value="Tomato">Tomato</option>
              <option value="Pharmaceutical">Pharmaceutical</option>
            </select>
          </div>

          <textarea
            id="subject"
            rows={6}
            name="subject"
            placeholder="Write your query.."
            onChange={(e) => setSubject(e.target.value)}
          ></textarea>

          <input className="contact-submit" type="submit" value="Submit" />
        </form>
        <div className="address">
          <div className="contactLinksRight">
            <h2>Head Office</h2>
            <p>
              Mecpro Heavy Engineering Ltd.
              <br />
              (CIN: U74210DL1986PLC024429)
              <br />
              610, Som Dutt Chambers-II,
              <br /> Bhikaji Cama Place
              <br />
              New Delhi-110 066, India
              <br />
              Tel. : + 91-11-26100912
              <br />
              Fax : + 91-11-26185182
              <br />
              MS Team : projects@mecpro.com
              <br />
              E-mail : projects@mecpro.com, rajan.skhariya@mecpro.com
            </p>
          </div>
          <div className="contactOverseasAddress">
            <h2> Overseas Office</h2>
            <p>
              Mecpro Heavy Engineering SDN. BHD. (926103-V) Suite 19A-12-05,
              Level 12, UOA Centre Business Suite Wisma, UOA-II, Jalan Pinang
              50450,
              <br /> Kuala Lumpur Wilayah Persekutuan,
              <br /> Malaysia <br />
              Tele: + 60165302714 <br />
              Mobile: + 91 9910022256
              <br />
              E-mail : projects@mecpro.com,
            </p>
          </div>
        </div>
      </div>
      <Clients />
    </>
  );
};

export default Contact;
