import React, { useState, useEffect } from 'react';


const languages = {
  en: 'English',
  es: 'Español',
  fr: 'Français',
  de: 'Deutsch',
  it: 'Italiano',
  ja: '日本語',
  ko: '한국어',
  nl: 'Nederlands',
  pt: 'Português',
  ru: 'Русский',
  'zh-CN': '中文',
  ms: 'Bahasa Melayu',
  th: 'ไทย',
  id: 'Bahasa Indonesia'
};

const countryToLanguage = {
  US: 'en',
  ES: 'es',
  FR: 'fr',
  DE: 'de',
  IT: 'it',
  JP: 'ja',
  KR: 'ko',
  NL: 'nl',
  PT: 'pt',
  RU: 'ru',
  CN: 'zh-CN',
  MY: 'ms',
  TH: 'th',
  ID: 'id',
  CO: 'es'
};

const AutoTranslate = () => {
  const [showModal, setShowModal] = useState(true);
  const [isTranslating, setIsTranslating] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [showControls, setShowControls] = useState(false);

  useEffect(() => {
    const detectInitialLanguage = async () => {
      try {
        const response = await fetch('https://ipapi.co/json/');
        const data = await response.json();
        const defaultLanguage = countryToLanguage[data.country_code] || 
                              navigator.language.split('-')[0] || 
                              'en';
        setSelectedLanguage(defaultLanguage);
      } catch (error) {
        console.error('Error detecting language:', error);
        setSelectedLanguage('en');
      }
    };

    detectInitialLanguage();
  }, []);
  
  const initializeTranslation = async (chosenLanguage) => {
    setIsTranslating(true);
    setShowModal(false);

    const script = document.createElement('script');
    script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit';
    script.async = true;
    document.body.appendChild(script);

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        pageLanguage: 'en',
        includedLanguages: Object.keys(languages).join(','),
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: true,
      }, 'google_translate_element');

      const selectLanguage = () => {
        const dropdown = document.querySelector('.goog-te-combo');
        if (dropdown) {
          dropdown.value = chosenLanguage;
          dropdown.dispatchEvent(new Event('change'));
          setIsTranslating(false);
        }
      };

      setTimeout(selectLanguage, 1000);
    };
  };

  if (showModal) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
        <div className="bg-white rounded-xl max-w-md w-full p-6 space-y-6">
          <div className="text-center">
            <h2 className="text-2xl font-bold text-gray-900 mb-2">Select Your Language</h2>
            <p className="text-gray-600">Choose your preferred language to continue</p>
          </div>

          <div className="grid grid-cols-2 gap-3">
            {Object.entries(languages).map(([code, name]) => (
              <button
                key={code}
                onClick={() => initializeTranslation(code)}
                className={`p-3 rounded-lg text-left transition-colors ${
                  selectedLanguage === code
                    ? 'bg-blue-50 border-2 border-blue-500 text-blue-700'
                    : 'border-2 border-gray-200 hover:border-blue-300 text-blue-700 hover:bg-blue-500'
                }`}
              >
                <span className="text-sm font-medium">{name}</span>
              </button>
            ))}
          </div>

          <button
            onClick={() => initializeTranslation(selectedLanguage)}
            className="w-full py-3 px-4 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 transition-colors"
          >
            Continue with {languages[selectedLanguage]}
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed bottom-4 right-4 z-50">
      {isTranslating && (
        <p className="mb-2 bg-blue-100 p-2 rounded text-sm">
          Translating content...
        </p>
      )}

      <div className="bg-white rounded-lg shadow-lg p-4">
        <div id="google_translate_element"></div>

        {showControls && (
          <button
            onClick={() => window.location.reload()}
            className="mt-2 px-4 py-2 bg-gray-200 text-sm rounded hover:bg-gray-300"
          >
            Reset Translation
          </button>
        )}

        <button
          onClick={() => setShowControls(!showControls)}
          className="mt-2 text-xs text-gray-500 hover:text-gray-700 block w-full text-center"
        >
          {showControls ? 'Hide Controls' : 'Show Controls'}
        </button>
      </div>
    </div>
  );
};

export default AutoTranslate;