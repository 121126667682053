import React, { useEffect, useRef, useState } from "react";
import "./Testimonial.css";
import "./Patents.css";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper/modules";
import vid1 from "./Assets/01.webm";
import vid1ios from "./Assets/01.mp4";
import vid2 from "./Assets/2.webm";
import vid2ios from "./Assets/2.mp4";
import vid3 from "./Assets/3.webm";
import vid3ios from "./Assets/3.mp4";
import vid4 from "./Assets/12.webm";
import vid4ios from "./Assets/12.mp4";
import vid5 from "./Assets/5.webm";
import vid5ios from "./Assets/5.mp4";
import vid6 from "./Assets/6.webm";
import vid6ios from "./Assets/6.mp4";
import vid7 from "./Assets/7.webm";
import vid7ios from "./Assets/7.mp4";
import vid8 from "./Assets/8.webm";
import vid8ios from "./Assets/8.mp4";
import vid9 from "./Assets/9.webm";
import vid9ios from "./Assets/9.mp4";
import vid10 from "./Assets/10.webm";
import vid10ios from "./Assets/10.mp4";
import vid11 from "./Assets/11.webm";
import vid11ios from "./Assets/11.mp4";
import vid12 from "./Assets/4.webm";
import vid12ios from "./Assets/4.mp4";
import vid13 from "./Assets/04.webm";
import vid13ios from "./Assets/04.mp4";
import vid14 from "./Assets/02.webm";
import vid14ios from "./Assets/02.mp4";
import vid15 from "./Assets/5.webm";
import vid15ios from "./Assets/5.mp4";
import pvid from "./Assets/Final view 3.mp4";

const Testimonial = () => {
  const vidContainerRef = useRef();
  const vidRef = useRef();
  const [myVideoIsPlaying, setMyVideoIsPlaying] = useState();

  useEffect(() => {
    const observer = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio !== 0.5) {
          const vidss = Array.prototype.slice.apply(
            document.querySelectorAll(".vid")
          );
          vidss.forEach(function (media) {
            vidss.forEach(function (media, event) {
              if (event.target !== media) media.pause();
            });
          });
        }
      });
    });

    observer.observe(vidContainerRef.current);

    //pause function
    const handlePause = (event) => {
      medias.forEach(function (media) {
        if (event.target !== media) media.pause();
        console.log(event.target);
      });
    };

    var medias = Array.prototype.slice.apply(document.querySelectorAll(".vid"));
    medias.forEach(function (media) {
      media.addEventListener("play", handlePause);
    });
  }, []);

  return (
    <>
      <div ref={vidContainerRef} className="videoContainer">
        <div className="videoHeading">
          <h1>OUR CLIENTS WHO MADE US PROUD !!</h1>
          <br />
        </div>
        <Swiper
          grabCursor={true}
          loop={true}
          spaceBetween={350}
          pagination={true}
          navigation={true}
          modules={[Pagination, Navigation]}
          className="mySwiper"
          breakpoints={{
            0: {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            480: {
              slidesPerView: 2,
              spaceBetween: 10,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 15,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            /*   1400: {
                slidesPerView: 2,
                spaceBetween: 15
              }, */
            /*  1280: {
               slidesPerView: 3,
               spaceBetween: 30
             }, */
          }}
        >
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid1} type="video/webm" />
              <source src={vid1ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName' alt=''>Award</h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid4} type="video/webm" />
              <source src={vid4ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName' alt=''></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid6} type="video/webm" />
              <source src={vid6ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName' alt=''></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid2} type="video/webm" />
              <source src={vid2ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid5} type="video/webm" />
              <source src={vid5ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid3} type="video/webm" />
              <source src={vid3ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid7} type="video/webm" />
              <source src={vid7ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid8} type="video/webm" />
              <source src={vid8ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid9} type="video/webm" />
              <source src={vid9ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid10} type="video/webm" />
              <source src={vid10ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid11} type="video/webm" />
              <source src={vid11ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid12} type="video/webm" />
              <source src={vid12ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid13} type="video/webm" />
              <source src={vid13ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'></h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid14} type="video/webm" />
              <source src={vid14ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'>Award</h2> */}
          </SwiperSlide>
          <SwiperSlide>
            <video ref={vidRef} className="vid" controls>
              <source src={vid15} type="video/webm" />
              <source src={vid15ios} type="video/mp4" />
            </video>
            {/* <h2 className='AwardName'>Award</h2> */}
          </SwiperSlide>
        </Swiper>
      </div>

      {/* <div className='PatentVid'>
        <h1 >OUR PATENTS</h1><br />
        <div className="pvidContainer">
          <video autoPlay loop muted src={pvid} ></video>
        </div>
      </div> */}
    </>
  );
  /*   let vid = document.querySelectorAll(".vid");
    let observer = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        console.log(entry.isIntersecting);
     
  
  
    });
    observer.observe(vid) */
};

export default Testimonial;
