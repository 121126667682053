import "./Refinery.css";
import { Parallax } from "react-parallax";
import RefineryFaq from "./RefineryFaq";
import { Helmet } from "react-helmet";
import refi from "../components/Assets/Oil Refinery.mp4";

const Refinery = () => {
  return (
    <>
      <Helmet>
        <title>Edible oil Refinery Plant</title>
        <meta
          name="description"
          content=">Edible oil refining is to make the oil suitable for
            human consumption. The components to be re-
            moved are all those glyceridic and nonglyceridic
            compounds that are detrimental to the flavour,
            colour, stability."
        />
        <meta
          name="keywords"
          content=" Refinery, refinery, edible oil, oil extraction, edible oil refinery, refinery plant, edible oil refining,  "
        />
      </Helmet>

      <div>
        <h1 className="Refinery">Edible oil refinery</h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="RefinieryHeaderImage"
            src="./Images/refinery1.webp"
            alt="Refinary-Header-Img"
          />
        </div>

        {/* <Parallax blur={0} bgImage=".\Images\refinery1.webp" strength={100}>
          <div className="HimgContainer">

          </div> */}

        {/* <h1 className="pageHeading">Refinery</h1> */}

        {/*  </Parallax> */}

        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/untitled1-38@2x.webp"
            alt="Refinery-img1"
          />
          <article>
            Edible oil refining is to make the oil suitable for human
            consumption. The components to be re- moved are all those glyceridic
            and nonglyceridic compounds that are detrimental to the flavour,
            colour, stability This also involves making the oil flavorless,
            odorless, change in color, rearrangement of their molecular
            structure and to make the extracted or mechanically pressed oil
            suitable as per our requirements.
          </article>
        </section>

        <h1 className="pageHeading2">
          MECPRO ADVANCED TECHNOLOGIES IN OIL REFINERY
        </h1>
        <div className="mecpro1">
          Mecpro has conducted research for years to come up with new
          technologies that improve the efficiency and reduce the operating cost
          of your refinery. Besides, these methods also are environment friendly
          and require lower operator intervention. Of the several inventions of
          Mecpro, the most important ones are described here.
        </div>
        <br />
        <br />

        <section className="ref2">
          <article>
            Mecpro offers :- <br />
            • All the maintenance of the heating and economization coils are
            easy <br />
            • 75% of the heat energy is recovered for reuse <br />
            • Lower steam consumption <br />
            • Seal less magnetic pumps ensure no air intake <br />
            • Scrubber system eliminates fatty acid escape to the water <br />
            • Less neutral oil loss <br />
            • Lower temperature process <br />
            • Less trans fatty acid development <br />
            • Lower retention time for evaporated material <br />
            • Steady and gradual rise of temperature <br />
            • Less stripping steam <br />
            • Single booster in running <br />
            • Shell and tube preheating system under vacuum <br />
            • Efficient sparging method through structural packing <br />
            • Easier maintenance <br />
            • Can handle higher FFA oil <br />
          </article>
          <div className="reffImg2">
            <img src="./Images/2-181010@2x.png" alt="RefImg2" />
            <img src="./Images/P1010984.webp" alt="refImg3" />
          </div>
        </section>
        <h1 className="pageHeading2">MECPROS OIL REFINERY PLANT </h1>

        <section className="refVid">
          <video src={refi} autoPlay loop controls muted></video>
        </section>

        <section className="Ref3">
          <ol type="A">
            <li>
              Enzymatic Degumming (Licensed from IICT) The conventional system
              of bleaching used in the country cannot reduce the phosphorus
              level below 20 ppm and the wash water step is compulsory in the
              process. There is thus a higher loss of oil in conventional
              degumming. In enzymatic degumming the phosphorus level can be
              brought down to below 5 ppm and the oil loss is less.The gums that
              are coming out from the plant are converted into high value
              lecithin. The process is much simpler com- pared to any other
              method used for degumming. It is specially recommended for
              refining crude rice bran oil. Mecpro is a licensee of this process
              that has been developed by the Indian Institute of Chemical
              Technology (IICT), Hyderabad. Mecpro has been the first company to
              commercialize the technology. <br />
              <br />
              <span className="MecOffer">M</span>ecpro{" "}
              <span className="MecOffer">O</span>ffers
              <ul>
                <li>Lower oil loss</li> <li>Phosphorus level below 5 ppm</li>
                <li>High quality lecithin</li> <li>Lower water loss </li>{" "}
                <li>No effluent</li>
              </ul>
            </li>

            <li>
              Acid Degumming The acid degumming process can be considered as an
              alternative to the water degumming process in that it uses a
              degumming acid combined with water. Crude oil, either water
              degummed or not, is treated by an acid, usually phosphoric acid,
              citric acid or malic acid in the presence of water. For oils
              containing NHP (e.g. sunflowersoyabeen, rape seed, rice bran oil
              oil) this process can lead to degummed oil with a lower residual
              phosphorus content (5 to 30 ppm) than water degumming. The acid
              degummed oil is then dry degummed and physically refined
            </li>
            <li>
              Twin Bleaching Technology (Patented) This is a new innovation
              which reduces the bleaching earth consumption by 30% using spent
              earth to remove the inactivating material in a closed circuit.
              There are two types of bleaching batch bleaching and cocurrent
              bleaching. Such bleaching processes have high bleaching earth
              consumption due to inactivated bleaching earth with volatile
              material entering into the system.In the newly developed twin
              bleaching system (counter current bleaching) the volatile material
              will be inactivated by spent bleaching earth in a closed loop
              circuit. So the bleaching earth consumption reduces by at least
              20% in the process. It finds better Dewaxing efficiency.
              <br />
              <br />
              <span className="MecOffer">M</span>ecpro{" "}
              <span className="MecOffer">O</span>ffers
              <ul>
                <li>Minimum 20% reduction in bleaching </li>
                <li>earth consumption</li>
                <li>Silica treatment is possible</li>
                <li>Less filter cloth consumption</li> <li>Less oil loss </li>{" "}
                <li>Less spent earth disposal</li>
              </ul>
            </li>

            <li>
              Gravitational Dewaxing and Winterization The newly developed
              process design for gravitational Dewaxing and winterization
              ensures less filter area and lower cloud point. The system will be
              able to achieve below 5o C cloud point. Further efforts are on to
              bring this down to 0o C.
              <br />
              <br />
              <ul>
                <span className="MecOffer">M</span>ecpro{" "}
                <span className="MecOffer">O</span>ffers
                <li> Cloud point below 5o C </li>
                <li>Non lubricating bushes used </li>
                <li>Less power consumed </li>
                <li>Less filtration area required </li>
              </ul>
            </li>
            <li>
              Deacidification Cum Deodorisation Technology The process is based
              on the basic principles of distillation as given by Dalton’s Law:
              The total pressure of a mixture of gases or vapours is the sum of
              the partial pressure of each of the gases or vapours in the
              mixture. Raoult’ Law: In a solution, the vapour pressure of each
              component is proportional to its molar concentration The newly
              developed deodorizer with bubble cap, structural packing and thin
              film trays along with the oil in circulation through magnetic seal
              less pumps ensures the good quality of the oil produced.
            </li>
          </ol>
        </section>
      </div>
      <h1 className="faqHeading">Frequently Asked Questions!</h1>
      <div className="faqContainer">
        <RefineryFaq />
        {/*  <div className="faqImages">
          <img src="./Images/20180323_125924.jpg" alt="" />
          <img src="./Images/20180828_090759.jpg" alt="" />
          <img src="./Images/20180828_090109.jpg" alt="" />
          <img src="./Images/20180828_085337.jpg" alt="" />
        </div> */}
      </div>
    </>
  );
};

export default Refinery;
