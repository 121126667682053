import Accordion from 'react-bootstrap/Accordion';


function LayerEsterificationfaq() {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>What is meant by Layer Esterification process?
                </Accordion.Header>
                <Accordion.Body>
                    Converting Free Fatty Acids into glyceride without using Catalyst or Chemical in a layer form
                    is called Layer Esterification process. MECPRO believes that to maintain a competitive
                    technology edge, it is necessary to continue to improve and modify existing processes, as well
                    as develop new applications.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>What is the process technology?
                </Accordion.Header>
                <Accordion.Body>
                    Critical understanding of the chemistry of the process by MECPRO provided the potential to
                    explore the technology. Triglycerides are esters of glycerol and three fatty acids. Fatty acids are
                    in turn aliphatic compounds containing 4 to 24 Carbon atoms and having a terminal Carboxyl
                    group. Di-glycerides are esters of glycerol and two fatty acids and Mono glycerides are esters
                    of glycerol and one fatty acid. The characteristics of the triglycerides are influenced by the
                    nature of fatty acid. Years of consistent development has generated this commercially viable
                    process technology
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>Does it require any Catalyst or Chemical?
                </Accordion.Header>
                <Accordion.Body>
                    The Layer Esterification process offered by Mecpro is based on Glycerolizes process on layer
                    form and does not require any Catalyst or Chemical. It only requires Glycerin to re-bond the
                    fatty acid molecules to convert into triglycerides in a condensation process.                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>What kind of oil can be Layer Esterified?</Accordion.Header>
                <Accordion.Body>
                    Any oil can be Layer Esterified, which could include Crude Palm Oil (CPO), Used Cooking
                    Oil (UCO), Sludge Palm Oil (SPO), Palm Fatty Acid Distillates (PFAD), Rice Bran Oil (RBO),
                    Rendered Animal Fat and Trap greases etc.              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>Is there a limit on the percentage of Free Fatty Acid in the feed stock?</Accordion.Header>
                <Accordion.Body>
                    There is no limit on the percentage of Free Fatty Acid and our technology can process from
                    2% FFA to 90% FFA oils, with great ease and bring down the FFA Level to less than 0.1 %.                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>Can it be integrated into the existing refinery?
                </Accordion.Header>
                <Accordion.Body>
                    With minimal disturbance to an existing plant, the Layer Esterification reaction column can be
                    retrofitted into the exiting refinery. As the equipment does not require much space, it can be
                    safely incorporated. Installation will be done in a such manner that you can run the plant in
                    Layer Esterification mode or refining mode depending upon the cost of FFA in the market.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>Is there any loss during process?</Accordion.Header>
                <Accordion.Body>
                    There is no oil loss in the process; however, it increases the weight by 42% of the glycerin
                    quantity added to perform the layer esterification.
                    Contact: projects@mecpro.com

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>How do we proceed further?</Accordion.Header>
                <Accordion.Body>
                    MECPRO can arrange a detailed technical presentation to your core technical team, to further clarify any doubts. Moreover, MECPRO can arrange a plant visit to show you the latest plant, based on our state-of-the-art cutting edge innovative technologies <br />

                    Contact: projects@mecpro.com


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
                <Accordion.Header>Does it affect the quality of the Finished Oil?
                </Accordion.Header>
                <Accordion.Body>
                    The unique method of Layer Esterification process technology developed by MECPRO does
                    not have any adverse effect on the quality of the finished product up to 10% FFA. As a matter
                    of fact, its specifications are comparable to the other products.

                </Accordion.Body>

            </Accordion.Item>
            <Accordion.Item eventKey="9">
                <Accordion.Header>What is the composition of Mono, Di and Triglycerides after Layer
                </Accordion.Header>
                <Accordion.Body>
                    After Layer Esterification, the composition of oil would be as Esterification?? follows:
                    Mono Negligible
                    Di 40% of the FFA converted
                    Tri 60%
                    or example, if we process CPO with 5% FFA, the FFA remaining in 1 MT CPO is 50 Kg. In this
                    case we have to add 6.5Kg glycerin and after the process the FFA will be less than 0.1 weight the
                    weight will increase by 2.6 Kg.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="10">
                <Accordion.Header>How much glycerin is consumed and of what specifications?
                </Accordion.Header>
                <Accordion.Body>
                    Glycerin to the extent of about 13% of the FFA as palmitic and 12% of the FFA as oleic acid in the
                    oil is consumed; however, in order to have low utility consumption is advisable to use USP grade
                    glycerin having 98% to 99% purity. In order to make a product fit for human consumption, the
                    glycerin should be of above 99.5% purity


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="11">
                <Accordion.Header>Is Layer Esterified oil safe for human consumption?
                </Accordion.Header>
                <Accordion.Body>
                    It is indeed a very safe process and oils up to 8 to 10% FFA can safely be used for human
                    consumption after going through the Layer Esterification process. Since we are not using any
                    catalyst, only modification of fat has been done. However, it totally depends upon the rules and
                    regulations of the country as regards food safety.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="12">
                <Accordion.Header>What is the minimum economic Plant Capacity?
                </Accordion.Header>
                <Accordion.Body>
                    Ideally, 50 TPD is a minimum economic capacity for a Layer Esterification plant, which can go up
                    to any extent -- 500 TPD or 1000 TPD and even more.



                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="13">
                <Accordion.Header>Does it affect the quality of the Finished Oil?
                </Accordion.Header>
                <Accordion.Body>
                    The unique method of Layer Esterification process technology developed by MECPRO does
                    not have any adverse effect on the quality of the finished product up to 10% FFA. As a matter
                    of fact, its specifications are comparable to the other products.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="14">
                <Accordion.Header>What is the project implementation schedule?
                </Accordion.Header>
                <Accordion.Body>
                    The retrofitting of the Layer Esterification plant in an existing Refinery can be done in four to
                    six months. However, a new green field plant would take almost 12 to 18 months to
                    commission, depending upon the place and soil conditions. The process can be easily integrated
                    with existing refining facilities.


                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="15">
                <Accordion.Header>What is the payback period for the Project?
                </Accordion.Header>
                <Accordion.Body>
                    The payback period for the project is between 1 to 3 years and hence it makes the project
                    commercially viable. Today this process has an established reputation as a low cost, efficient,
                    safe and reliable method that provides a high-quality product which is well accepted by the
                    industry. However, everything depends on the prevailing cost structure. If there is a difference
                    of $ 100 between the FFA and CPO price, the project will be commercially viable.




                </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="16">
                <Accordion.Header>Can we bypass the Layer Esterification Process?
                </Accordion.Header>
                <Accordion.Body>
                    The payback period for the project is between 1 to 3 years and hence it makes the project
                    commercially viable. Today this process has an established reputation as a low cost, efficient,
                    safe and reliable method that provides a high-quality product which is well accepted by the
                    industry. However, everything depends on the prevailing cost structure. If there is a difference
                    of $ 100 between the FFA and CPO price, the project will be commercially viable.




                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="17">
                <Accordion.Header>What is the Utility Consumption?</Accordion.Header>
                <Accordion.Body>
                    There is no other utility consumption except Glycerin, 0.7 KW power and thermal heat 0.7 KW for
                    1% FFA, which would be the only utility in addition to that already used in the refining process...



                </Accordion.Body>
            </Accordion.Item>

        </Accordion>
    )
}

export default LayerEsterificationfaq;