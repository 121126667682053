import React, { useEffect, useRef } from "react";
import { gsap, Expo } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Patents.css";
import patent0 from "./images/patent/1. INDIA 217089 AN IMPROVED WASTE WATER RECYCLING APPARATUS ADAPTABLE TO A SOLVENT EXTRACTION PLANT FOR.............. (1).png";
import patent1 from "./images/patent/2. INDIA 230865 APROCESS AND AN APARATUS FOR SEPERATION OF EDIBLE OIL AND EDIBLE SOLVENT FROM MISCELLA-1.png";
import patent3 from "./images/patent/006.png";
import patent4 from "./images/patent/CERTIFACATE OF GRANT 1709 ETP 24.3.2020-1.png";
import patent5 from "./images/patent/Patent Certificate.jpg";
import patent6 from "./images/patent/9.png";
import patent7 from "./images/patent/CERTIFICATE OF GRANT-MY 169306-A, FREE FATTY ACID (LAYER ESTERI.jpg";
import patent8 from "./images/patent/3. INDIA 230881 A SOLVENT RECOVERY APARATUS.webp";
import patent9 from "./images/patent/17. THAILAND -1501006431 FILING APPL - OIL RECOVERY FROM EFFLUENT WATER-PATENT.webp";
import patent10 from "./images/patent/14. MALAYISA MY 181158A- OIL RECOVERY FROM EFFLUENT WATER USING SOLVENT.pdf";
import pvid from "./Assets/Final view 3.mp4";

const Patents = () => {
  /*  gsap.registerPlugin(ScrollTrigger);
  let box1 = useRef();
  let box2 = useRef();
  let box3 = useRef();

  useEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: ".patentContainer",
          start: "20% 70%",
          end: "100% bottom",

          scrub: 1,
        },
      });

      tl.from(box1.current, {
        yPercent: 20,
        duration: 2,
        opacity: 0,
        ease: Expo.easeIn,
      });
      tl.from(box2.current, {
        yPercent: 100,
        duration: 2,
        opacity: 0,
        ease: Expo.easeIn,
      });
    });

    return () => ctx.revert();
  }, []);
  return (
    <>
      <div className="patcontainer">
        <h1 className="patentHeading">OUR 17 PATENTS</h1>
        <div className="patentContainer">
          <div className="boxtop" ref={box1}>
            <img className="patent1 patent" src={patent0} alt="patent1" />
            <img className="patent2 patent" src={patent1} alt="patent2" />
            <img className="patent3 patent" src={patent3} alt="patent3" />
            <img className="patent4 patent" src={patent4} alt="patent4" />
          </div>

          <div className="boxbottom" ref={box2}>
            <img className="patent5 patent" src={patent5} alt="patent5" />
            <img className="patent6 patent" src={patent6} alt="patent6" />
            <img className="patent7 patent" src={patent7} alt="patent7" />
            <img className="patent8 patent" src={patent8} alt="patent8" />
            <img className="patent9 patent" src={patent9} alt="patent8" />
          </div>
           <div className="boxbottom2" ref={box3}>
            <img className='patent5 patent' src={patent5} alt="patent5" />
            <img className='patent6 patent' src={patent6} alt="patent6" />
            <img className='patent7 patent' src={patent7} alt="patent7" />
            <img className='patent8 patent' src={patent8} alt="patent8" />
            <img className='patent9 patent' src={patent9} alt="patent8" />
          </div> 
        </div> */
  return (
    <>
      <h1 className="patentHeading">OUR 17 PATENTS</h1>
      <div className="pvidContainer">
        <video autoPlay loop muted src={pvid}></video>
      </div>
      {/* </div> */}
    </>
  );
};

export default Patents;
