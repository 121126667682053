import React, { useEffect, useRef } from "react";
import { gsap, Expo } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./Projects.css";
import projectData from "./ProjectData";
import { Link, useNavigate } from "react-router-dom";

const Projects = () => {
  const navigate = useNavigate();
  const goToPage = (val) => {
    navigate(val);
  };
  const card = useRef();
  let projectTop = useRef();
  let projectBottom = useRef();

  useEffect(() => {
    const ctx = gsap.context(() => {
      gsap.registerPlugin(ScrollTrigger);
      const tl = gsap.timeline({});

      tl.to(
        projectTop.current,
        {
          scrollTrigger: {
            trigger: ".projectContainer",
            start: "55% bottom",
            end: "bottom bottom",

            scrub: 1,
          },
          yPercent: -100,
          opacity: 0,
          duration: 3,
          ease: Expo.easeInOut,
        },
        "a"
      );
      tl.to(
        projectBottom.current,
        {
          scrollTrigger: {
            trigger: ".projectContainer",
            start: "55% bottom",
            end: "10% 30%",
            scrub: 1,
          },
          yPercent: 100,
          opacity: 0,
          duration: 3,
          ease: Expo.easeIn,
        },
        "b"
      );

      tl.from(
        ".projects",
        {
          scrollTrigger: {
            trigger: ".projectContainer",
            start: "10% 80%",
            end: "10% bottom",
            scrub: 1,
          },
          yPercent: 200,
          opacity: 0,
          duration: 3,
          transition: 2,
          ease: Expo.easeIn,
        },
        "b"
      );
    });

    return () => ctx.revert();
  }, []);

  return (
    <>
      <div id="Projects" className="projectContainer" data-tilt>
        <div className="projectHeading">
          <h1>Projects</h1>
        </div>

        <div className="projectTop" ref={projectTop}>
          <h1>Projects</h1>
        </div>

        {projectData.map(function projectss(val) {
          return (
            <div className="projects">
              <Link to={val.to}>
                <div className="projectImg" ref={card}>
                  <div className="projectCard">
                    <img className="img1" src={val.imgsrc} alt={val.alt} />
                    <h5 className="title">{val.title}</h5>
                  </div>
                </div>
              </Link>
            </div>
          );
        })}

        <div className="projectBottom" ref={projectBottom}>
          <h1>Projects</h1>
        </div>
      </div>
    </>
  );
};

export default Projects;
