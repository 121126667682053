import "./Refinery.css";
import "./Fractionation.css";
import "./PalmKerneloilextraction.css";
import { Parallax } from "react-parallax";
import { Helmet } from "react-helmet";
import kernelios from "../components/Assets/FRACTIONATION.mp4";
import kernel from "../components/Assets/FRACTIONATION.webm";

const PalmKerneloilextraction = () => {
  return (
    <>
      <Helmet>
        <title>Palm Kernel Solvent Extraction</title>
        <meta
          name="description"
          content="The new Palm Oil Mill design will decrease
            the effluent and ponding system by 80-90%
            and enhance the OER by 1%. By Introducing
            new patent technologies for Palm Fibre Oil
            extraction and emulsified oil recovery from
            POME."
        />
        <meta
          name="keywords"
          content="Mesocarp Fibre Solvent Extraction Plant, PALM FIBRE Solvent Extraction Plant, Palm oil extraction Plant, palm oil mill, Palm oil system, palm kernel Oil Extraction Plant "
        />
      </Helmet>
      <div>
        <h1 className="Refinery">PALM KERNEL OIL EXTRACTION PLANT</h1>
      </div>

      <div className="refinaryContainer">
        <Parallax
          blur={0}
          bgImage="./Images/untitled1-35@2x.png"
          strength={600}
        >
          <div className="HimgContainer"></div>

          {/* <h1 className="pageHeading">Palm Kernel Oil Extraction</h1> */}
        </Parallax>

        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/untitled2-9@2x.png"
            alt="PALM KERNEL-1"
          />
          <h5 className="ref1h5">
            Palm Kernels are a product of the oil milling process.The fruit
            contains 20% oil and 7% palm kernel, of which 45-48% is oil. On a
            wet basis, the kernel contains about 47-50% (by weight) of oil,and
            its properties and characteristics are quite different from Palm
            Oil, as it contains lauric acid that gives soap its characte- ristic
            of producing lather.{" "}
          </h5>
        </section>

        <section className="refVid">
          <img
            className="fracbigImg"
            src="./Images/2-181008@2x.png"
            alt="PALM KERNEL-2"
          />
        </section>
        <br />

        <h5 className="mecpro1">
          At present, the prevalent practice is to use mechanical presses in two
          steps leaving 6% to 8% oil in the cake, so 5-6% oil recovery less as
          compared to new process method. The burning loss increases red colour
          which is another inefficiency in the present process as compared to
          new process (burning loss 1%, colour 4-6 R, protein 8-12%).
        </h5>
        <br />

        <section className="refVid">
          <video className="vid new" controls autoPlay muted>
            <source src={kernel} type="video/webm" />
            <source src={kernelios} type="video/mp4" />
          </video>
        </section>
        <br />
        <section className="refVid">
          <img
            className="fracbigImg"
            src="./Images/3-5@2x.png"
            alt="PALM KERNEL-3"
          />
        </section>
        <br />
        <h5 className="mecpro1">
          MECPRO, recently developed an innovative state of the art process
          technology for direct Solvent Extraction of Palm Kernel seed using
          Expander or first press cake, wherein, cost is reduced significantly &
          quality of the product improves.
        </h5>
        <section className="refinery1">
          <h5 className="ref1h5">
            {" "}
            <br /> <br />
            In this process, the power saving is 35 to 65% depending upon
            capacity, Protein increases by 8%,maintenance cost reducedby 60%, 5%
            more oil recovery and environmentmuch cleaner than by the mechanical
            process.{" "}
          </h5>

          <div className="reffImg2">
            <img
              className="reffImg2"
              src="./Images/4-2@2x.png"
              alt="PALM KERNEL-4"
            />
          </div>
        </section>
        <h5 className="mecpro1">
          Palm kernel pretreatment is essential for efficient extraction of oil
          from the palm kernels. Magnetic seperators are used to remove the
          metal debris, while vibrating screens are used to sieve sand, stone or
          other undesirable materials, which also help to increase the protein
          in the meal. <br />
          <br />
          Solvent extraction processing can be divided into three main
          operations: <br />
          <br />
          Kernel pre-treatment
          <br />
          <br />
          Oil Extraction <br />
          <br />
          Solvent recovery from the oil and meal
          <br />
          <br />
        </h5>

        <section className="refVid">
          <img
            className="fracbigImg"
            src="./Images/5-1@2x.png"
            alt="PALM KERNEL-5"
          />
        </section>
        <br />
      </div>
    </>
  );
};

export default PalmKerneloilextraction;
