import { Helmet } from "react-helmet";
import "./FatSplitting.css";
import { Parallax } from "react-parallax";

const FatSplitting = () => {
  return (
    <>
      <Helmet>
        <title>Fat Splitting </title>
        <meta
          name="description"
          content="The plant offered is versatile and all types
            of fats, oils and acid oils can be split. The
            plant consists of two or three Autoclaves
            wherein the water and fat move in counter
            current flow."
        />
        <meta name="keywords" content=" Fat Splitting  " />
      </Helmet>

      <div>
        <h1 className="Refinery">FAT SPLITTING</h1>
      </div>
      <div className="refinaryContainer">
        <Parallax blur={0} bgImage=".\Images\fatslitting.png" strength={600}>
          <div className="HimgContainer"></div>

          {/* <h1 className="pageHeading">Fat Splitting</h1> */}
        </Parallax>

        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/solvent-5@2x.png"
            alt="Refinery-img1"
          />
          <article>
            The plant offered is versatile and all types of fats, oils and acid
            oils can be split. The plant consists of two or three Autoclaves
            wherein the water and fat move in counter current flow. 95% to 97%
            splitting and a glycerine concentration in the sweet water of 6% to
            16% can be achieved in this plant depending on the quality of the
            raw material processed.{" "}
          </article>
        </section>

        <h3 className="pageHeading2">
          This plant has the following advantages:
        </h3>
        <h4 className="mecpro1">
          {" "}
          • Ease of Installation and operation <br />
          • Low/No maintenance <br />
          • Flexibility of operation.
          <br />
        </h4>

        <h5 className="mecpro1">
          Raw materials should undergo treatment depending on the quality and
          the end product desired.Good grade raw material can be processed
          directly without treatment.Raw material passes through the filter and
          is pumped to the autoclave by a High Pressure Pump through a Heat
          Exchanger where the tempe- rature is brought close to the splitting
          temperature. Process water collected in the Hot Water Tank is sent to
          the autoclave by a High Pressure Water Pump through a Water Heat
          Exchanger where the water at a pressure of around 30kg per cm. sq. is
          brought to the desired temperature.Stearic acid is kept in molten
          condition at around 60°C to 70°C in the Feed Tank from where by
          gravity it is fed to the Flaker. Cooling water at 32 °C from the
          cooling tower is circulated through the flaker to keep it at a
          temperature below 40°C. Stearic acid solidifies in the Drum which is
          kept in rotation and the flakes are collected and bagged.
        </h5>
        <br />

        {/* <section className="refVid">
          <video src="./Images/Oilmill.webm" autoPlay loop muted></video>
        </section> */}

        <section className="refVid">
          <img
            className="fracbigImg"
            src="./Images/fatsplitting2.png"
            alt="cotton -Header-Img"
          />
        </section>
        <br />
        <br />
      </div>
    </>
  );
};

export default FatSplitting;
