
import { Helmet } from 'react-helmet'


const DesignandEngineering = () => {
  return (
    <>
      <Helmet>
        <title>Design & Engineering</title>
        <meta name="description" content=" Establishing safety rules related to pressure integrity.
            Rules cover the construction of boilers, pressure vessels, transport tanks, and nuclear components.
            Also includes in-service inspection for pressure integrity of nuclear components and transport tanks.
            Interpretation of rules when questions arise." />
        <meta name="keywords" content="Design & Engineering, ASME Committee, Boiler and Pressure Vessel Committee, Establishing safety rules,  pressure integrity,
            Rules cover the construction of boilers, pressure vessels, transport tanks,  nuclear components,
            Also includes, in-service inspection, inspection for pressure integrity, of ,nuclear components, and transport tanks,
            ,Interpretation of rules, when questions arise " />
      </Helmet>




      <h1 className="pageHeading2">Design & Engineering</h1>




      <div className="bakery-content">
        <div>
          <h3 className="pageHeading2">Formation of ASME Committee (1911):</h3>
          <h5 className="mecpro1">The American Society of Mechanical Engineers (ASME) established a committee in 1911.
            Purpose: Formulating standard rules for the construction of steam boilers and pressure vessels. </h5>

          <h3 className="pageHeading2">Committee Name Change:</h3>
          <h5 className="mecpro1">The committee established in 1911 is now called the Boiler and Pressure Vessel Committee..
          </h5>

          <h3 className="pageHeading2">Committee's Function:</h3>
          <h5 className="mecpro1">Focus: Establishing safety rules related to pressure integrity.
            Rules cover the construction of boilers, pressure vessels, transport tanks, and nuclear components.
            Also includes in-service inspection for pressure integrity of nuclear components and transport tanks.
            Interpretation of rules when questions arise. </h5>

          <h3 className="pageHeading2">Code Scope:</h3>
          <h5 className="mecpro1">Excludes safety issues unrelated to pressure integrity.
            Users should refer to other relevant codes, standards, laws, or documents for non-pressure integrity safety concerns.
          </h5>

          <h3 className="pageHeading2">Engineering Judgment:</h3>
          <h5 className="mecpro1">Engineering judgment is essential in selecting appropriate Code rules for specific applications.
            It is not intended to replace a design handbook
            Users s
          </h5>

          <h3 className="pageHeading2">Mandatory Requirements and Prohibitions:</h3>
          <h5 className="mecpro1">The Code includes mandatory requirements, specific prohibitions, and nonmandatory guidance for construction.
            Aspects not addressed are not considered prohibited
          </h5>

          <h3 className="pageHeading2">Use of Computers for Design:</h3>
          <h5 className="mecpro1">The Code neither requires nor prohibits the use of computers for design.
            Designers using computer programs are responsible for program assumptions and their application.
          </h5>

          <h3 className="pageHeading2">Tolerances:</h3>

          <h5 className="mecpro1">The Code does not fully address tolerances.
            Nominal values can have allowable tolerances based on engineering judgment and standard practices.  </h5>
          <br />

        </div>
        <div>


          <h3 className="pageHeading2">Care and Inspection:</h3>
          <h5 className="mecpro1">The Committee provides suggested rules of good practice for the care and inspection of boilers and pressure vessels in service. </h5>

          <h3 className="pageHeading2">Freedom of Design Choice:</h3>
          <h5 className="mecpro1">Code rules do not endorse specific designs or limit manufacturer's design freedom.
          </h5>

          <h3 className="pageHeading2">Code Revisions:</h3>
          <h5 className="mecpro1">The Committee regularly reviews and revises rules, considering technological developments, Code Cases, and interpretation requests.
            Official interpretations are provided by the Boiler and Pressure Vessel Committee.
          </h5>

          <h3 className="pageHeading2">Publication of Revisions:</h3>
          <h5 className="mecpro1">Proposed revisions are published for public review and approval.
          </h5>

          <h3 className="pageHeading2">Effective Date of Revisions:</h3>
          <h5 className="mecpro1">Revisions generally become mandatory six months after approval unless contracted for earlier.
          </h5>

          <h3 className="pageHeading2">Material Specifications:</h3>
          <h5 className="mecpro1">Material specifications in Section II align with recognized national or international organizations.
            Materials must meet ASME requirements.
          </h5>

          <h3 className="pageHeading2">Use of Specifications from Other Sections:</h3>
          <h5 className="mecpro1">Materials allowed by various Code Sections must align with material specifications in Section II or referenced in the Guidelines for Acceptable Editions.
          </h5>

          <h3 className="pageHeading2">Language Interpretation:</h3>
          <h5 className="mecpro1">Singular/plural and gender interpretation is flexible based on context.
          </h5>
        </div>
      </div>


      <h1 className="pageHeading2">As per ASME</h1> <br />

      <h4>Fabrication of each component as per ASME
      </h4>
      <h4>ASME Section V111 Divition 1 <br /><br />
        Mainly Focused on: </h4> <br />
      <h2>UG: Requirement of all Methods of construction and all material</h2>
      <h4>UG16 - General</h4>
      <h4>UG27 - Thikhness of shell under internal pressure</h4>
      <h4> UG28 - Thickness of shell tubes under external pressure</h4>
      <h4> UG29 - Stiffening rings for cylindrical shells under external pressure</h4>
      <h4>UG32 - Formed Heads and sections, Pressure on concave side</h4>
      <h4> UG34 - Unstayed Flat heads and covers</h4>
      <h4>UG36 - Opening in pressure vessels</h4>
      <h4>UG37 - Reinforcement required for opening in Shells and Format Heads</h4>
      <h4>UG40 - Limits of Reinforecement</h4>
      <h4> UG45 - Nozzel Neck thickness</h4>
      <h4> UG46 - Inspection opening (Braced and stayed surface)</h4>
      <h4>UG49 - Location of Stay bolts</h4>
      <h4>UG90 - Inspection and Tests</h4><br /><br />

      <h2>UW: Requirement of pressure vessels fabricated by welding</h2>
      <h4>UW3  - Weld Joint Catogary</h4>
      <h4>UW11 - Radiographic and ultra sonic Examination</h4>
      <h4>UW12 - Joint Effeciencies</h4>
      <h4>UW16 - Minimum Requirement of Attachment Welds at opening</h4><br /><br />

      <h2>UCS: Requirement of pressure vessel constructed of carbon and low alloy steal</h2>
      <h4>UCS - Requirement of post weld heat treatment</h4>
      <h4>UCS - Materials</h4><br />






























    </>
  )
}

export default DesignandEngineering