import "./Soap.css";
import "./Refinery.css";
import { Parallax } from "react-parallax";
import { Helmet } from "react-helmet";

const Soap = () => {
  return (
    <>
      <Helmet>
        <title>Soap </title>
        <meta
          name="description"
          content=">Mecpro has Designed a unique process for
            the Soap Stock, which is coming from the
            Refinery after the Chemical process. This
            process is 100% Environment friendly he-
            nce very popular and adopted readily by
            the processing industry."
        />
        <meta
          name="keywords"
          content=" Soap, completely environmental friendly Soap, Stock
          Splitting, Acid Oil Plants, How to make soap"
        />
      </Helmet>

      <div>
        <h1 className="Refinery">SOAP</h1>
      </div>
      <div className="refinaryContainer">
        <Parallax
          blur={0}
          bgImage="./Images/1-1801777404@2x.png"
          strength={600}
        >
          <div className="HimgContainer"></div>

          {/* <h1 className="pageHeading">Soap</h1> */}
        </Parallax>
        <section className="refinery1">
          <img
            className="refImg"
            src="./Images/untitled1-37@2x.png"
            alt="Soap-img1"
          />
          <article>
            Mecpro has Designed a unique process for the Soap Stock, which is
            coming from the Refinery after the Chemical process. This process is
            100% Environment friendly he- nce very popular and adopted readily
            by the processing industry.{" "}
          </article>
        </section>
        <h1 className="pageHeading2">ACID OIL SECTION</h1>
        <h5 className="mecpro1">
          MECPRO offers new generation, completely environmental friendly Soap
          Stock Splitting / Acid Oil Plants. This latest designed Plant is
          completely closed & it does not pass any acid fumes to the atmosphere.
          The final output will be Acid oil with good color which is used
          directly as raw material for Soap Plants.
        </h5>
        {/* <section className="refVid">

          <video src="./Images/Oilmill.mp4" autoPlay loop muted></video>
        </section> */}
        <h1 className="pageHeading2">PROCESS DESCRIPTION</h1>
        <h5 className="mecpro1">
          Soap Stock from process house is accumulated in a Homogeneous Tank,
          which is equipped with the open steam heating arrangements, so as to
          evaporate moi- sture as far as possible in terms of minimizing acid
          consumption for splitting. This Soap Stock is pumped through the
          horizontal Screw Pump to the Acid Reactors for splitting, Reactors are
          PP FRP constructed fully closed vessels, equipped with copper steam
          heating cum agitation coils, light & sight glasses, inspection manhole
          etc. Splitting takes place in semi-continuous manner. Rea- ctors are
          used in cycles for splitting operation. After getting appropriate
          charge in selected Reactor, the Soap Stock is getting boiled in terms
          of drying with steam heating cum agitation coils. After removing
          maximum moisture pre- calculated amount of sulphuric acid (H2SO4) will
          be added slowly. Splitting is done under continuous observation so as
          to maintain appropriate acid value of final product also for avoiding
          excess acid consumption. <br />
          After Completion of splitting operation, settling process starts for
          3- 4 hours. After that water is getting drained to the Fat Trap Tank
          and clear acid oil is pumped to the storage tank with PP Pump. Fat
          Trap Tank is PP FRP Constr- ucted rectangular tank, specially designed
          with multi – compartments & siphoning system to collect all the
          droplets of Acid Oil.
        </h5>
        <h1 className="pageHeading2">Acid Oil Flow Chart</h1>
        <img src="./Images/2 181009.png" alt="" className="Bakeryimg2" />
        <img src="./Images/3-7@2x.png" alt="" className="Bakeryimg2" />
        <h5 className="mecpro1">
          Vapours from Reactors are carried out contineuously through Chimney,
          made of FRP.
        </h5>
        <h1 className="pageHeading2">Toilet Soap</h1>
        <h5 className="mecpro1">
          Generally, main raw material used for making toilet soap are palm oil,
          palm kernel oil, coconut oil and other fatty acid, caustic soda,
          water, color perfume, preservatives, additives etc. The process
          started from soap saponification vessel, oil/fats are transfer to soap
          saponification vessel, required quantity caustic lye (water) required
          concentration in added during the process. At the same time solution
          is heated (boiled) by using steam. After process complete heat soap,
          ready in saponification vessel which is transferred to cooling mould.
          After cooling and drying of soap for few hours. The same soap in soap
          chips by using soap chips making machine that is for fast and better
          drying. The dried chips used for finishing of soap and that process
          started from mixer machine where desired colour, perfume
          preservatives, additives etc. are added. The mixed soap rolls machine
          where it gets homo- genized finishing and final output soap in form of
          liner ribbon. The same thin ribbon is feed in to the duplex plodder
          machine which used for the highest compression, finishing of soap and
          which produced final output of soap in bar form. Automatic cutting
          machine is kept next to duplex plodder outlet and which cut soap in
          bar form. The next process is cutting bar in to cake which will be
          suitable size of soap. Automatic machine is used to stamp final soap
          (which are made specially according to buyer’s selected to go, brand
          name.
        </h5>
        <img src="./Images/4-4@2x.png" alt="bk" className="Bakeryimg2" />
      </div>
      <br />
    </>
  );
};

export default Soap;
