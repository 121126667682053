import React from "react";
import extra from "../components/Assets/PREPARATORY & SOLVENT EXTRACTION FULL HD_2.mp4";
import extraios from "../components/Assets/PREPARATORY & SOLVENT EXTRACTION FULL HD.webm";
import { Helmet } from "react-helmet";

const ExtractionSection = () => {
  return (
    <>
      <Helmet>
        <title>Solvent - Extraction SECTION</title>
        <meta
          name="description"
          content=" The extraction method is by creating penetration by designing the
          extractor and creating a wave bed to prevent flooding of hexane from
          one compartment to another compartment. The flood control system will
          ensure the hopper hexane spread on the raw material to ensure high
          efficiency in the extraction method."
        />
        <meta
          name="keywords"
          content="Mecpro has increased the percolation and filtration area by
          60% which allows for a higher spray flow rate and eliminates flooding.
          This ensures more efficient extraction.,  
          solvent plant,
          oil solvent extraction plant,
          continuous solvent extraction plant,
          Miscella Filteration,
          Miscella Separation,
          Hexane separation,
          Azeotropic distillation,
          Self wash Miscella Filtration,
          Solventisation,
          Degumming,
          Edible Oil Solvent Extraction Plant,
          Desolventisation,
          Losses of Solvent,
          Solvent Extraction,
          What is Solvent Extraction,
          Higher bleachability,
          non-hydratable phosphatides,
          Advance Solvent Extraction process, 
         "
        />
      </Helmet>
      <div>
        <h1 className="Refinery">EXTRACTION SECTION</h1>

        <h5 className="mecpro1">
          The extraction method is by creating penetration by designing the
          extractor and creating a wave bed to prevent flooding of hexane from
          one compartment to another compartment. The flood control system will
          ensure the hopper hexane spread on the raw material to ensure high
          efficiency in the extraction method. The temperature required for
          solvent in the extractor is partially near 60% absorbed from
          Desolventized vapors. The spray pump selection depends upon the raw
          material, ensuring the breakdown of circulation pumps in the process.
          The double discharge cutter will ensure carrying conveyors for smooth
          running. The other advanced technologies incorporated in the
          extraction will make uninterrupted operation in the solvent extraction
          process. <br />
          <br />
          <h1 className="pageHeading2">
            ADVANCED TECHNOLOGY FOR EXTRACTION{" "}
          </h1>{" "}
          <br />• Mecpro has increased the percolation and filtration area by
          60% which allows for a higher spray flow rate and eliminates flooding.
          This ensures more efficient extraction. <br />• Wave bed extractor
          ensure mixing of miscella from one compartment to another compartment.{" "}
          <br /> • A robust heavy duty drive system minimizes downtime during
          the extraction process, ensuring consistent operation and reliability.{" "}
          <br /> • The robust design of uniform feeding mechanism using spike
          cutter and drum cutter work in tantum to maintain uniform feeding of
          material from the extractor to the solvent tight conveyor. This
          consistency is crucial for optimal extraction. <br /> • Powder
          material from the belt cleaning separated the solid cake from the
          solvent and continuously pass to the solvent tight conveyor by using
          screw conveyor system. <br /> • Sealed screw conveyor system for
          material from extractor to D.T. instead of rotary valve. <br /> •
          Final Vent Air Purification arrangement to separate hexane from the
          Vent Air.{" "}
        </h5>

        <section className="refVid">
          <video className="vid new" controls autoPlay muted>
            <source src={extra} type="video/webm" />
            <source src={extraios} type="video/mp4" />
          </video>
        </section>
      </div>
    </>
  );
};

export default ExtractionSection;
