import React from 'react';
import Patentsf from '../components/Patentsf';

/* import Patents from '../components/Patents'; */







const PatentandAward = () => {

  return (
    <>



      <Patentsf />







    </>
  )
}


export default PatentandAward