import React from 'react'
import "./Fabrication.css"
import video from '../components/Assets/workshop/workshopvid.webm'
import work from '../components/Assets/workshop/01.jpg'
import work1 from '../components/Assets/workshop/Aqua Y2 Pro_20150725_182521.jpg'
import work2 from '../components/Assets/workshop/Aqua Y2 Pro_20150901_162933.jpg'
import work3 from '../components/Assets/workshop/IMG-20161104-WA0020.jpg'
import work4 from '../components/Assets/workshop/IMG-20170505-WA0123.jpg'
import work5 from '../components/Assets/workshop/IMG-20170505-WA0123.jpg'
import { Helmet } from 'react-helmet'

const Fabrication = () => {
    return (
        <>
            <Helmet>
                <title>Fabrication</title>
                <meta name="description" content=" MECPRO incorporated in 1986,with the objective of supplying project on turnkey basis in the field of edible oil & fatty acid, oil & chemical etc. companies having several credential for process & development independently as well as along with the various institution like CSIR" />
                <meta name="keywords" content="Fabrication, mecpro, mecpro workshop, Modern machine, Modern machine shop, equipped with a range of lathes, lathes, drilling machines, welding, cutting, VMC, CNC, and other, allied machines, All machine,  measuring equipment,  calibrated, calibrated & good condition " />
            </Helmet>
            <h1 className="pageHeading2">FABRICATION WORK</h1>


            <h5 className="mecpro1">MECPRO incorporated in 1986,with the objective of supplying project on turnkey basis in the field of edible oil & fatty acid, oil & chemical etc. companies having several credential for process & development independently as well as along with the various institution like CSIR..
            </h5> <br />

            <h1 className="pageheading2">WORKSHOP</h1> <br />

            <h4>Our workshop spreads over a wide area situated at ludhiana, punjab. <br />

                Our Workshop is completed with heavy machineries equipment capability for fabrication all kind of equipments. <br />

                ALL WORK FOR OVERSEAS & SHIPMENT FABRICATE AT SITE. <br />
            </h4>
            <section className="workVid">

                <video src={video} autoPlay loop controls muted></video>
                <div className="imgwraper">
                    <img className="workimg" src={work} alt="workshop" />
                    <img className="workimg" src={work1} alt="workshop" />
                    <img className="workimg" src={work2} alt="workshop" />
                    <img className="workimg" src={work3} alt="workshop" />
                    <img className="workimg" src={work4} alt="workshop" />
                    <img className="workimg" src={work5} alt="workshop" />
                </div>
            </section><br />

            <h1 className="pageheading2">PRODUCTION</h1> <br />
            <h4>We are maintaining good production environment:</h4><br />

            <h1 className="pageheading2">FABRICATION SHOP</h1> <br />
            <h4>Modern machine shop equipped with a range of lathes, drilling machines, welding, cutting, VMC, CNC and other allied machines<br /> All machine and measuring equipment are calibrated & good condition

                <br />Supporting facilities such as handling equipment is proper.
            </h4><br />


        </>
    )
}

export default Fabrication