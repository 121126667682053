import { Helmet } from "react-helmet";
import "./CottonSeed.css";
import cotton from "../components/Assets/Cotton seed file.webm";

/* import { Parallax } from "react-parallax" */

const CottonSeed = () => {
  return (
    <>
      <Helmet>
        <title>Cotton Seed Delinting </title>
        <meta
          name="description"
          content="Cotton is an important fibre crop of global significance
            and is grown in tropical and subtropical regions of more
            than eighty countries. cotton seed has a similar structure
            to other oilseeds such as sunflower seed , having an oil-
            bearing kernal surrounded by a hard outer hull:in processing , 
            the oil is extracted from the kernel."
        />
        <meta
          name="keywords"
          content=" Cotton Seed Delinting,  cotton, cotton will
            become a source of fibre,cotton seed oil, cotton seed protein,cotton plant, viz.lint, seed, stalk, leaves,
            main product of cotton seed,  by-
            products of cotton seed "
        />
      </Helmet>
      <div>
        <h1 className="Refinery">COTTON SEEDS</h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img
            className="RefinieryHeaderImage"
            src="./Images/cottonseed1.webp"
            alt="Refinary-Header-Img"
          />
        </div>
        <br />

        <section className="refinery1">
          <img className="refImg" src="./Images/cottonseed.webp" alt="refImg" />
          <article>
            Cotton is an important fibre crop of global significance and is
            grown in tropical and subtropical regions of more than eighty
            countries. cotton seed has a similar structure to other oilseeds
            such as sunflower seed , having an oil- bearing kernal surrounded by
            a hard outer hull:in proce- ssing , the oil is extracted from the
            kernel. cottomseed has been divided in to three parts: 1 meat (
            kernels hull ( outer hard covering of seed ) Lint ( soft white
            fibers )Its seeds alsocontains 20-25% protein. Hence, in future,
            cotton will become a source of fibre, oil and protein.There are four
            products of cotton plant viz.lint, seed, stalk and leaves. Out of
            these, lint is the main product and rest are by- products.The
            information on the utilization of cotton byproducts is very scanty
            and that too is not available in any single source.
          </article>
        </section>

        <h1 className="pageHeading2 ">
          We provide advanced technology for Cotton seed Processing Plants which
          includes following stages of processing{" "}
        </h1>
        <h5 className="mecpro1">
          Delinting Meats recovery <br /> <br />
          Hull beating <br /> <br />
          Cooking and Conditioning <br /> <br />
          There are integrated expellers that are used for recovery of oil from
          the cooked meats.Remaining residual oil cake in the expeller is
          further completely recovered through Solvent Extraction process.{" "}
          <br />
          <br />
          Finally, the oil is refined in order to get Edible Cottonseed Oil.{" "}
          <br />
          <br />
          In order to protect the plant from corrosion and other damages, all
          the machines integrated in it are properly lined with FRP. The process
          carried out for obtaining clean cottonseed from delinting is
          ecofriendly and does not cause any harm to human beings. <br />
          The seeds are about 15% of the value of the crop and are pressed to
          make oil and used as animal feed. About 5% of the seeds are used for
          sowing the next crop. <br />
        </h5>
        <section className="refVid">
          <video src={cotton} autoPlay loop controls muted></video>
        </section>
        <h1 className="pageHeading2">DELINTED COTTONSEED</h1>

        <h5 className="mecpro1">
          Delinted Cottonseeds are produced using two type of processes -
          Mechanical and Acid.
        </h5>

        <h1 className="pageHeading2">MECHANICALLY DELINTED COTTONSEED -</h1>
        <h5 className="mecpro1">
          Mechanical delinting is the most common process for delinted seed
          available in the feed trade. Mechanically delinted seed retains about
          1-2% residual linters which usually appear on the ends of the seeds.
        </h5>

        <h1 className="pageHeading2">ACID DELINTED COTTONSEED-</h1>

        <h5 className="mecpro1">
          {" "}
          Acid delinting process completely removes all linters. This process is
          used for the production of planting seed. At certain times during the
          year, quantities of culled or leftover planting seed, become avail-
          able to the feed trade. It is the most common form of delinted seed
          available in the feed trade. Mechanically delinted seed retains about
          1-2% residual linters which usually appear on the ends of the seeds.
          Acid delinting is a process that completely removes all linters. This
          process is used for the production of planting seed. At certain times
          during the year, quantities of culled, or leftover planting seed,
          become available to the feed trade. <br />
          <br />
          Our Cottonseed plant is fabricated using advanced technology for
          reliable performance. <br />
          <br />
          Cottonseed plant which we offer is fully eco-friendly as it produces
          pollution free while carrying out its performance.
        </h5>
        <br />

        <section className="refVid">
          <img
            className="cotlastimg"
            src="./Images/2-181011@2x.webp"
            alt="solvent-img"
          />
          <br />
          <br />
        </section>
      </div>
    </>
  );
};

export default CottonSeed;
