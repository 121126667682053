import { Helmet } from "react-helmet";
import "./Hydrogenation.css";

const Hydrogenation = () => {
  return (
    <>
      <Helmet>
        <title>Hydrogenation </title>
        <meta name="description" content="Hydrogenation is a process in which a
            liquid unsaturated fat is turned into a
            solid fat by adding hydrogen in presence
            of a catalyst. " />
        <meta name="keywords" content=" Hydrogenation,  chemical process for adding hydrogen to
            unsaturated triglyceride esters  " />
      </Helmet>
      <div><h1 className="Refinery">HYDROGENATION</h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img className="RefinieryHeaderImage" src="./Images/1 1801777407.png" alt="cotton -Header-Img" />
        </div>

        {/* <h1 className="pageHeading">Hydrogenation</h1> */}


        <section className="refinery1">
          <img className="refImg" src="./Images/2 181012.png" alt="refImg" />
          <h5 className="ref1h5">Hydrogenation is a process in which a
            liquid unsaturated fat is turned into a
            solid fat by adding hydrogen in presence
            of a catalyst. Hydrogenation is done
            generally to increase the hardness of oil.
            It is among the tools the industry uses to
            give the fats and oils desired functionality
            for specified products. Hydrogenation is a
            chemical process for adding hydrogen to
            unsaturated triglyceride esters.
          </h5><br />


        </section>
        <h1 className="pageHeading2">A process in which a liquid unsaturated fat is turned into a solid fat by adding hydrogen.   </h1>

        <h5 className="mecpro1">Mecpro has in association with NRDC has developed a continuous
          process for hydrogenation of oil which will not only substantially
          reduce the cost of production but also improve the product quality
          besides effective use of labour, space and energy.



        </h5><br />
        <section className="refVid">

          <img className="solmidimg" src="./Images/3-8@2x.png" alt="solvent-img" />
        </section><br />

        <h5 className="mecpro1">The process developed by MECPRO is unique as it does not require the fibre to be dried and wet slivered
          fibre can be directly processed. This technology has enhanced the oil extraction rate & maintained a better
          quality of the oil. For the capital investment required, the project is viable with payback within 1 year for 120
          Ton FFB/Hr. going on to 3 years for 45 Ton FFB/Hr.

        </h5><br />


      </div>

    </>
  );
};

export default Hydrogenation;
