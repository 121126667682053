import React from 'react'
import './Clients.css'
import c1 from './images/clients/1.png';
import c2 from './images/clients/2.png';
import c3 from './images/clients/3.png';
import c4 from './images/clients/4.png';
import c5 from './images/clients/5.png';
import c6 from './images/clients/6.png';
import c7 from './images/clients/Group 2186.png';
import c8 from './images/clients/Group 2130.png';
import c9 from './images/clients/Group 2131.png';
import c10 from './images/clients/Group 2132.png';
import c11 from './images/clients/Group 2133.png';
import c12 from './images/clients/Group 2134.png';
import c13 from './images/clients/Group 2135.png';
import c14 from './images/clients/Group 2136.png';
import c15 from './images/clients/Group 2137.png';
import c16 from './images/clients/Group 2138.png';
import c17 from './images/clients/Group 2139.png';
import c18 from './images/clients/Group 2140.png';
import c19 from './images/clients/Group 2141.png';
import c20 from './images/clients/Group 2142.png';
import c21 from './images/clients/Group 2143.png';
import c22 from './images/clients/Group 2144.png';
import c23 from './images/clients/Group 2145.png';
import c24 from './images/clients/Group 2146.png';
import c25 from './images/clients/Group 2147.png';
import c26 from './images/clients/Group 2148.png';
import c27 from './images/clients/Group 2149.png';
import c28 from './images/clients/Group 2150.png';
import c29 from './images/clients/Group 2151.png';
import c30 from './images/clients/Group 2152.png';
import c31 from './images/clients/Group 2153.png';
import c32 from './images/clients/Group 2154.png';
import c33 from './images/clients/Group 2155.png';
import c34 from './images/clients/Group 2184.png';
import c35 from './images/clients/Group 2185.png';
import c36 from './images/clients/Group 2182.png';



const Clients = () => {
  return (
    <>

      <h1 className='ClientHeading'>Our Valued Customers</h1>
      <div className="clientContainer">
        <marquee behavior="alternate" direction="right">
        <img src={c1} alt="Our Clients" />
        <img src={c2} alt="Our Clients" />
        <img src={c3} alt="Our Clients" />
        <img src={c4} alt="Our Clients" />
        <img src={c5} alt="Our Clients" />
        <img src={c6} alt="Our Clients" />
        <img src={c7} alt="Our Clients" />
        <img src={c8} alt="Our Clients" />
        <img src={c9} alt="Our Clients" />
        <img src={c10} alt="Our Clients" />
        <img src={c11} alt="Our Clients" />
        <img src={c12} alt="Our Clients" />
        <img src={c13} alt="Our Clients" />
        <img src={c14} alt="Our Clients" />
        <img src={c15} alt="Our Clients" />
        <img src={c16} alt="Our Clients" />
        <img src={c17} alt="Our Clients" />
        <img src={c18} alt="Our Clients" />
        </marquee>
        <marquee behavior="alternate" direction="left" >
        <img src={c19} alt="Our Clients" />
        <img src={c20} alt="Our Clients" />
        <img src={c21} alt="Our Clients" />
        <img src={c22} alt="Our Clients" />
        <img src={c23} alt="Our Clients" />
        <img src={c24} alt="Our Clients" />
        <img src={c25} alt="Our Clients" />
        <img src={c26} alt="Our Clients" />
        <img src={c27} alt="Our Clients" />
        <img src={c28} alt="Our Clients" />
        <img src={c29} alt="Our Clients" />
        <img src={c30} alt="Our Clients" />
        <img src={c31} alt="Our Clients" />
        <img src={c32} alt="Our Clients" />
        <img src={c33} alt="Our Clients" />
        <img src={c34} alt="Our Clients" />
        <img src={c35} alt="Our Clients" />
        <img src={c36} alt="Our Clients" />
        </marquee>  
      </div>

    </>
  )
}

export default Clients