import React, { useState, useEffect, useCallback } from 'react';
import { 
  Box,
  Modal,
  Typography,
  TextField,
  Paper,
  Grid,
  IconButton,
  Chip,
  Fade,
  Container,
  Card,
  CardContent,
  InputAdornment,
  Divider,
  CircularProgress
} from '@mui/material';
import {
  Search as SearchIcon,
  Clear as ClearIcon,
  Refresh as RefreshIcon,
  Translate as TranslateIcon,
  ArrowForward as ArrowForwardIcon,
  History as HistoryIcon
} from '@mui/icons-material';

const LanguageSelector = () => {
  const [showModal, setShowModal] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const [recentLanguages, setRecentLanguages] = useState([]);
  const [isTranslating, setIsTranslating] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [initAttempts, setInitAttempts] = useState("");
  const [error, setError] = useState(null);

  // Reversed languages object - name to code mapping
  const languages = {
    'Afrikaans': 'af',
    'Albanian': 'sq',
    'Amharic': 'am',
    'Arabic': 'ar',
    'Armenian': 'hy',
    'Azerbaijani': 'az',
    'Basque': 'eu',
    'Belarusian': 'be',
    'Bengali': 'bn',
    'Bosnian': 'bs',
    'Bulgarian': 'bg',
    'Catalan': 'ca',
    'Cebuano': 'ceb',
    'Chinese': 'zh',
    'Corsican': 'co',
    'Croatian': 'hr',
    'Czech': 'cs',
    'Danish': 'da',
    'Dutch': 'nl',
    'English': 'en',
    'Esperanto': 'eo',
    'Estonian': 'et',
    'Finnish': 'fi',
    'French': 'fr',
    'Frisian': 'fy',
    'Galician': 'gl',
    'Georgian': 'ka',
    'German': 'de',
    'Greek': 'el',
    'Gujarati': 'gu',
    'Haitian Creole': 'ht',
    'Hausa': 'ha',
    'Hawaiian': 'haw',
    'Hebrew': 'he',
    'Hindi': 'hi',
    'Hmong': 'hmn',
    'Hungarian': 'hu',
    'Icelandic': 'is',
    'Igbo': 'ig',
    'Indonesian': 'id',
    'Irish': 'ga',
    'Italian': 'it',
    'Japanese': 'ja',
    'Javanese': 'jv',
    'Kannada': 'kn',
    'Kazakh': 'kk',
    'Khmer': 'km',
    'Korean': 'ko',
    'Kurdish': 'ku',
    'Kyrgyz': 'ky',
    'Lao': 'lo',
    'Latin': 'la',
    'Latvian': 'lv',
    'Lithuanian': 'lt',
    'Luxembourgish': 'lb',
    'Macedonian': 'mk',
    'Malagasy': 'mg',
    'Malay': 'ms',
    'Malayalam': 'ml',
    'Maltese': 'mt',
    'Maori': 'mi',
    'Marathi': 'mr',
    'Mongolian': 'mn',
    'Myanmar (Burmese)': 'my',
    'Nepali': 'ne',
    'Norwegian': 'no',
    'Nyanja (Chichewa)': 'ny',
    'Odia (Oriya)': 'or',
    'Pashto': 'ps',
    'Persian': 'fa',
    'Polish': 'pl',
    'Portuguese': 'pt',
    'Punjabi': 'pa',
    'Romanian': 'ro',
    'Russian': 'ru',
    'Samoan': 'sm',
    'Scots Gaelic': 'gd',
    'Serbian': 'sr',
    'Sesotho': 'st',
    'Shona': 'sn',
    'Sindhi': 'sd',
    'Sinhala': 'si',
    'Slovak': 'sk',
    'Slovenian': 'sl',
    'Somali': 'so',
    'Spanish': 'es',
    'Sundanese': 'su',
    'Swahili': 'sw',
    'Swedish': 'sv',
    'Tajik': 'tg',
    'Tamil': 'ta',
    'Tatar': 'tt',
    'Telugu': 'te',
    'Thai': 'th',
    'Turkish': 'tr',
    'Turkmen': 'tk',
    'Ukrainian': 'uk',
    'Urdu': 'ur',
    'Uyghur': 'ug',
    'Uzbek': 'uz',
    'Vietnamese': 'vi',
    'Welsh': 'cy',
    'Xhosa': 'xh',
    'Yiddish': 'yi',
    'Yoruba': 'yo',
    'Zulu': 'zu'
  };

  // Debug logging function
  const debugLog = (stage, message, data = null) => {
    const timestamp = new Date().toISOString();
    console.log(`[${timestamp}] [${stage}]:`, message, data ? data : '');
  };

  const loadTranslateScript = useCallback(() => {
    if (scriptLoaded) {
      debugLog('SCRIPT_LOAD', 'Script already loaded, skipping');
      return Promise.resolve();
    }

    return new Promise((resolve, reject) => {
      const existingScript = document.querySelector('script[src*="translate_a/element.js"]');
      if (existingScript) {
        setScriptLoaded(true);
        resolve();
        return;
      }

      const script = document.createElement('script');
      script.src = '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit&hl=en';
      script.async = true;

      script.onload = () => {
        debugLog('SCRIPT_LOAD', 'Script loaded successfully');
        setScriptLoaded(true);
        resolve();
      };

      script.onerror = (error) => {
        debugLog('SCRIPT_LOAD_ERROR', 'Error loading script', error);
        reject(error);
      };

      document.body.appendChild(script);
    });
  }, [scriptLoaded]);

  useEffect(() => {
    loadTranslateScript();

    window.googleTranslateElementInit = () => {
      new window.google.translate.TranslateElement({
        pageLanguage: 'en',
        includedLanguages: Object.values(languages).join(','),
        layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE,
        autoDisplay: false,
      }, 'google_translate_element');
      debugLog('GOOGLE_INIT', 'Google Translate element initialized');
    };
  }, [loadTranslateScript, languages]);

  const setLanguageDirectly = useCallback(() => {
    if (!selectedLanguage) return;

    try {
      const translateElement = document.querySelector('#google_translate_element');
      if (!translateElement) throw new Error('Google Translate element not found');

      const languageDropdown = translateElement.querySelector('.goog-te-gadget-simple');
      if (!languageDropdown) throw new Error('Language dropdown not found');

      // Simulate a click on the language dropdown
      languageDropdown.click();

      const languageOptions = Array.from(
        document.querySelectorAll('.text .VIpgJd-ZVi9od-vH1Gmf-ibnC6b')
      );

      // Find the language option using the language name (key) instead of code
      const selectedLanguageName = Object.entries(languages).find(([name, code]) => code === selectedLanguage)?.[0];
      const targetLanguage = languageOptions.find(
        (el) => el.textContent.trim() === selectedLanguageName
      );

      if (targetLanguage) {
        targetLanguage.click(); // Select the desired language
        debugLog('LANGUAGE_SET', `Language set to: ${selectedLanguageName} (${selectedLanguage})`);
        setIsTranslating(false);
      } else {
        throw new Error(`Language option "${selectedLanguageName}" not found`);
      }
    } catch (error) {
      debugLog('ERROR', error.message);
    }
  }, [selectedLanguage, languages]);

  useEffect(() => {
    if (scriptLoaded && selectedLanguage) {
      debugLog('SETTING_LANGUAGE', `Attempting to set language: ${selectedLanguage}`);
      setLanguageDirectly();
    }
  }, [scriptLoaded, selectedLanguage, setLanguageDirectly]);

  const initializeTranslation = (languageCode) => {
    debugLog('INIT_TRANSLATION', `Initializing translation for language: ${languageCode}`);
    setIsTranslating(true);
    setShowModal(false);
    setSelectedLanguage(languageCode);
  };

  const filteredLanguages = Object.entries(languages).filter(([name]) =>
    name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Rest of the component remains the same...
  return (
    <>
      <Modal
        open={showModal}
        closeAfterTransition
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Fade in={showModal}>
          <Container maxWidth="md">
            <Paper
              elevation={24}
              sx={{
                p: 4,
                maxHeight: '90vh',
                overflowY: 'auto',
                borderRadius: 3,
                bgcolor: 'background.paper',
              }}
            >
              {/* Header */}
              <Box sx={{ textAlign: 'center', mb: 4 }}>
                <TranslateIcon sx={{ fontSize: 48, color: 'primary.main', mb: 2 }} />
                <Typography variant="h4" component="h1" gutterBottom fontWeight="bold">
                  Choose Your Language
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Select your preferred language to continue
                </Typography>
              </Box>

              {/* Search */}
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search languages..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 3 }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchTerm && (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchTerm('')} edge="end" size="small">
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {/* Recent Languages */}
              {recentLanguages.length > 0 && (
                <Box sx={{ mb: 3 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
                    <HistoryIcon fontSize="small" color="action" />
                    <Typography variant="subtitle2" color="text.secondary">
                      Recent
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap' }}>
                    {recentLanguages.map(code => {
                      const languageName = Object.entries(languages).find(([_, c]) => c === code)?.[0];
                      return (
                        <Chip
                          key={`recent-${code}`}
                          label={languageName}
                          onClick={() => initializeTranslation(code)}
                          color="primary"
                          variant="outlined"
                          icon={<TranslateIcon />}
                          clickable
                        />
                      );
                    })}
                  </Box>
                </Box>
              )}

              <Divider sx={{ my: 3 }} />

              {/* Language Grid */}
              <Grid container spacing={2}>
                {filteredLanguages.map(([name, code]) => (
                  <Grid item xs={12} sm={6} md={4} key={code}>
                    <Card
                      variant="outlined"
                      sx={{
                        cursor: 'pointer',
                        transition: 'all 0.2s',
                        '&:hover': {
                          transform: 'translateY(-2px)',
                          boxShadow: 2,
                          borderColor: 'primary.main',
                        },
                      }}
                      onClick={() => initializeTranslation(code)}
                    >
                      <CardContent sx={{ 
                        display: 'flex', 
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        '&:last-child': { pb: 2 }
                      }}>
                        <Box>
                          <Typography variant="subtitle1" component="div">
                            {name}
                          </Typography>
                          <Typography variant="caption" color="text.secondary">
                            {code.toUpperCase()}
                          </Typography>
                        </Box>
                        <ArrowForwardIcon color="action" sx={{ opacity: 0.5 }} />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Paper>
          </Container>
        </Fade>
      </Modal>

      {/* Translation Widget Container */}
      <Box
        sx={{
          position: 'fixed',
          bottom: 16,
          right: 16,
          zIndex: 1000,
        }}
      >
        {isTranslating && (
          <Paper
            sx={{
              mb: 2,
              p: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
            }}
          >
            <CircularProgress size={20} />
            <Typography variant="body2">
              Translating content... (Attempt {initAttempts})
            </Typography>
          </Paper>
        )}
        <Paper
          elevation={3}
          sx={{ p: 2 }}
        >
          <div id="google_translate_element"></div>
          <IconButton
            size="small"
            onClick={() => {
              debugLog('REFRESH_CLICK', 'Manual refresh triggered');
              window.location.reload();
            }}
            sx={{ mt: 1 }}
            color="primary"
          >
            <RefreshIcon />
          </IconButton>
        </Paper>
      </Box>
    </>
  );
};

export default LanguageSelector;