import React, { useState } from 'react'
const loginInitialValue ={
    name: '',
    usename: '',
    password: ''
}
const Login = () => {
    const [Login, setLogin] = useState(loginInitialValue);
    const onValueChange = (e)=>{
        setLogin({...Login, [e.target.name]: e.target.value})
        console.log(setLogin);
    }
       
    const loginUser =() =>{

    }
  return (
   <>
   <h1>Admin Login</h1>

   <form method="POST">

    <input type="text" name='userName' placeholder='User Name' onChange={(e)=>{onValueChange(e)}} />
    <input type="password" name='password' placeholder='enter Password' onChange={(e)=>{onValueChange(e)}} />
    <input type="button" value="Log in" onClick={loginUser()} />





   </form>
   
   
   
   
   </>
  )
}

export default Login