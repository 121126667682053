import "./SustainabilityQualityManagem.css";



const SustainabilityQualityManagem = () => {
  return (
    <>

      <div className="sustainability-quality-managem">

        <div className="sustainability-quality-managem-child" />
        <b className="quality-management">Quality Management</b>
        <b className="all-work-carried-container">
          <p className="all-work-carried">
            ALL WORK CARRIED OUT BY OUR WELL EXPERIENCED TEAM
          </p>
        </b>
        <b className="quality-specifications">QUALITY SPECIFICATIONS</b>
        <img className="image-2-icon" alt="" src="/Images/image-2@2x.png" />
        <b className="our-quality-management">OUR QUALITY MANAGEMENT SYSTEM</b>
        <b className="inspection">INSPECTION</b>
        <b className="the-qc-facility">
          The Q.C. facility is supported by well equipped Gauge and labs added to
          these are our flawless inspection and testing facilities that further
          take manufacturing standards to levels of excellence.
        </b>
        <b className="incoming-raw-material-container">
          <p className="all-work-carried">INCOMING RAW MATERIAL INSPECTION</p>
          <ul className="visual-dimensional-chemical">
            <li className="visual-dimensional">
              <span>{`VISUAL & DIMENSIONAL`}</span>
            </li>
            <li>
              <span>{`CHEMICAL & MECHANICAL AT REPUTED TESTING LAB`}</span>
            </li>
          </ul>
        </b>
        <b className="painting">PAINTING</b>
        <b className="we-maintain-the-container">
          <p className="all-work-carried">
            WE MAINTAIN THE FOLLOWING STEP FOR GOOD PAINTING:
          </p>
          <p className="blank-line">&nbsp;</p>
          <ul className="good-surface-preparation-as-pe">
            <li className="visual-dimensional">
              <span className="good-surface-preparation">{`GOOD SURFACE PREPARATION AS PER SSPC &NACE:`}</span>
              <span className="span">{`                    `}</span>
              <span>
                SSPC-SP1-Solvent cleaning SSPC-SP2-Hand tool cleaning
                SSPC-SP3-Power tool cleaning etc.
              </span>
            </li>
          </ul>
          <p className="blank-line">&nbsp;</p>
          <ul className="good-surface-preparation-as-pe">
            <li className="visual-dimensional">
              <span>ENSURE THE PAINTING CHECKLIST AND DATA SHEET</span>
            </li>
          </ul>
          <p className="all-work-carried">&nbsp;</p>
          <ul className="good-surface-preparation-as-pe">
            <li>
              <span>MAINTAIN ALL INSTRUCTION WHICH GIVEN IN DATA SHEET</span>
            </li>
          </ul>
        </b>
        <img className="image-3-icon" alt="" src="/Images/image-3@2x.png" />
        <b className="packaging-shipment">{`PACKAGING & SHIPMENT`}</b>
        <b className="text">{` `}</b>
        <img
          className="tdesignsystem-sum-icon"
          alt=""
          src="/Images/tdesignsystemsum.svg"
        />
        <img
          className="tdesignsystem-sum-icon1"
          alt=""
          src="/Images/tdesignsystemsum.svg"
        />
        <b className="with-awareness-to">{`WITH AWARENESS TO THE TECHNOLOGY,OUR ENGINEERS &MANAGERS HAVE COLLABRATION WITH CLIENTS TO GIVE OUR BEST OUTPUT.`}</b>
        <b className="quality-control">{`QUALITY & CONTROL`}</b>
        <b className="our-packaging-are-container">
          <p className="all-work-carried">{`OUR PACKAGING ARE GOOD FOR SEAWORTHY EXPORT & DOMESTIC.WE MAINTAIN THE FOLLOWING STEPS:`}</p>
          <p className="all-work-carried">&nbsp;</p>
          <ul className="good-surface-preparation-as-pe">
            <li className="visual-dimensional">{`PRODUCTS ARE WELL WRAPED & WELL FIXED IN WOODEN BOX &GUNNY BAG`}</li>
            <li className="visual-dimensional">LIFTING WITH PROPER LIFTER</li>
            <li className="visual-dimensional">IIPC MARK ON ALL BOXES</li>
            <li>SILICA GEL FOR MOISTURE CONTROL ETC.</li>
          </ul>
        </b>
        <img className="vector-icon" alt="" src="/Images/vector.svg" />
        <img className="vector-icon1" alt="" src="/Images/vector.svg" />
        <img className="vector-icon2" alt="" src="/Images/vector.svg" />
        <img className="vector-icon3" alt="" src="/Images/vector.svg" />
        <img className="vector-icon4" alt="" src="/Images/vector1.svg" />
      </div>

    </>
  );
};

export default SustainabilityQualityManagem;
