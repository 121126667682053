import { Helmet } from "react-helmet";
import must from '../components/Assets/mustard final.mp4'




const Mustardoilextraction = () => {
  return (
    <>

      <Helmet>
        <title>Mustard Oil Extraction</title>
        <meta name="description" content="Mustard Oil Extraction" />
        <meta name="keywords" content=" Mustard Oil Extraction " />
      </Helmet>



      <h1 className="Refinery">Mustard Oil Extraction</h1>

      <section className="refVid">

        <video src={must} autoPlay loop controls muted></video>
      </section> <br />





    </>
  );
};
export default Mustardoilextraction;