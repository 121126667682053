import React from 'react'
import './Maintainance.css'
import { Helmet } from 'react-helmet'
const Maintainance = () => {
  return (
    <>
      <Helmet>
        <title>Maintainance</title>
        <meta name="description" content=" The primary goals of Plant Maintenance are to ensure reliable operation at minimal cost, starting with well-matched equipment specifications that consider both current and future needs while avoiding over-specification.
            Equipment selection should prioritize long-term reliability, availability of replacement parts, technical support, common parts for interchangeability, simplicity, and the total-life cost." />
        <meta name="keywords" content="Total Productive Maintenance, mecpro, Maintenance, Modern machine, Modern machine shop, equipped with a range of lathes, lathes, drilling machines, welding, cutting, VMC, CNC, and other, allied machines, All machine,  measuring equipment,  calibrated, calibrated & good condition, Total Quality Management, TPM, TQM, Breakdown, preventive, predictive,  Equipment life, Purchasing,Commissioning,Testing, Operation, Maintenance, Overhauling, Modification, Replacement " />
      </Helmet>
      <div className="maintainanceContainer">
        <h1 className='pageHeading2'>Maintainance</h1>
        <article className='maintainanceContent'>
          <h5>
            The primary goals of Plant Maintenance are to ensure reliable operation at minimal cost, starting with well-matched equipment specifications that consider both current and future needs while avoiding over-specification.
            Equipment selection should prioritize long-term reliability, availability of replacement parts, technical support, common parts for interchangeability, simplicity, and the total-life cost. </h5>

          <h5>  Total Productive Maintenance (TPM) shares similarities with Total Quality Management (TQM) and requires upper-level management commitment, employee empowerment, and a long-term perspective. </h5>

          <ul> <h5> Maintenance can be categorized as:</h5>
            <li className='maintenanceListItem' >  Breakdown </li>
            <li className='maintenanceListItem'>  Preventive </li>
            <li className='maintenanceListItem'>  Predictive </li>
          </ul>
          <h5>    In the first phase, training ensures operators have the necessary skills to maintain equipment and actively detect deterioration. </h5>

          <h5>    The second phase focuses on proper equipment operation and maintenance. </h5>

          <h5>    The third phase addresses wear and tear through preventive and predictive maintenance, monitoring, and proactive responses. </h5>
          <h5>   Cross-functional teams are crucial at all stages. TPM is integral to successful Total Quality Management and equipment lifespan. </h5>

          <h5>  Management support is key, and TPM involves everyone in the plant. </h5>

          <h5>     Operators must take responsibility for routine maintenance tasks. Effective maintenance is critical for improving productivity and efficiency in the long run, particularly in highly competitive industries. </h5>

          <h5>    Performance goals are set for each department, with regular assessments of achievements. Performance criteria include compliance with maintenance schedules, zero stock outages in the warehouse, timely material delivery, and maintenance planning efficiency. </h5>

          <h5>   The equipment management strategy aims to change the organizational culture towards maintenance and redefine workforce responsibilities for controlling and carrying out maintenance. </h5>
          <ul>
            <h4>  Equipment management recognizes different support needs at various stages of including:- </h4>

            <li className='maintenanceListItem'>  Equipment life – Carrying out financial transactions to obtain the best equipment. </li>
            <li className='maintenanceListItem'>  selection -  Determining equipment that best suits intended utilization and performance requirements versus price, ease of operation and maintenance, reputation for quality, manufacturer support etc. </li>
            <li className='maintenanceListItem'>    Purchasing -  Carrying out financial transactions to obtain the best equipment. </li>
            <li className='maintenanceListItem'>   Commissioning -  placing the equipment in service. </li>
            <li className='maintenanceListItem'>  Testing -  Ensuring the equipment meets the requirements of the user. </li>
            <li className='maintenanceListItem'>  Operation  – Operating the equipment during the production process </li>
            <li className='maintenanceListItem'>   Maintenance -  – Conducting repair and upkeep of the equipment.</li>
            <li className='maintenanceListItem'>   Overhauling -  Restoring equipment to original design specifications after extended use (may be part of maintaining)</li>
            <li className='maintenanceListItem'>   Modification -  Changing the design configuration of the equipment to yield better performance or to correct or update operating characteristics (a capital expense) </li>
            <li className='maintenanceListItem'>  Replacement - Replacing the similar equipment or improved operating or performance characteristics. </li>
          </ul>
        </article>
      </div>
    </>
  )
}


export default Maintainance
