const ProjectData = [
  {
    imgsrc: "./Images/BIO DIESEL.webp",
    title: 'Bio Diesel',
    alt: 'Bio Diesel',
    to: 'Biodiesel'

  },
  {
    imgsrc: './Images/shortenings-and-margraine-1.webp',
    title: 'Bakery Shortening',
    alt: 'Bakery Shortening And Margarine',
    to: 'BakeryShortening'


  },
  {
    imgsrc: './Images/Cotton Seed0011.webp',
    title: 'Cotton Seed',
    alt: 'Cotton Seed',
    to: 'CottonSeed'

  },
  {
    imgsrc: './Images/hydrogenation.webp',
    title: 'Hydrogenation',
    alt: 'Hydrogenation',
    to: 'Hydrogenation'

  },
  {
    imgsrc: './Images/1-1801777408@2x.webp',
    title: 'Layer Esterification',
    alt: 'Layer Esterification',
    to: 'LayerEsterification'

  },
  {
    imgsrc: './Images/palm oil Mill.webp',
    title: 'Palm Oil Mill',
    alt: 'Palm Oil Mill',
    to: 'PalmOilMill'

  },
  {
    imgsrc: './Images/palmkernal.webp',
    title: 'Palm Kernal',
    alt: 'Palm Kernal',
    to: 'PalmKerneloilextraction'

  },
  {
    imgsrc: './Images/refinery1.webp',
    title: 'Refinery',
    alt: 'Refinery',
    to: 'Refinery'


  },
  {
    imgsrc: './Images/SOAP PLANT.webp',
    title: 'Soap',
    alt: 'Soap',
    to: 'Soap'


  },
  {
    imgsrc: './Images/PALM FIBRE.webp',
    title: 'Palm Fiber',
    alt: 'Palm Fiber',
    to: 'Palmfibreoilectraction'

  },
  {
    imgsrc: './Images/SOLVENT1.webp',
    title: 'Solvent Extraction',
    alt: 'Solvent Extraction',
    to: 'SolventExtraction'


  },
  {
    imgsrc: './Images/fractionation3.webp',
    title: 'Fractionation',
    alt: 'Fractionation',
    to: 'Fractionation'

  },
  {
    imgsrc: './Images/spentbleeching.webp',
    title: 'Spent Bleeching Earth',
    alt: 'Spent Bleeching Earth',
    to: 'SpentBleechingEarth'

  },
  {
    imgsrc: './Images/Wax.webp',
    title: 'Wax Purification',
    alt: 'Wax Purification',
    to: 'WaxPurification'


  },
  {
    imgsrc: './Images/header-2@2x.webp',
    title: 'Oil Recovery from POME',
    alt: 'Palm Oil Mill',
    to: 'Pome'

  },

  {
    imgsrc: './Images/flour mill.webp',
    title: 'Flour Mill',
    alt: 'Flour Mill',
    to: 'FlourMill'

  },
  {
    imgsrc: './Images/Oil Mill.webp',
    title: 'Oil Mill',
    alt: 'Oil Mill',
    to: 'OilMill'

  },

  {
    imgsrc: './Images/solar energy.png',
    title: 'Solar Energy',
    alt: 'Solar Energy'

  },


]

export default ProjectData;
