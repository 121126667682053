import React from "react";
import { Helmet } from "react-helmet";

const MiscellaSelfwash = () => {
  return (
    <>
      <Helmet>
        <title>Self Wash Miscella Filtration System</title>
        <meta
          name="description"
          content="The special filtration
        system uses special filter cloth to reduce the level of impurities and
        volatiles to below 0.1% as against anything up to 2% in the conventional
        filters.This helps in avoiding the degradation of the oil and improving
        distillation."
        />
        <meta
          name="keywords"
          content="Self wash Miscella Filtration System,  This helps in avoiding the degradation of the oil and improving
        distillation,  
          solvent plant,
          oil solvent extraction plant,
          continuous solvent extraction plant,
          Miscella Filteration,
          Self wash Miscella Filtration,
          solventisation,
          edible oil solvent extraction plant,
          desolventisation,
          losses of solvent,
          solvent extraction
          What is Solvent Extraction,
          Advance Solvent Extraction process,
           
           "
        />
      </Helmet>
      <h2 className="Refinery text-left">
        Self wash Miscella Filtration System (Patented){" "}
      </h2>
      <h5 className="mecpro1">
        Self-wash Miscella Filtration System (Patented) The special filtration
        system uses special filter cloth to reduce the level of impurities and
        volatiles to below 0.1% as against anything up to 2% in the conventional
        filters. This helps in avoiding the degradation of the oil and improving
        distillation. This will not only reduce the operating cost but also
        reduce maintenance costs to make it more economical. Mecpro offers
        <br />• Reduced operating cost
        <br />• Better oil quality
        <br />• Reduction in sediments
        <br />• Reduce choking in pre-heater tubes
        <br />• No water is required in the miscella tank
        <br />• Less maintenance cost
      </h5>
    </>
  );
};

export default MiscellaSelfwash;
