import React from "react";
import "./DesolventizationSection.css";
import { Helmet } from "react-helmet";

const DesolventizationSection = () => {
  return (
    <>
      <Helmet>
        <title>Solvent Extraction - Desolventization</title>
        <meta
          name="description"
          content="Solvent Extraction is a process to extract oil from any oil-bearing seed like soybean, sunflower, rapeseed mustard, corn gem, Mahua neem seed, shea, mango kernel, sal seed, etc. the powder material like rice bran, spent bleaching earth. Mecpro is having a vast experience in extraction technology to extract oil from any oil seed
            which is containing oil. The recent innovation and research Mecpro could able to do significant changes to process
            the material without a preparatory section by reducing 40%power consumption and reduction of 20% FFA, 20% better color and bleachability, and reduced manpower
            and inventory."
        />
        <meta
          name="keywords"
          content=" Solvent Extraction, solvent extraction process,  extract oil from any oil-bearing seed, like soybean oil extraction, sunflower oil extraction, rapeseed oil extraction, mustard oil extraction, corn gem, Mahua neem seed, shea oil extraction, mango kernel oil extraction, sal seed, rice bran oil extraction, spent bleaching earth,solvent extraction equipment,
          solvent plant,
          Protein Concentration,
          Protein Isolation,
          solvent extraction machinery,
          oil solvent extraction plant,
          continuous solvent extraction plant,
          solvent extraction plant cost,
          solvent extraction plant process,
          edible oil solvent extraction plant,
          solvent plant cost,
          solvent extraction plant, Mecpro, is having a vast experience in extraction technology to extract oil from any oil seed
            which is containing oil, extraction technology, extract oil "
        />
      </Helmet>
      <div>
        <h2 className="Refinery text-left">HIGH VACUUM DESOLVENTIZATION </h2>
        <h5 className="mecpro1">
          Mecpro has incorporated several advancements in the Desolventization
          for the general process of raw material as well as, de-oiled meal
          (white flakes) for high protein to make edible products, protein
          isolation, and protein concentration. First time in the global market
          we have added a high vacuum system and air sparging for the
          Desolventization as against flash Desolventization working with hexane
          reflux as carry over. Such advanced technology will ensure good
          quality of de-oiled meals as well as, maintaining PDI value in the
          soya de-oiled meal and white flakes. The improved method of steam
          applied to the Desolventization process will enhance the heat transfer
          coefficient and the condensate will be used for the distillation of
          miscella, hence reduction of energy consumption significantly. The
          modified internal fittings will reduce the wear and tear of the
          desolventizing toaster and make it for a minimum of 10 to 20 years of
          running depending upon the raw material processed. The self- styled
          mechanical door system for discharging from one compartment to another
          compartment will ensure to maintenance the torque. <br />
          <br />
          <h1 className="pageHeading2">
            ADVANCED TECHNOLOGY FOR DESOLVENTISATION
          </h1>
          <br />• High vacuum desolventisation using vacuum pump will ensure
          separation of hexane from the de-oiled meal efficiently with reduced
          steam consumption.
          <br />• Nitrogen purging to improve the quality and increase the
          carryover of hexane, ensure better colour and increase Protein
          Dispersibility Index (PDI) value.
          <br />• Continuous condensate steam discharge system enhances heat
          transfer efficiency by increasing heat transfer co-efficient.
          <br />• Advanced system minimizes fugitive losses, ensuring more
          efficient and environmentally friendly operations.
          <br />• The sealed screw conveyor system ensures better sealing and
          material discharge from the D.T., improving overall efficiency.
          <br />• Self-operated float level system helps in maintaining optimal
          level of material, constant load easy to adjust and consistent
          operation.
          <br />• The heat economization with miscella and fresh solvent,
          reducing energy consumption in distillation and extraction and helps
          to reduce the water quantity in circulation.
          <br />• No hot water circulation in the dust collector ensures the
          reduction of steam consumption and water consumption in the process.
        </h5>
      </div>
    </>
  );
};

export default DesolventizationSection;
