import "./Refinery.css";
import "./BakeryShortening.css"
import { Helmet } from "react-helmet";
/* import { Parallax } from "react-parallax" */




const BakeryShortening = () => {

  return (
    <>
      <Helmet>
        <title> BakeryShortening </title>
        <meta name="discription" content="Bakery shortening or Emulsifier-type shortening is hydrogenated
            shortening to which an emulsifying agent has been added. This gives the shortening exceptional ability to blend with other ingredients.
            It is especially formulated to contribute a crumbly, crusty and flaky texture and firm enough to support the weight of the fillings as used in making of cream sandwich cookies, decorative creaming pastries and other bakery products." />
        <meta name="keyword" content="BAKERY SHORTENING & MARAGARINE " />
      </Helmet>

      <div><h1 className="Refinery">BAKERY SHORTENING & MARAGARINE </h1>
      </div>
      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img className="RefinieryHeaderImage" src="./Images/bk1.webp" alt="Refinary-Header-Img" />
        </div><br />



        <section className="refinery1">
          <img className="refImg" src="./Images/3-6@2x.webp" alt="Refinery-img1" />
          <h5 className="ref1h5">Bakery shortening or Emulsifier-type shortening is hydrogenated
            shortening to which an emulsifying agent has been added. This gives the shortening exceptional ability to blend with other ingredients.
            It is especially formulated to contribute a crumbly, crusty and flaky texture and firm enough to support the weight of the fillings as used in making of cream sandwich cookies, decorative creaming pastries and other bakery products.

            MECPRO provides the Shortening Plant capable of producing up to
            1000 kg/hour after cooling from an inlet temperature of 450c to 23/250c which would be suitable for semi-liquid filling into cans. The capacity
            of such plants largely depends upon product formulations and inlet
            temperature of the product to be processed. <br />

          </h5><br /><br />



        </section><br /><br />


        <h5 className="mecpro1">In this process quick solidification & subsequent crystallization function requires careful control. Normally it follows below path:
        </h5><br />
        <section className="refVid">
          <img src="./Images/4-3@2x.webp" alt="Bakery" className="fracbigImg" /><br />
        </section><br />
        <h5 className="mecpro1">Generally 8-12% Nitrogen or air is introduced in chilled product, to improve shortening textural appearance & colour. Stabilize it by
          making it more homogenous& improve its creaming properties by contributing gas to expand dough & batter for subsequent baking
          operations. </h5>
        <section className="Bakeryimg2">
          <img src="./Images/5-2@2x.webp" alt="" className="bakery-min" />
        </section>

        <h1 className="pageHeading2">MARGARINE</h1>
        <h5 className="mecpro1">Margarine is a plastic fat food product containing 80 % Edible fat, 20 % Aqueous phase (especially cultured Skim Milk), also Salt,
          Vitamin, Food colouring and other additives.
        </h5>
        <h5 className="mecpro1">Aqueous phase provides an excellent growth media for harmful bacteria. So, it must be carefully prepared & sterilized for proper
          blending in the tanks which are jacketed by hot water media.
        </h5>
        <h5 className="mecpro1">Depending on the undergone processes the final product is named like Table Margarine, Tube filled Margarine, Puff pastry Marga-
          rine etc.
        </h5><br />


        <section className="refVid">

          <video src="./Images/Oilmill.mp4" autoPlay loop muted></video>
        </section>


        <section>
          <img src="./Images/raw-oil-and-fat-1@2x.webp" alt="" className="Bakeryimg2" />
        </section>

        <div className="bakery-content">
          <div>
            <h1 className="pageHeading2">MARGARINE</h1>
            <h5 className="mecpro1">The Plant consists of the following processes:

              Essess Processor - Heat transfer cylinder assembly
              Crystal Distribution cum Stabilizer
              Triplex Plunger Reciprocating Pump
              Nitrogen Gas Purging System
              Interconnecting Product Pipeline
              Control Panel. </h5><br /><br />

            <h1 className="pageHeading2">CRYSTAL DISTRIBUTION
              CUM STABLIZER</h1>
            <h5 className="mecpro1">Horizontally mounted and sequentially connected
              to the twonumber of Essess Processors as mentioned
              above. The cylinder has two rows of fixed pins at 180°
              with a concentric shaft carrying pins in a helicalarran-
              gement which intermesh with fixed pins while rotating,
              driven through a gear box and a suitable motor hose
              proof to IP 55.

              All product contact parts are made of stainless steel
              AISI 304 materials. Two numbers of heaters are provided
              for heating of the product in case of product solidification
              inside the product cylinder during power failure or production
              stoppage.

              The cylinder is suitably insulated for eliminating heat loss
              and cladded with stainless sheets for maintaining utmost hygiene. </h5>



            <h1 className="pageHeading2">NITROGEN PURGING
              SYSTEM</h1>
            <h5 className="mecpro1">We will provide advance technology for Nitrogen Purging
              System which helps in flushing the blended oil with inert
              high purity Nitrogen to retard aerobic spoilage and oxidation
              while typically reducing the O2 level in blended oil. The diss-
              olved O2 is reduced from 8ppm to 0.5 ppm which gives better
              product colour, texture and extended shelf life and storage
              period.Generally 8 – 12 % Nitrogen is introduced in Chilled
              Product to improve Shortening Textural appearance & Colour
              and thus giving more homogeneous & improved creaming
              properties which helps in subsequent baking operations. </h5>

            <h1 className="pageHeading2">Control Panel</h1>
            <h5 className="mecpro1">This control panel not only enables smooth and trouble free handling of
              the product but also ensures safe operation of the plant.The unique
              design feature of the Control Panel prevents mishandling of the plant
              thereby avoiding costly damages.

              The unit does not start unless temperature of the product at certain
              specified locations is below the specified limit. At the same time the
              unit does not stop unless the temperature at the specific locations
              goes below the specified temperature during the operation of the unit.
              This safety factor avoids damage to the equipment of the plant when
              the product inside the equipment as well as pipeline is solid in case
              the plant is stopped due to power failure or product change over.

              The Bakery Shortening Plant enumerated above is not the only capacity
              limit but also we can manufacture higher production capacity plants as
              may be required by the clients.

              We can also procure other fabricated equipment like Fat Blenders of
              various capacities in both Stainless Steel and Carbon Steel as per the
              specific requirements. </h5><br />

          </div>
          <div>
            <h1 className="pageHeading2">ESSESS PROCESSOR</h1>

            <h5 className="mecpro1">Two numbers of interconnected Essess Processor
              consisting of a single horizontal heat transfer cylinder
              assembly mounted on an open frame. The cylinder is
              jacketed and lagged for use with glycol solution.
              There movable heat transfer cylinder will be internally
              hard chrome plated. The Essess Processor shaft is
              concentrically mounted within the cylinder and has
              four diametrically opposed rows of floating type scraper
              blades and is driven by suitable motor hose proof to IP55
              standard via belts and pulleys suitably guarded.

              All the product contact parts are made of Stainless Steel
              AISI 304 materials. The scraping blades, seal parts and 'O'
              rings will be compatible with the product. The frame and
              the external product non-contact parts are in Carbon Steel
              and finished painted with metallic paint.

              Essess Processor chilling jacket is suitably insulated for
              eliminating heat loss and cladded with stainless steel
              sheets for maintaining utmost hygiene.  </h5>

            <h1 className="pageHeading2">TRIPLEX PLUNGER
              RECIPROCATING PUMP</h1>
            <h5 className="mecpro1">Triplex model, single acting, reciprocating plunger pump
              is used for feeding the product to the system. The capacity
              of the pump is 1,690 litres/hour driven by a suitable motor
              hose with degree of protection proof to IP 55. All product
              contact parts are of stainless steel AISI / 316 materials and
              the product handling compartment is suitably jacketed for
              hot water / steam circulation for melting the product.  </h5>

            <h1 className="pageHeading2">INTERCONNECTING
              PRODUCT
              PIPELINE</h1>
            <h5 className="mecpro1">The interconnecting product pipeline is jacketed for heating
              in case of product being solidified inside the pipeline. Further
              each section of the product pipeline is interconnected suitably
              for continuous heating of the entire length of the product pipeline.
            </h5>
            <img src="./Images/6-11@2x.webp" alt="Bakery" className="bakeryImgBottom" /><br /><br />

          </div>
        </div>








      </div>


    </>
  )
}

export default BakeryShortening