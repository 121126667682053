import "./SolarEnergy.css";
import { Parallax } from "react-parallax"
import "./Refinery.css";
import { Helmet } from "react-helmet";



const SolarEnergy = () => {
  return (
    <>
      <Helmet>
        <title>Solar Energy</title>
        <meta name="description" content="Solar cell is one of the cost-effective methods to produce power in Chad. Climatic condition is suitable for implementation of solar cell in an economical way. In regard to land there are several acres of land is available for the installation of solar cell besides, floating cells implementation in the lake. Within 15000 m2 area one can produce 1 MW power from the solar cell in Chad, Chad is having raw material suitable to manufacture solar cells a suitable project can be envisaged for constructing to reduce the import cost of solar cells for future developments.
        The world is moving forward to stop carbon emission with such installation Chad also can be a part in that development. Solar energy, wind mill energy, hydrogen energy, thermal power plants, waste heat power plants, heavy fuel generators and hydro-power energy; these are the source for power generation." />
        <meta name="keywords" content=" Solar Energy Solar cell cost-effective methods to produce power" />
      </Helmet>

      <div><h1 className="Refinery">SOLAR ENERGY</h1>
      </div>

      <div className="refinaryContainer">
        <div className="HimgContainer">
          <img className="RefinieryHeaderImage" src="./Images/solar.png" alt="Refinary-Header-Img" />
        </div>
      </div><br />

      <h5 className="mecpro1">Solar cell is one of the cost-effective methods to produce power in Chad. Climatic condition is suitable for implementation of solar cell in an economical way. In regard to land there are several acres of land is available for the installation of solar cell besides, floating cells implementation in the lake. Within 15000 m2 area one can produce 1 MW power from the solar cell in Chad, Chad is having raw material suitable to manufacture solar cells a suitable project can be envisaged for constructing to reduce the import cost of solar cells for future developments.
        The world is moving forward to stop carbon emission with such installation Chad also can be a part in that development. Solar energy, wind mill energy, hydrogen energy, thermal power plants, waste heat power plants, heavy fuel generators and hydro-power energy; these are the source for power generation.</h5><br />



    </>


  );
};

export default SolarEnergy;
