import React from 'react'
import Accordion from 'react-bootstrap/Accordion';


const FractionationFaq = () => {
    return (
        <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
                <Accordion.Header>What is inline crystallization and fractionation?
                </Accordion.Header>
                <Accordion.Body>
                    Inline crystallization means the oil will flow continuously from refining process to the crystallizer and the flow will continue to the sequence of crystallizers and at the end by gravity it will pass through the filter where olein and stearin separated.no need of reducing the temperature and storing the same in storage tank and further heat up for fractionation.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
                <Accordion.Header>How you are claiming higher olein recovery from any other prevailing technology?
                </Accordion.Header>
                <Accordion.Body>
                    In the continuous process oil is not stagnant and the method of process technology will create sandy crystal in the crystallization process, entrainment of olein into sandy crystal significantly reduce as compared to other type of crystal formation. Due to such reason the olein recovery will be better.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
                <Accordion.Header>What is the percentage of olein recovery can be guaranteed by Mecpro?

                </Accordion.Header>
                <Accordion.Body>
                    Minimum 68 %. It may better up to 72 %
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
                <Accordion.Header>	How the clarity will be different?
                </Accordion.Header>
                <Accordion.Body>
                    Constant flow and gravity force will give better bed formation and clarity in the process better than any other method of process, you can cut the top of tin and fill the oil from continuous process and with prevailing technology processed.
                    you can see better clarity to the bottom from by-gravity filtered oil.        </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
                <Accordion.Header>Is it possible to achieve 6 CP (60 I.V) in single step of process?
                </Accordion.Header>
                <Accordion.Body>
                    Yes, it can achieve 6 CP in single step. With reduction of capacity by 10% as against 50% in other prevailing technology.        </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5">
                <Accordion.Header>Is it possible to achieve 3.5 CP (65 I.V) without unloading or heating?
                </Accordion.Header>
                <Accordion.Body>
                    Our continuous inline crystallization can achieve 3.5 CP in continuous process keeping two steps of filtration without heating and unloading.
                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="6">
                <Accordion.Header>How you can reduce the manpower and Power?</Accordion.Header>
                <Accordion.Body>
                    Since it is attached with refinery and continuous flow besides controlled by logic, the refinery people itself can handle the fractionation adding a single person in shift for operating the filter.



                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="7">
                <Accordion.Header>How you can reduce the Chilled water consumption?
                </Accordion.Header>
                <Accordion.Body>
                    The continuous crystallization, the oil cooled with normal water upto 3 to 5oC above the normal water temperature, however in the prevailing technology, the oil cooled by normal water upto 10 to 12oC above the normal water temperature.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="8">
                <Accordion.Header>How will you compress the stearin and clean the filter?

                </Accordion.Header>
                <Accordion.Body>
                    Once the filtration is over, the filter pump will be automatically start for Squeezing the stearin and as per the PLC logic cleaning process will complete.

                </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="9">
                <Accordion.Header>How will you melt the stearin and break the olein?</Accordion.Header>
                <Accordion.Body>
                    Oil from deodorizer taken at 100 to 110oC and used for melting the stearin and breaking the nucleus of olein and given to the crystallizer at 40 to 50oC for the crystallization process.


                </Accordion.Body>
            </Accordion.Item>


        </Accordion>
    )
}

export default FractionationFaq